import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import QRCode from "react-qr-code";

// Components
import telegramService from "../../services/telegram.js";

const ConnectTelegram = ({ StepProgress, ConnectStatus }) => {
  // Language
  const [t] = useTranslation();
  // State
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [isStateConnected, setIsStateConnected] = useState(ConnectStatus);
  const [isStateWaitPhoneNumber, setIsStateWaitPhoneNumber] = useState(true);
  const [isStateWaitCode, setIsStateWaitCode] = useState(false);
  const [isLoginWithQrCode, setIsLoginWithQrCode] = useState(false);
  const [isLoginWithQrCodeError] = useState(false);
  const [numBtnStatus, setNumBtnStatus] = useState(false);
  const [myQrCode, setMyQrCode] = useState();

  useEffect(() => {
    CheckConnect();
  }, []);

  useEffect(() => {
    let intervalId;
    if (!isStateConnected && isLoginWithQrCode) {
      intervalId = setInterval(HandleQrCodeLogin, 5000);
    }
    return () => clearInterval(intervalId);
  }, [isStateConnected, isLoginWithQrCode]);

  // Qr code login
  const HandleQrCodeLogin = () => {
    if (isStateConnected) {
      StepProgress(2);
      return;
    } else {
      telegramService.loginWithQrCode(
        (response) => {
          if (response.data.isLoggedIn) {
            setIsStateConnected(true);
            // console.log("Qr Code Login isLoggedIn:", response.data.isLoggedIn);
          } else if (response.data.qrCodeUrl) {
            setMyQrCode(response.data.qrCodeUrl);
            // console.log(
            //   "Qr Code Login new qrCodeUrl:",
            //   response.data.qrCodeUrl
            // );
            // setIsLoginWithQrCodeError(true);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };
  // Phone number login
  const HandlePhoneNumberLogin = () => {
    // console.log("Handle Phone Number run");
    if (isStateConnected) {
      StepProgress(2);
      return;
    } else {
      telegramService.loginWithPhoneNumber(
        phone,
        otp,
        (response) => {
          if (response.data.isLoggedIn) {
            setIsStateConnected(true);
            // console.log(
            //   "HandlePhoneNumberLogin isLoggedIn",
            //   response.data.isLoggedIn
            // );
          } else {
            setIsStateWaitPhoneNumber(false);
            setIsStateWaitCode(true);
          }
        },
        (error) => {
          setIsStateWaitPhoneNumber(true);
          setIsStateWaitCode(false);
          console.log(error);
        }
      );
    }
  };
  // number input
  const numBtn = (e) => {
    setPhone(e);
    if (e.length >= 5) setNumBtnStatus(true);
    else setNumBtnStatus(false);
  }

  // Logout
  const HandleLogout = () => {
    telegramService.LogoutTelegramConnect(
      (response) => {
        if (response.success) {
          setIsStateConnected(false);
          // console.log("is logout");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  // Connect Check
  const CheckConnect = () => {
    telegramService.getTelegramConnectStatus(
      (res) => {
        setIsStateConnected(res.data.isLoggedIn);
        // console.log("check telegram connect status:", res.data.isLoggedIn);
      },
      (error) => {
        // console.log("check telegram connect status:", error);
      }
    );
  };
  return (
    <div className="ConnectTelegram">
      <div className="step1">
        {!isStateConnected && (
          <div className="pop-up-title">
            <h1>{t("Dashboard.telegramBot.stp1Title")}</h1>
            <p>{t("Dashboard.telegramBot.stp1Text")}</p>
          </div>
        )}
        {isLoginWithQrCode && !isStateConnected && myQrCode && (
          <>
            <div className="qrcode">
              <QRCode
                value={myQrCode}
                size={256}
                style={{ height: "auto", maxWidth: "50%", width: "50%" }}
                viewBox={`0 0 256 256`}
              />
              <div className="stps">
                <h3>Scan from mobile telegram</h3>
                <ul className="list-unstyled">
                  <li><span>1.</span> Open Telegram on your phone</li>
                  <span>2.</span> {`Go to Settings > Devices > Scan QR Code`}
                  <li><span>3.</span> Scan images and then press Enter</li>
                </ul>
              </div>
            </div>
            {isLoginWithQrCodeError && (
              <p className="Error">Something went wrong, Please scan again.</p>
            )}
            <p
              className="loginMethods"
              onClick={() => setIsLoginWithQrCode(!isLoginWithQrCode)}
            >
              Or using your phone number
            </p>
          </>
        )}
        {!isLoginWithQrCode && !isStateConnected && (
          <>
            <div className="inpt">
              {isStateWaitPhoneNumber && (
                <>
                  <div className="itm">
                    <label> {t("Phone Number")}</label>
                    {/* <input
                      type="text"
                      placeholder="Enter Phone number here"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    ></input> */}
                    <PhoneInput
                      inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: true
                      }}
                      placeholder="Enter Phone number"
                      enableSearch={true}
                      value={phone}
                      onChange={(e) => numBtn(e)}
                    />
                  </div>
                  <button
                    disabled={!numBtnStatus}
                    className={numBtnStatus ? "Step1btn active" : "Step1btn"}
                    onClick={HandlePhoneNumberLogin}
                  >
                    {t("Next")}
                  </button>
                </>
              )}
              {isStateWaitCode && (
                <>
                  <div className="itm">
                    <label> {t("Login code")}</label>
                    <input
                      type="text"
                      placeholder="Enter code here"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    ></input>
                  </div>
                  <button className="Step1btn" onClick={HandlePhoneNumberLogin}>
                    {t("Enter")}
                  </button>
                </>
              )}
            </div>

            {!isStateWaitCode && (
              <p
                className="loginMethods"
                onClick={() => {
                  HandleQrCodeLogin();
                  setIsLoginWithQrCode(!isLoginWithQrCode);
                }}
              >
                Or using QR code
              </p>
            )}
          </>
        )}
        {isStateConnected && (
          <>
            <h3 className="connected">
              {t("You are connected")}
              <svg
                fill="#000000"
                width="800px"
                height="800px"
                viewBox="0 0 24 24"
                id="d9090658-f907-4d85-8bc1-743b70378e93"
                data-name="Livello 1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>prime</title>
                <path
                  id="70fa6808-131f-4233-9c3a-fc089fd0c1c4"
                  data-name="done circle"
                  d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM11.52,17L6,12.79l1.83-2.37L11.14,13l4.51-5.08,2.24,2Z"
                />
              </svg>
            </h3>
            <div className="btns">
              <button className="btn" onClick={HandleLogout}>
                {t("Logout")}
              </button>
              <button className="btn" onClick={(e) => StepProgress(2)}>
                {t("Next")}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ConnectTelegram;
