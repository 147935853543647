import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";

function Settings() {
  const [linkBtn, SetLinkBtn] = useState("profile");
  const [t] = useTranslation();
  useEffect(() => {
    if (window.location.pathname === "/settings/profile") {
      SetLinkBtn("profile");
    }
    if (window.location.pathname === "/settings/security") {
      SetLinkBtn("security");
    }
  }, []);
  return (
    <div className="dashboard-settings">
      <div className="title">
        <h1>{t("Dashboard.settings.title")}</h1>
        <ul className="list-unstyled links">
          <li>
            <NavLink
              exact
              to="profile"
              className={`${linkBtn === "profile" ? "active" : ""}`}
              onClick={(e) => SetLinkBtn("profile")}
            >
              {t("Dashboard.settings.subtitle1")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="security"
              className={`${linkBtn === "security" ? "active" : ""}`}
              onClick={(e) => SetLinkBtn("security")}
            >
              {t("Dashboard.settings.subtitle2")}
            </NavLink>
          </li>
        </ul>
      </div>
      <Outlet />
    </div>
  );
}

export default Settings;
