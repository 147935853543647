import { Link, useNavigate } from "react-router-dom";
import "./css/login.scss";
import RightCov from "./login&sign/RightCov";
import { useTranslation } from "react-i18next";
// Assets
import Logo from "./assets/insta-signal_FINAL icon 1.png";
import authService from "./services/auth";
import { useState } from "react";
import { ToastAlert, toast } from "./Dashboard/Alert/ToastAlert";

function ResetPassword() {
  const navigate = useNavigate();
  const [t, _] = useTranslation();
  const [email, setEmail] = useState();
  const sendOTP = () => {
    const isValidEmail = /\S+@\S+\.\S+/.test(email);
    if (!isValidEmail) {
      toast.error("Please enter a valid email address.");
      return;
    }

    authService.sendOTP(
      email,
      "CHANGE_PASSWORD",
      (data) => {
        toast.success("OTP sent to your email.");
        navigate("/reset-password/otp-check", { state: { email } });
      },
      (e) => toast.error("Please enter a valid email address.")
    );
  };
  return (
    <div className="reset-password">
      <div className="log_content">
        <form>
          <div className="top_part">
            <img src={Logo} alt="" />
            <h1>{t("resetPassword.title1")}</h1>
            <p>{t("resetPassword.title2")}</p>
          </div>
          <div className="form_part">
            <div className="inpt">
              <label>{t("resetPassword.lbl1")}</label>
              <input
                type="email"
                placeholder={t("resetPassword.inpt1")}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
          </div>
          <button className="btn_sign" type="button" onClick={sendOTP}>
            {t("resetPassword.btn1")}
          </button>
        </form>
        <Link to="/sign-in" className="back">
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5582 8.40088L4.95898 17L13.5582 25.5992"
              stroke="#292D32"
              strokeWidth="2.125"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M29.0417 17H5.19922"
              stroke="#292D32"
              strokeWidth="2.125"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      </div>
      <RightCov />
      <ToastAlert />
    </div>
  );
}

export default ResetPassword;
