import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import subscription from "../assets/Group 1707480058.png";

function Plans() {
  const [t] = useTranslation();
  return (
    <div className="plans">
      <div className="plan">
        <div className="top_sec-plan">
          <h4>{t("Home.Subscriptions.plan1.plan")}</h4>
          <h3>
            $19.99 <span>/{t("Home.Subscriptions.month")}</span>
          </h3>
        </div>
        <ul className="list-unstyled list">
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan1.l1")}</p>
          </li>
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan1.l2")}</p>
          </li>
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan1.l3")}</p>
          </li>
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan1.l4")}</p>
          </li>
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan1.l5")}</p>
          </li>
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan1.l6")}</p>
          </li>
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan1.l7")}</p>
          </li>
        </ul>
        <Link to="/sign-up">{t("Home.Subscriptions.btn")}</Link>
      </div>
      <div className="plan">
        <div className="top_sec-plan">
          <h4>{t("Home.Subscriptions.plan2.plan")}</h4>
          <h5>
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.2012 16.9533L17.7312 17.3008C17.4016 17.381 17.1432 17.6304 17.0719 17.96L16.7601 19.2696C16.5909 19.9824 15.6822 20.2051 15.21 19.6438L12.5462 16.5792C12.3324 16.3297 12.4482 15.9377 12.7689 15.8575C14.3458 15.4744 15.7623 14.5925 16.8047 13.3363C16.9739 13.1314 17.2768 13.1047 17.4639 13.2918L19.4417 15.2695C20.1188 15.9466 19.8782 16.793 19.2012 16.9533Z"
                fill="white"
              />
              <path
                d="M2.67503 16.9533L4.145 17.3008C4.47463 17.381 4.73298 17.6304 4.80426 17.96L5.11607 19.2696C5.28534 19.9824 6.19404 20.2051 6.66621 19.6438L9.32998 16.5792C9.54379 16.3297 9.42797 15.9377 9.10725 15.8575C7.53038 15.4744 6.11386 14.5925 5.07152 13.3363C4.90225 13.1314 4.59935 13.1047 4.41226 13.2918L2.43449 15.2695C1.75741 15.9466 1.99795 16.793 2.67503 16.9533Z"
                fill="white"
              />
              <path
                d="M10.9628 2.28036C7.51505 2.28036 4.72656 5.06885 4.72656 8.51659C4.72656 9.80838 5.10965 10.9933 5.7689 11.9822C6.73106 13.4076 8.25449 14.4143 10.0274 14.6726C10.3303 14.7261 10.6421 14.7528 10.9628 14.7528C11.2835 14.7528 11.5953 14.7261 11.8982 14.6726C13.6711 14.4143 15.1945 13.4076 16.1567 11.9822C16.8159 10.9933 17.199 9.80838 17.199 8.51659C17.199 5.06885 14.4105 2.28036 10.9628 2.28036ZM13.6889 8.3206L12.9495 9.06004C12.8248 9.18476 12.7535 9.4253 12.798 9.60348L13.0118 10.5211C13.1811 11.2427 12.798 11.5278 12.1566 11.1447L11.2657 10.6191C11.1053 10.5211 10.8381 10.5211 10.6777 10.6191L9.78682 11.1447C9.14538 11.5189 8.76229 11.2427 8.93156 10.5211L9.14538 9.60348C9.18101 9.43421 9.11865 9.18476 8.99392 9.06004L8.23667 8.3206C7.80013 7.88406 7.94267 7.44753 8.54848 7.34953L9.50173 7.18917C9.66209 7.16244 9.84918 7.0199 9.92045 6.87736L10.4461 5.82611C10.7312 5.25594 11.1944 5.25594 11.4795 5.82611L12.0051 6.87736C12.0764 7.0199 12.2635 7.16244 12.4328 7.18917L13.386 7.34953C13.9829 7.44753 14.1255 7.88406 13.6889 8.3206Z"
                fill="white"
              />
            </svg>
            {t("Home.Subscriptions.plan2.choice")}
          </h5>
          <h3>
            $39.99 <span>/{t("Home.Subscriptions.month")}</span>
          </h3>
        </div>
        <ul className="list-unstyled list">
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan2.l1")}</p>
          </li>
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan2.l2")}</p>
          </li>
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan2.l3")}</p>
          </li>
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan2.l4")}</p>
          </li>
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan2.l5")}</p>
          </li>
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan2.l6")}</p>
          </li>
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan2.l7")}</p>
          </li>
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan2.l8")}</p>
          </li>
        </ul>
        <Link to="/sign-up">{t("Home.Subscriptions.btn")}</Link>
        <img src={subscription} alt="" />
      </div>
      <div className="plan">
        <div className="top_sec-plan">
          <h4>{t("Home.Subscriptions.plan3.plan")}</h4>
          <h3>
            $49.99 <span>/{t("Home.Subscriptions.month")}</span>
          </h3>
          <p>{t("Home.Subscriptions.plan3.txt")}</p>
        </div>
        <ul className="list-unstyled list">
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan3.l1")}</p>
          </li>
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan3.l2")}</p>
          </li>
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan3.l3")}</p>
          </li>
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan3.l4")}</p>
          </li>
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan3.l5")}</p>
          </li>
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan3.l6")}</p>
          </li>
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan3.l7")}</p>
          </li>
          <li>
            <div className="icon">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                  fill="#5CC159"
                />
              </svg>
            </div>
            <p>{t("Home.Subscriptions.plan3.l8")}</p>
          </li>
        </ul>
        <Link to="/sign-up">{t("Home.Subscriptions.btn")}</Link>
      </div>
    </div>
  );
}

export default Plans;
