import axios from "axios";

/**
 * Sends a request to a specified API endpoint.
 *
 * @param {Object} options Configuration options for the request.
 * @param {string} options.method The HTTP method to use (e.g., "GET", "POST").
 * @param {string} options.url The URL of the API endpoint.
 * @param {Object} [options.data] Optional data to be sent with the request.
 * @returns {Promise} A promise that resolves with the response of the request.
 */
const executeRequest = ({ method, url, data, headers = {}, params = {} }) => {
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    const config = {
      method,
      url,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        ...headers,
      },
      data, // Will be ignored for GET requests by axios.
      params, // For GET requests, any query params can be passed here.
    };

    axios(config)
      .then((response) => resolve(response.data))
      .catch((error) => {
        console.log(JSON.stringify(error.response.data));
        reject({
          success: false,
          response: {
            data: {
              message: error.response.data.message || "Something went wrong.",
            },
          },
        })
      }
      );
  });
};

export default executeRequest;
