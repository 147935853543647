import { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
} from "recharts";

const FollowersData = [
  {
    name: "10 AM",
    Followers: 1000,
  },
  {
    name: "16 PM",
    Followers: 2300,
  },
  {
    name: "22 PM",
    Followers: 1900,
  },
  {
    name: "2 AM",
    Followers: 2900,
  },
  {
    name: "6 AM",
    Followers: 3500,
  },
];
const StatsData = [
  {
    name: "10 AM",
    Stats: 1000,
  },
  {
    name: "12 PM",
    Stats: 2300,
  },
  {
    name: "14 PM",
    Stats: 1900,
  },
  {
    name: "16 PM",
    Stats: 2900,
  },
  {
    name: "18 PM",
    Stats: 1200,
  },
  {
    name: "20 PM",
    Stats: 3200,
  },
  {
    name: "22 PM",
    Stats: 1000,
  },
  {
    name: "12 AM",
    Stats: 2900,
  },
  {
    name: "2 AM",
    Stats: 1300,
  },
  {
    name: "4 AM",
    Stats: 4000,
  },
];

function Statics() {
  const [followersFilter, setFollowersFilter] = useState("");
  const [stateFilter1, setStateFilter1] = useState("");
  const [stateFilter2, setStateFilter2] = useState("");
  const [t] = useTranslation();
  return (
    <>
      <div className="top_boxes">
        <div className="infos">
          <p>
            <span>{t("Dashboard.Analytics.Username")}:</span>
            <span>ETH agressive</span>
          </p>
          <p>
            <span>{t("Dashboard.Analytics.Followers")}:</span>
            <span>290/+10%</span>
          </p>
          <p>
            <span>{t("Dashboard.Analytics.Exchange")}:</span>
            <span>Binance features (USDT-M) One mode way</span>
          </p>
          <p>
            <span>{t("Dashboard.Analytics.Price")}:</span>
            <span>10$</span>
          </p>
          <p>
            <span>{t("Dashboard.Analytics.CurrentOpenPositions")}:</span>
            <span>150</span>
          </p>
          <p>
            <span>{t("Dashboard.Analytics.CumulativeUnrealizedP/L")}:</span>
            <span>0% 0$</span>
          </p>
        </div>
        <div className="followers_progress">
          <div className="title_sec">
            <h2>{t("Dashboard.Analytics.FollowerProgress")}</h2>
            <div className="filter">
              <Select
                value={followersFilter}
                onChange={(e) => setFollowersFilter(e.target.value)}
                displayEmpty
                className="select_filter"
              >
                <MenuItem value="">{t("Dashboard.select1.a")}</MenuItem>
                <MenuItem value={t("Dashboard.select1.b")}>
                  {t("Dashboard.select1.b")}
                </MenuItem>
                <MenuItem value={t("Dashboard.select1.c")}>
                  {t("Dashboard.select1.c")}
                </MenuItem>
                <MenuItem value={t("Dashboard.select1.d")}>
                  {t("Dashboard.select1.d")}
                </MenuItem>
                <MenuItem value={t("Dashboard.select1.e")}>
                  {t("Dashboard.select1.e")}
                </MenuItem>
              </Select>
            </div>
          </div>
          <div className="chart">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={FollowersData}
                barSize={22}
                margin={{
                  top: 5,
                  right: 0,
                  left: 0,
                  bottom: 5,
                }}
              >
                <defs>
                  <linearGradient id="Gradient2" x1="0" x2="0" y1="0" y2="1">
                    <stop offset="0%" stopColor="#5cc159" />
                    <stop offset="100%" stopColor="#9ff49c" />
                  </linearGradient>
                </defs>

                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="Followers" fill="url(#Gradient2)" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className="stats">
        <div className="title_sec">
          <h2>{t("Dashboard.Analytics.Stats")}</h2>
          <div className="filter">
            <Select
              value={stateFilter1}
              onChange={(e) => setStateFilter1(e.target.value)}
              displayEmpty
              className="select_filter"
            >
              <MenuItem value="">AVG P/L %</MenuItem>
              <MenuItem value="P/L">P/L</MenuItem>
              <MenuItem value="ROI">ROI</MenuItem>
              <MenuItem value="Volume">Volume</MenuItem>
              <MenuItem value="Number of Trades">Number of Trades</MenuItem>
              <MenuItem value="Average Time in Trades in Minutes">
                Average Time in Trades in Minutes
              </MenuItem>
              <MenuItem value="Profit Factor">Profit Factor</MenuItem>
              <MenuItem value="Profitability">Profitability</MenuItem>
            </Select>
            <Select
              value={stateFilter2}
              onChange={(e) => setStateFilter2(e.target.value)}
              displayEmpty
              className="select_filter"
            >
              <MenuItem value="">{t("Dashboard.select1.a")}</MenuItem>
              <MenuItem value={t("Dashboard.select1.b")}>
                {t("Dashboard.select1.b")}
              </MenuItem>
              <MenuItem value={t("Dashboard.select1.c")}>
                {t("Dashboard.select1.c")}
              </MenuItem>
              <MenuItem value={t("Dashboard.select1.d")}>
                {t("Dashboard.select1.d")}
              </MenuItem>
              <MenuItem value={t("Dashboard.select1.e")}>
                {t("Dashboard.select1.e")}
              </MenuItem>
            </Select>
          </div>
        </div>
        <div className="chart">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={StatsData}
              margin={{
                top: 5,
                right: 5,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="Stats"
                stroke="#5CC159"
                strokeWidth="3"
                fill="#5cc15961"
                activeDot={{ r: 0 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="statistics">
        <div className="statistics_table">
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>{t("Dashboard.Analytics.AllTime")}</th>
                <th>{t("Dashboard.Analytics.Weekly")}</th>
                <th>{t("Dashboard.Analytics.Monthly")}</th>
                <th>{t("Dashboard.Analytics.Yearly")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t("Dashboard.Analytics.AVGP/L")}</td>
                <td>
                  <span className="plus">13%</span>
                </td>
                <td>
                  <span className="min">-0.05%</span>
                </td>
                <td>
                  <span className="min">-0.70%</span>
                </td>
                <td>
                  <span className="plus">0.75%</span>
                </td>
              </tr>
              <tr>
                <td>{t("Dashboard.Analytics.P/L")}</td>
                <td>
                  <span className="min">-$13</span>
                </td>
                <td>
                  <span className="min">-$13</span>
                </td>
                <td>
                  <span className="plus">$3.5</span>
                </td>
                <td>
                  <span className="plus">$11.5</span>
                </td>
              </tr>
              <tr>
                <td>{t("Dashboard.Analytics.ROI")}</td>
                <td>
                  <span className="plus">145%</span>
                </td>
                <td>
                  <span className="plus">145%</span>
                </td>
                <td>
                  <span className="plus">145%</span>
                </td>
                <td>
                  <span className="plus">125%</span>
                </td>
              </tr>
              <tr>
                <td>{t("Dashboard.Analytics.MaxLoss/Gain")}</td>
                <td>
                  <span className="min">-112.5%</span> /{" "}
                  <span className="plus">145%</span>
                </td>
                <td>
                  <span className="min">-112.5%</span> /{" "}
                  <span className="plus">145%</span>
                </td>
                <td>
                  <span className="min">-112.5%</span> /{" "}
                  <span className="plus">145%</span>
                </td>
                <td>
                  <span className="min">-112.5%</span> /{" "}
                  <span className="plus">145%</span>
                </td>
              </tr>
              <tr>
                <td>{t("Dashboard.Analytics.Volume")}</td>
                <td>$14564.5</td>
                <td>$14564.5</td>
                <td>$13564.5</td>
                <td>$18264.5</td>
              </tr>
              <tr>
                <td>{t("Dashboard.Analytics.Min/Avg/Max")}</td>
                <td>226.3 / 254.1 / 783.4</td>
                <td>226.3 / 254.1 / 783.4</td>
                <td>226.3 / 254.1 / 783.4</td>
                <td>226.3 / 254.1 / 783.4</td>
              </tr>
              <tr>
                <td>{t("Dashboard.Analytics.NTrades")}</td>
                <td>39</td>
                <td>39</td>
                <td>39</td>
                <td>39</td>
              </tr>
              <tr>
                <td>{t("Dashboard.Analytics.AverageTimeInTrades")}</td>
                <td>0d 10h 20min 22s</td>
                <td>0d 10h 20min 22s</td>
                <td>0d 10h 20min 22s</td>
                <td>0d 10h 20min 22s</td>
              </tr>
              <tr>
                <td>{t("Dashboard.Analytics.ProfitFactor")}</td>
                <td>0.75</td>
                <td>0.75</td>
                <td>0.89</td>
                <td>0.86</td>
              </tr>
              <tr>
                <td>{t("Dashboard.Analytics.ProfitVolume")}</td>
                <td>
                  <span className="min">-0.70%</span>
                </td>
                <td>
                  <span className="min">-0.70%</span>
                </td>
                <td>
                  <span className="min">-0.70%</span>
                </td>
                <td>
                  <span className="plus">0.75%</span>
                </td>
              </tr>
              <tr>
                <td>{t("Dashboard.Analytics.PercentProfitable")}</td>
                <td>
                  <span className="plus">75%</span>
                </td>
                <td>
                  <span className="plus">75%</span>
                </td>
                <td>
                  <span className="plus">85%</span>
                </td>
                <td>
                  <span className="plus">89%</span>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default Statics;
