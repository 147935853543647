import executeRequest from "../utils/apiService";

const profileServices = {

    async enable2FA(onSuccess, onFailure) {
        return executeRequest({
            method: "POST",
            url: `/api/Profile/2fauth`,
        })
            .then(onSuccess)
            .catch(onFailure);
    },
    async disable2FA(onSuccess, onFailure) {
        return executeRequest({
            method: "DELETE",
            url: `/api/Profile/2fauth`,
        })
            .then(onSuccess)
            .catch(onFailure);
    },

    async connectMyTelegram(token, onSuccess, onFailure) {
        return executeRequest({
            method: "POST",
            url: `/api/Profile/telegram`,
            data: { token }
        })
            .then(onSuccess)
            .catch(onFailure);
    },

    async addTelegramToken(token, onSuccess, onFailure) {
        return executeRequest({
            method: "PUT",
            url: `/api/Profile/telegram/token`,
            data: { token }
        })
            .then(onSuccess)
            .catch(onFailure);
    },
    async getMyProfile(onSuccess, onFailure) {
        return executeRequest({
            method: "GET",
            url: `/api/Profile`,
        })
            .then(onSuccess)
            .catch(onFailure);
    },
    async disconnectMyTelegram(onSuccess, onFailure) {
        return executeRequest({
            method: "DELETE",
            url: `/api/Profile/telegram`,
        })
            .then(onSuccess)
            .catch(onFailure);
    },
    /**
     * Updates the user's profile with the provided data.
     * 
     * @param {Object} data - The data to update the user's profile.
     * @param {string} data.name - The user's name.
     * @param {string} data.phone - The user's phone number.
     * @param {boolean} data.allowCopyTrades - Flag to allow or disallow copy trades.
     * @param {string} data.about - A brief description about the user.
     * @param {string} data.timeZone - The user's time zone. Must be a valid time zone string.
     * @param {string} data.username - The user's username.
     * 
     * @returns {Promise<void>} A promise that resolves when the update is successful or rejects with an error.
     * 
     * The function attempts to update the user's profile via a PUT request to the `/api/Profile` endpoint.
     * On successful update, it calls `onSuccess` with the response data.
     * If the update fails, it calls `onFailure` with the error.
     */
    async updateMyProfile(data, onSuccess, onFailure) {
        return executeRequest({
            method: "PUT",
            url: `/api/Profile`,
            data
        })
            .then(onSuccess)
            .catch(onFailure);
    },
    async deleteProfile(onSuccess, onFailure) {
        return executeRequest({
            method: "DELETE",
            url: `/api/Profile/2fauth`,
        })
            .then(onSuccess)
            .catch(onFailure);
    },
}

export default profileServices