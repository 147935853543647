export default function Signal({ signal }) {
  return (<tr>
    <td>{signal.id}</td>
    <td>{signal.pair}</td>
    <td>
      <p className={signal.side === "long" || signal.side === "buy" ? "buy" : "sell"}>{signal.side}</p>
    </td>
    <td>{new Date(signal.timeStamp).toLocaleDateString() + " " + new Date(signal.timeStamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</td>
    <td>{signal.entryPrice?.toFixed(2)}</td>
    <td>{signal.unrealizedPnl?.toFixed(2)}</td>
    <td>{signal.size}</td>
    <td>{`${signal.exchange}/${signal.type}`}</td>
    <td>
      <p className="signal-origin">
        {signal.isWebhook ? "TradingView" : "Telegram"}
      </p>
    </td>
  </tr>)
}