import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./css/login.scss";
import RightCov from "./login&sign/RightCov";
import authService from "./services/auth";
import { useTranslation } from "react-i18next";

// Components
import BackButton from "./login&sign/BackButton";
// Assets
import Logo from "./assets/insta-signal_FINAL icon 1.png";

function SignIn() {
  const [showPassword, setShowPassword] = useState(true);
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false)
  const [email, setEmail] = useState('');
  const [t] = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check for the token in local storage
    const token = localStorage.getItem("token");
    if (token) {
      // If token exists, redirect to '/home'
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setLoginError(false)
  }, [password, email])
  const loginUser = async (e) => {
    e.preventDefault();
    authService.login(
      email,
      password,
      () => navigate('/'),
      message => setLoginError(message)
    )
  };

  return (
    <div className="signin">
      <div className="log_content">
        <form>
          <div className="top_part">
            <img src={Logo} alt="" />
            <h1>{t("login.title1")}</h1>
            <p>{t("login.title2")}</p>
            {/* <div className="sign_with">
              <button>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M33.1346 18.5368C33.1346 17.4868 33.0485 16.4324 32.8676 15.4H18.2207V21.3448H26.6074C26.4344 22.2962 26.0701 23.2025 25.5366 24.009C25.0031 24.8154 24.3116 25.5053 23.5038 26.0367V29.897H28.5089C31.4471 27.2058 33.1346 23.2353 33.1346 18.5368Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M18.2225 33.6228C22.4093 33.6228 25.9409 32.2551 28.5129 29.8948L23.51 26.0368C22.1181 26.9787 20.3203 27.5125 18.227 27.5125C14.177 27.5125 10.7425 24.7949 9.50938 21.142H4.34766V25.1191C5.64823 27.6807 7.63348 29.8315 10.0828 31.3326C12.5322 32.8338 15.3498 33.6265 18.2225 33.6228Z"
                    fill="#34A853"
                  />
                  <path
                    d="M9.50453 21.1419C8.85405 19.2251 8.85405 17.1471 9.50453 15.2302V11.2531H4.34722C3.26115 13.4028 2.69531 15.7776 2.69531 18.1861C2.69531 20.5946 3.26115 22.9694 4.34722 25.1191L9.50453 21.1419Z"
                    fill="#FBBC04"
                  />
                  <path
                    d="M18.2225 8.85084C20.4342 8.81499 22.572 9.64636 24.1784 11.167L28.6122 6.75748C25.7943 4.13263 22.0732 2.69477 18.2225 2.7428C15.349 2.73916 12.5307 3.53268 10.0812 5.0351C7.63168 6.53753 5.64692 8.68996 4.34766 11.253L9.50056 15.2258C10.727 11.5685 14.1682 8.85084 18.2181 8.85084H18.2225Z"
                    fill="#EA4335"
                  />
                </svg>
              </button>
              <button>
                <svg
                  width="31"
                  height="32"
                  viewBox="0 0 31 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_189_2003)">
                    <path
                      d="M15.4998 31.5292C23.976 31.5292 30.8473 24.6579 30.8473 16.1817C30.8473 7.70553 23.976 0.834229 15.4998 0.834229C7.02364 0.834229 0.152344 7.70553 0.152344 16.1817C0.152344 24.6579 7.02364 31.5292 15.4998 31.5292Z"
                      fill="#1DA1F2"
                    />
                    <path
                      d="M23.4728 11.4696C22.9093 11.7184 22.3068 11.8862 21.6713 11.9642C22.3187 11.5745 22.8163 10.96 23.0501 10.2286C22.4446 10.5823 21.7702 10.8401 21.0568 10.984C20.4842 10.3725 19.6719 9.98877 18.7666 9.98877C17.0341 9.98877 15.6282 11.3946 15.6282 13.1242C15.6282 13.373 15.6582 13.6128 15.7091 13.8406C13.1013 13.7177 10.7872 12.4647 9.24041 10.5703C8.96764 11.0319 8.81476 11.5655 8.81476 12.15C8.81476 13.2411 9.36931 14.2003 10.2116 14.7639C9.69604 14.7459 9.21344 14.605 8.79078 14.3712V14.4102C8.79078 15.9329 9.8699 17.2009 11.3087 17.4886C11.0449 17.5606 10.7662 17.5966 10.4814 17.5966C10.2806 17.5966 10.0887 17.5786 9.89688 17.5426C10.2986 18.7896 11.4556 19.6978 12.8345 19.7218C11.7644 20.5641 10.4035 21.0647 8.94366 21.0647C8.69486 21.0647 8.44606 21.0497 8.19727 21.0228C9.59413 21.913 11.2398 22.4316 13.0173 22.4316C18.7876 22.4316 21.944 17.6505 21.944 13.5109C21.944 13.379 21.944 13.2441 21.9351 13.1092C22.5466 12.6686 23.0831 12.114 23.5028 11.4845L23.4728 11.4696Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_189_2003">
                      <rect
                        width="30.6949"
                        height="30.6949"
                        fill="white"
                        transform="translate(0.152344 0.834229)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M33.5292 18.1817C33.5292 14.0864 31.9024 10.1589 29.0066 7.26319C26.1109 4.36743 22.1834 2.74061 18.0882 2.74061C14.2 2.73702 10.4536 4.20043 7.59727 6.83853C4.74098 9.47662 2.98509 13.0952 2.68034 16.9714C2.37559 20.8476 3.54442 24.6961 5.95335 27.7482C8.36228 30.8002 11.834 32.8311 15.6749 33.4352V22.6441H11.7573V18.1817H15.6771V14.7802C15.6771 10.9111 17.9823 8.77144 21.5095 8.77144C23.1991 8.77144 24.966 9.07365 24.966 9.07365V12.8743H23.0183C21.0992 12.8743 20.5036 14.0655 20.5036 15.2854V18.1817H24.7852L24.1013 22.6441H20.5014V33.4352C24.1333 32.8601 27.4409 31.0078 29.8291 28.2116C32.2172 25.4155 33.5293 21.8589 33.5292 18.1817Z"
                    fill="#1877F2"
                  />
                  <path
                    d="M24.0996 22.6442L24.7834 18.1817H20.5019V15.2854C20.5019 14.0656 21.0974 12.8744 23.0165 12.8744H24.9643V9.07149C24.9643 9.07149 23.1974 8.76929 21.5077 8.76929C17.9784 8.76929 15.6754 10.909 15.6754 14.7781V18.1817H11.7578V22.6442H15.6776V33.4353C17.2766 33.6869 18.9051 33.6869 20.5041 33.4353V22.6442H24.0996Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div className="or">
              <p>Or</p>
            </div> */}
          </div>
          <div className="form_part">
            <div className="inpt">
              <label>{t("login.lbl1")}</label>
              <input type="email" placeholder={t("login.inpt1")}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="inpt">
              <label>{t("login.lbl2")}</label>
              <input
                type={`${showPassword ? "password" : "text"}`}
                placeholder={t("login.inpt2")}
                onChange={e => setPassword(e.target.value)}
              />
              <div
                className="show_pwd"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                <svg
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.7036 15.6372C9.51814 15.6372 7.74414 13.8632 7.74414 11.6777C7.74414 9.49226 9.51814 7.71826 11.7036 7.71826C13.8891 7.71826 15.6631 9.49226 15.6631 11.6777C15.6631 13.8632 13.8891 15.6372 11.7036 15.6372ZM11.7036 9.08991C10.2771 9.08991 9.11579 10.2512 9.11579 11.6777C9.11579 13.1043 10.2771 14.2656 11.7036 14.2656C13.1301 14.2656 14.2915 13.1043 14.2915 11.6777C14.2915 10.2512 13.1301 9.08991 11.7036 9.08991Z"
                    fill="#8B948B"
                  />
                  <path
                    d="M11.7032 19.9259C8.26496 19.9259 5.01873 17.9142 2.78752 14.421C1.81822 12.9122 1.81822 10.4524 2.78752 8.93444C5.02788 5.44131 8.27411 3.42957 11.7032 3.42957C15.1323 3.42957 18.3786 5.44131 20.6098 8.93444C21.5791 10.4433 21.5791 12.9031 20.6098 14.421C18.3786 17.9142 15.1323 19.9259 11.7032 19.9259ZM11.7032 4.80121C8.74961 4.80121 5.92402 6.57521 3.94885 9.67513C3.26302 10.745 3.26302 12.6105 3.94885 13.6803C5.92402 16.7803 8.74961 18.5543 11.7032 18.5543C14.6568 18.5543 17.4824 16.7803 19.4576 13.6803C20.1434 12.6105 20.1434 10.745 19.4576 9.67513C17.4824 6.57521 14.6568 4.80121 11.7032 4.80121Z"
                    fill="#8B948B"
                  />
                </svg>
              </div>
            </div>
            {
              loginError && <div className="verify mt-15">
                <p className="no">{loginError}</p>
              </div>
            }
            <div className="check_forget">
              <div className="pr_tu">
                {t("login.rem")}
                <input type="checkbox" />
                <div className="box">
                  <svg
                    width="9"
                    height="7"
                    viewBox="0 0 9 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.46387 6.36764C3.29261 6.36764 3.12991 6.29914 3.01002 6.17925L0.58664 3.75587C0.338308 3.50754 0.338308 3.09651 0.58664 2.84817C0.834972 2.59984 1.246 2.59984 1.49434 2.84817L3.46387 4.81771L7.86534 0.41623C8.11368 0.167897 8.52471 0.167897 8.77304 0.41623C9.02137 0.664562 9.02137 1.07559 8.77304 1.32393L3.91772 6.17925C3.79783 6.29914 3.63513 6.36764 3.46387 6.36764Z"
                      fill="#5CC159"
                    />
                  </svg>
                </div>
              </div>
              <Link to="/reset-password">{t("login.fgPwd")}</Link>
            </div>
          </div>
          <button className="btn_sign" onClick={loginUser}>
            {t("login.login")}
          </button>
          <div className="qs_sign">
            {t("login.have")}
            <Link to="/sign-up"> {t("login.signup")}</Link>
          </div>
        </form>
        <BackButton />
      </div>
      <RightCov />
    </div>
  );
}

export default SignIn;
