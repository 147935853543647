import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import ProblemPopup from "./popup/problem";

// Components
import exchangeService from "../services/exchange";
import { ToastAlert, toast } from "./Alert/ToastAlert";

// Assets
// import BinanceLogo from "../assets/Binance.png";
// import BybitLogo from "../assets/Binance.png";
// import KuCoinLogo from "../assets/KuCoin.png";
// import OkxLogo from "../assets/OKX.png";

function Exchange() {
  // Language
  const [t] = useTranslation();

  const [AddExchange, setAddExchange] = useState(false);
  const [POPSelect, setPOPSelect] = useState("add-exchange");
  const [EditPop, setEditPop] = useState(false);

  const [deleteExchangeId, setDeleteExchangeId] = useState();
  const [myExchanges, setExchanges] = useState([]);
  const [supportedExchanges, setSupportedExchanges] = useState([]);

  const [addSelect, setAddSelect] = useState("spot");
  const [selectExchangeToAdd, setselectExchangeToAdd] = useState(null);
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");

  const [connectBtnStatus, setConnectBtnStatus] = useState(false);

  useEffect(() => {
    fetchSupportedExchanges();
    fetchExchanges();
  }, []);

  useEffect(() => {
    if (apiKey.length > 0 && apiSecret.length > 0) {
      setConnectBtnStatus(true);
    } else {
      setConnectBtnStatus(false);
    }
  }, [apiKey, apiSecret]);

  const clearInputs = () => {
    setApiKey("");
    setApiSecret("");
    setselectExchangeToAdd(null);
  };

  // API
  const fetchExchanges = async () => {
    exchangeService.getExchanges(
      (data) => {
        setExchanges(data.exchanges);
      },
      (err) => console.log(err)
    );
  };
  const enableOrDisableExchange = async (myExchange) => {
    exchangeService.toggleExchange(
      myExchange._id,
      (data) => {
        fetchExchanges();
      },
      (err) => {
        toast.error(err.response.data.message);
      }
    );
  };
  const deleteExchange = async (exchangeId) => {
    setDeleteExchangeId(false);
    exchangeService.deleteExchange(
      exchangeId,
      () => {
        fetchExchanges();
        toast.success("The Channel has been deleted");
      },
      (err) => {
        toast.error(err.response.data.message);
      }
    );
  };
  const fetchSupportedExchanges = async () => {
    exchangeService.getSupportedExchanges(
      (data) => {
        setSupportedExchanges(data.exchanges);
      },
      (err) => console.log(err)
    );
  };
  const connectNewExchange = () => {
    exchangeService.createExchange(
      addSelect,
      selectExchangeToAdd,
      apiKey,
      apiSecret,
      (data) => {
        console.info(data);
        toast.success("The Channel has been Added");
        fetchExchanges();
        setAddExchange(false);
        clearInputs();
      },
      (error) => {
        toast.error(error.response.data.message);
      }
    );
  };

  return (
    <div className="dashboard-exchange">
      <div className="title">
        <h1>{t("Dashboard.Exchange.ee")}</h1>
      </div>
      <form className="exchSelect">
        <div className="cont">
          {/* <div className="inpt">
            <label>{t("Dashboard.Exchange.a")}</label>
            <Select
              value={exchange}
              onChange={(e) => setExchange(e.target.value)}
              displayEmpty
              className="select_filter"
            >
              <MenuItem value="">BINANCE-SPOT</MenuItem>
              <MenuItem value="DUBAI GMT+5">KuCoin</MenuItem>
              <MenuItem value="DUBAI GMT+6">Bybit</MenuItem>
              <MenuItem value="DUBAI GMT+7">OKX</MenuItem>
            </Select>
          </div> */}
          <button
            type="button"
            onClick={() => {
              setPOPSelect("add-exchange");
              setAddExchange(true);
            }}
          >
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.66406 11.1391H16.6391M11.1516 16.6266L11.1516 5.65155"
                stroke="white"
                strokeWidth="1.82917"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {t("Dashboard.Exchange.b")}
          </button>
        </div>
      </form>
      <div className="exchange-table table-bor">
        <Table>
          <thead>
            <tr>
              <th>{t("Dashboard.Exchange.tb")}</th>
              <th>{t("Dashboard.Exchange.tb3")}</th>
              <th>{t("Dashboard.Exchange.tb5")}</th>
              <th>{t("Dashboard.Exchange.tb6")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {myExchanges.length === 0 && (
              <tr>
                <td colSpan="9">
                  <div className="empty-table">
                    <p>There are no exchange at the moment.</p>
                    <button onClick={(e) => setAddExchange(true)}>
                      Add Exchange
                    </button>
                  </div>
                </td>
              </tr>
            )}
            {myExchanges.map((item) => (
              <tr>
                <td>
                  <div className="logo">
                    <img src={item.logo} alt={item.provider} />
                    <p>{`${item.provider}-${item.isFuture ? t("Future") : t("Spot")
                      }`}</p>
                  </div>
                </td>
                <td>
                  <p>{item.provider}</p>
                </td>
                <td>
                  <div className="api_copy">
                    <p>{`${item.secret.slice(0, 40)}...`}</p>
                    <div
                      class="copy"
                      onClick={() => {
                        navigator.clipboard.writeText(item.secret);
                      }}
                    >
                      <svg
                        width="29"
                        height="28"
                        viewBox="0 0 29 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.5052 15.05V19.95C19.5052 24.0333 17.8719 25.6666 13.7885 25.6666H8.88854C4.80521 25.6666 3.17188 24.0333 3.17188 19.95V15.05C3.17188 10.9666 4.80521 9.33331 8.88854 9.33331H13.7885C17.8719 9.33331 19.5052 10.9666 19.5052 15.05Z"
                          fill="#5CC159"
                        ></path>
                        <path
                          d="M20.7925 2.33331H15.8925C12.2956 2.33331 10.6086 3.60975 10.2572 6.69549C10.1837 7.34105 10.7183 7.87498 11.3681 7.87498H13.7925C18.6925 7.87498 20.9675 10.15 20.9675 15.05V17.4744C20.9675 18.1242 21.5014 18.6588 22.147 18.5853C25.2327 18.2338 26.5092 16.5469 26.5092 12.95V8.04998C26.5092 3.96665 24.8758 2.33331 20.7925 2.33331Z"
                          fill="#5CC159"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="switch">
                    <input
                      type="checkbox"
                      onChange={() => {
                        enableOrDisableExchange(item);
                      }}
                      checked={item.active}
                    />
                  </div>
                </td>
                <td>
                  <div className="options">
                    {false && (
                      <button className="edit" onClick={() => setEditPop(true)}>
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M25.8676 27.521H3.83314C3.33125 27.521 2.91504 27.1048 2.91504 26.6029C2.91504 26.101 3.33125 25.6848 3.83314 25.6848H25.8676C26.3695 25.6848 26.7857 26.101 26.7857 26.6029C26.7857 27.1048 26.3695 27.521 25.8676 27.521Z"
                            fill="#99A399"
                          />
                          <path
                            d="M23.4437 4.84992C21.0689 2.47509 18.743 2.41389 16.307 4.84992L14.8257 6.33113C14.7033 6.45354 14.6544 6.64941 14.7033 6.82078C15.6337 10.0648 18.2289 12.6599 21.4728 13.5903C21.5218 13.6025 21.5707 13.6148 21.6197 13.6148C21.7544 13.6148 21.8768 13.5658 21.9747 13.4679L23.4437 11.9866C24.6556 10.787 25.2432 9.62406 25.2432 8.44889C25.2554 7.23699 24.6678 6.06182 23.4437 4.84992Z"
                            fill="#99A399"
                          />
                          <path
                            d="M19.2703 14.7042C18.9153 14.5329 18.5726 14.3615 18.2421 14.1656C17.9728 14.0065 17.7157 13.8351 17.4586 13.6515C17.2505 13.5168 17.0057 13.321 16.7731 13.1251C16.7486 13.1129 16.6629 13.0394 16.565 12.9415C16.161 12.5987 15.7081 12.158 15.3041 11.6684C15.2674 11.6439 15.2062 11.5582 15.1205 11.448C14.9981 11.3011 14.79 11.0563 14.6064 10.7748C14.4595 10.5911 14.2881 10.3218 14.129 10.0525C13.9331 9.72201 13.7617 9.39149 13.5903 9.04873C13.5644 8.99316 13.5393 8.93787 13.515 8.8829C13.3343 8.47488 12.8023 8.35559 12.4868 8.67112L5.47432 15.6836C5.31518 15.8427 5.16828 16.1487 5.13156 16.3568L4.47052 21.0453C4.34811 21.8777 4.58069 22.6611 5.09483 23.1875C5.53552 23.616 6.14759 23.8486 6.80863 23.8486C6.95552 23.8486 7.10242 23.8363 7.24932 23.8118L11.95 23.1508C12.1703 23.1141 12.4764 22.9672 12.6233 22.808L19.627 15.8044C19.9447 15.4866 19.8248 14.9413 19.4115 14.765C19.365 14.7451 19.318 14.7249 19.2703 14.7042Z"
                            fill="#99A399"
                          />
                        </svg>
                      </button>
                    )}
                    <button
                      className="remove"
                      onClick={() => setDeleteExchangeId(item._id)}
                    >
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M26.3327 6.99219C24.3618 6.79632 22.391 6.64943 20.4079 6.53926V6.52701L20.1386 4.93563C19.9549 3.80943 19.6856 2.12012 16.8211 2.12012H13.6139C10.7617 2.12012 10.4923 3.73598 10.2965 4.92339L10.0394 6.49029C8.90097 6.56374 7.76252 6.63719 6.62407 6.74736L4.12683 6.99219C3.61269 7.04115 3.24545 7.49408 3.29442 7.99598C3.34338 8.49788 3.78407 8.86512 4.29821 8.81615L6.79545 8.57132C13.2099 7.93477 19.6734 8.1796 26.1613 8.82839C26.198 8.82839 26.2225 8.82839 26.2592 8.82839C26.7244 8.82839 27.1284 8.47339 27.1773 7.99598C27.2141 7.49408 26.8468 7.04115 26.3327 6.99219Z"
                          fill="#E72E4F"
                        />
                        <path
                          d="M24.0797 10.5544C23.7859 10.2484 23.3819 10.077 22.9657 10.077H7.4926C7.07639 10.077 6.66019 10.2484 6.37863 10.5544C6.09708 10.8605 5.93794 11.2767 5.96243 11.7051L6.72139 24.2648C6.85605 26.1255 7.02743 28.4513 11.2997 28.4513H19.1586C23.4309 28.4513 23.6023 26.1377 23.7369 24.2648L24.4959 11.7174C24.5204 11.2767 24.3612 10.8605 24.0797 10.5544ZM17.2612 22.3184H13.1848C12.6829 22.3184 12.2667 21.9022 12.2667 21.4003C12.2667 20.8984 12.6829 20.4822 13.1848 20.4822H17.2612C17.7631 20.4822 18.1793 20.8984 18.1793 21.4003C18.1793 21.9022 17.7631 22.3184 17.2612 22.3184ZM18.2895 17.4219H12.1688C11.6669 17.4219 11.2507 17.0056 11.2507 16.5038C11.2507 16.0019 11.6669 15.5856 12.1688 15.5856H18.2895C18.7914 15.5856 19.2076 16.0019 19.2076 16.5038C19.2076 17.0056 18.7914 17.4219 18.2895 17.4219Z"
                          fill="#E72E4F"
                        />
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {/* Add Exchange */}
      {AddExchange && (
        <div className="pop-up exchange-pop-up">
          <div className="pop-up-content">
            {POPSelect === "add-exchange" && (
              <>
                <div className="pop-up-title">
                  <h1>{t("Dashboard.Exchange.cc")}</h1>
                  <p>{t("Dashboard.Exchange.xw")}</p>
                  <div
                    className="close"
                    onClick={(e) => {
                      clearInputs();
                      setAddExchange(false);
                    }}
                  >
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M27.0009 26.9999L9.00098 9M27.001 9L9.00098 27.0001"
                        stroke="black"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="exchange_content">
                  <ul className="list-unstyled links">
                    <li
                      className={`${addSelect === "spot" ? "active" : ""}`}
                      onClick={(e) => {
                        setAddSelect("spot");
                        setselectExchangeToAdd(null);
                      }}
                    >
                      Spot
                    </li>
                    <li
                      className={`${addSelect === "usdt-m" ? "active" : ""}`}
                      onClick={(e) => {
                        setAddSelect("usdt-m");
                        setselectExchangeToAdd(null);
                      }}
                    >
                      Futures USDT-M
                    </li>
                  </ul>
                  {addSelect === "spot" && (
                    <div className="spot">
                      {supportedExchanges
                        .filter((item) => item.isSpot)
                        .map((item, index) => (
                          <div className="item">
                            <input
                              onClick={() =>
                                setselectExchangeToAdd(item.provider)
                              }
                              type="radio"
                              name="spot"
                            />
                            <div className="spot_item">
                              {/* {item.provider=="binance"} */}
                              <img src={item.logo} alt={item.provider} />
                              <p>{item.provider}</p>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                  {addSelect === "usdt-m" && (
                    <div className="usdt-m">
                      {supportedExchanges
                        .filter((item) => item.isFuture)
                        .map((item, index) => (
                          <div className="item">
                            <input
                              onClick={() =>
                                setselectExchangeToAdd(item.provider)
                              }
                              type="radio"
                              name="usdt-m"
                            />
                            <div className="spot_item">
                              <img src={item.logo} alt={item.provider} />
                              <p>{item.provider}</p>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                  <button
                    className={`next ${selectExchangeToAdd ? "active" : ""}`}
                    onClick={() => setPOPSelect("connect")}
                  >
                    {t("Dashboard.Exchange.vb")}
                  </button>
                </div>
              </>
            )}
            {POPSelect === "connect" && (
              <>
                <div
                  className="back_to"
                  onClick={() => setPOPSelect("add-exchange")}
                >
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.4978 29.8799L12.7178 20.0999C11.5628 18.9449 11.5628 17.0549 12.7178 15.8999L22.4978 6.11987"
                      stroke="#5CC159"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {t("Dashboard.Exchange.fdsf")}
                </div>
                <div className="pop-up-title">
                  <h1>{t("Dashboard.Exchange.cx")}</h1>
                  <div
                    className="close"
                    onClick={(e) => {
                      clearInputs();
                      setAddExchange(false);
                    }}
                  >
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M27.0009 26.9999L9.00098 9M27.001 9L9.00098 27.0001"
                        stroke="black"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="exchange_content">
                  <div className="key">
                    <p>{t("Dashboard.Exchange.fr")}</p>
                    <div className="steps">
                      <div className="step">
                        <p>
                          <span>1.</span>
                          {t("Dashboard.Exchange.zzq")}
                        </p>
                      </div>
                      <div className="step">
                        <p>
                          <span>2.</span>
                          {t("Dashboard.Exchange.fx")}
                        </p>
                        <div className="api">
                          <p>3.122.50.188,18.192.236.147.356</p>
                          <div
                            className="copy"
                            onClick={() =>
                              navigator.clipboard.writeText(
                                "3.122.50.188,18.192.236.147.356"
                              )
                            }
                          >
                            <svg
                              width="29"
                              height="28"
                              viewBox="0 0 29 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.5052 15.05V19.95C19.5052 24.0333 17.8719 25.6666 13.7885 25.6666H8.88854C4.80521 25.6666 3.17188 24.0333 3.17188 19.95V15.05C3.17188 10.9666 4.80521 9.33331 8.88854 9.33331H13.7885C17.8719 9.33331 19.5052 10.9666 19.5052 15.05Z"
                                fill="#5CC159"
                              />
                              <path
                                d="M20.7925 2.33331H15.8925C12.2956 2.33331 10.6086 3.60975 10.2572 6.69549C10.1837 7.34105 10.7183 7.87498 11.3681 7.87498H13.7925C18.6925 7.87498 20.9675 10.15 20.9675 15.05V17.4744C20.9675 18.1242 21.5014 18.6588 22.147 18.5853C25.2327 18.2338 26.5092 16.5469 26.5092 12.95V8.04998C26.5092 3.96665 24.8758 2.33331 20.7925 2.33331Z"
                                fill="#5CC159"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="step">
                        <p>
                          <span>3.</span>
                          {t("Dashboard.Exchange.fe")}
                        </p>
                      </div>
                    </div>
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("Dashboard.Exchange.sc")}
                    </a>
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("Dashboard.Exchange.ds")}
                    </a>
                  </div>
                  <form>
                    <div className="inpt">
                      <label>{t("Dashboard.Exchange.ff")}</label>
                      <input
                        type="text"
                        placeholder="AF4DGG5GXDF"
                        value={apiKey}
                        onChange={(e) => setApiKey(e.target.value)}
                      ></input>
                    </div>
                    <div className="inpt">
                      <label>{t("Dashboard.Exchange.rz")}</label>
                      <input
                        type="text"
                        placeholder="AF4DGG5GXDF"
                        value={apiSecret}
                        onChange={(e) => setApiSecret(e.target.value)}
                      ></input>
                    </div>
                    <button
                      type="button"
                      disabled={!connectBtnStatus}
                      className={connectBtnStatus ? "active" : ""}
                      onClick={() => connectNewExchange()}
                    >
                      {t("Dashboard.Exchange.rr")}
                    </button>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {/* Edit API Key and API Secret */}
      {EditPop && (
        <div className="pop-up editAPI-pop-up">
          <div className="pop-up-content">
            <div className="pop-up-title">
              <h1>{t("Dashboard.Exchange.ea")}</h1>
              <div className="close" onClick={(e) => setEditPop(false)}>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.0009 26.9999L9.00098 9M27.001 9L9.00098 27.0001"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <form>
              <div className="inpt">
                <label>{t("Dashboard.Exchange.ty")}</label>
                <input type="text" placeholder="s47dcxd78ze4xd44"></input>
              </div>
              <div className="inpt">
                <label>{t("Dashboard.Exchange.ez")}</label>
                <input type="text" placeholder="s47dcxd78ze4xd44"></input>
              </div>
              <div className="btns">
                <button type="button" onClick={() => setEditPop(false)}>
                  {t("Dashboard.popup.btn1")}
                </button>
                <button onClick={() => setEditPop(false)}>
                  {t("Dashboard.popup.btn2")}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Delete an Exchange */}
      {deleteExchangeId && (
        <ProblemPopup
          title={t("Dashboard.Exchange.az")}
          msg={t("Dashboard.Exchange.ed")}
          btnText={t("Dashboard.popup.btn2")}
          btnText2={t("Dashboard.popup.btn1")}
          confirmPopup={() => deleteExchange(deleteExchangeId)}
          closePopup={() => setDeleteExchangeId(false)}
        />
      )}
      {/* Alert */}

    </div>
  );
}

export default Exchange;
