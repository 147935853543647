import Table from "react-bootstrap/Table";
import { useState } from "react";
import CongratulationsPopup from "./popup/congratulations";
import { useTranslation } from "react-i18next";

function Withdrawal() {
  const [showCongrats, setShowCongrats] = useState(false);
  const [t] = useTranslation();
  return (
    <div className="dashboard-withdrawal">
      <div className="withdrawal-content">
        <div className="title">
          <h1>{t("Dashboard.withdrawal.title")}</h1>
        </div>
        <div className="balance">
          {t("Dashboard.withdrawal.Available")}<span>$786</span>
        </div>
        <form>
          <div className="inpt am">
            <label>{t("Dashboard.withdrawal.Desired")}</label>
            <input type="number" placeholder="450" />
            <span>$</span>
          </div>
          <div className="inpt">
            <label>{t("Dashboard.withdrawal.IBAN")}</label>
            <input type="text" placeholder="UM4785968789825874" />
            <p>
              {t("Dashboard.withdrawal.Please")}
            </p>
          </div>
          <div className="rules">
            <p>{t("Dashboard.withdrawal.minimum")}</p>
            <p>{t("Dashboard.withdrawal.maximum")}</p>
            <p>
              {t("Dashboard.withdrawal.money")}
            </p>
          </div>
          <button
            type="button"
            className="active"
            onClick={() => setShowCongrats(true)}
          >
            {t("Dashboard.withdrawal.Withdraw")}
          </button>
        </form>
      </div>
      <div className="withdrawal-table table-bor">
        <Table>
          <thead>
            <tr>
              <th>{t("Dashboard.withdrawal.Withdraw")}</th>
              <th>{t("Dashboard.withdrawal.Request")}</th>
              <th>{t("Dashboard.withdrawal.Date")}</th>
              <th>{t("Dashboard.withdrawal.Status")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>154546445</td>
              <td>300$</td>
              <td>22/09/2023</td>
              <td>
                <p className="status approved">{t("Dashboard.withdrawal.Approved")}</p>
              </td>
            </tr>
            <tr>
              <td>154546445</td>
              <td>300$</td>
              <td>22/09/2023</td>
              <td>
                <p className="status progress">{t("Dashboard.withdrawal.Progress")}</p>
              </td>
            </tr>
            <tr>
              <td>154546445</td>
              <td>300$</td>
              <td>22/09/2023</td>
              <td>
                <p className="status declined">{t("Dashboard.withdrawal.Declined")}</p>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      {/* Congratulations Popup */}
      {showCongrats && (
        <CongratulationsPopup
          title={t("Dashboard.popup.CongratulationsTitle")}
          msg={t("Dashboard.withdrawal.reviewing")}
          btnText={t("Dashboard.popup.Got")}
          closePopup={() => setShowCongrats(false)}
        />
      )}
    </div>
  );
}

export default Withdrawal;
