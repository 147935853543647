function SecTitle({ title, text }) {
  return (
    <div className="top_sec">
      <h1>
        {title.one} <span>{title.color}</span> {title.last}
      </h1>
      <p>{text}</p>
    </div>
  );
}

export default SecTitle;
