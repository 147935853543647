import React from 'react';
import { useNavigate } from 'react-router-dom';


function BackButton() {
  const navigate = useNavigate();
  const goBack = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate('/');
    }
  }
  return (
    <button className="back" onClick={goBack}>
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.5582 8.40088L4.95898 17L13.5582 25.5992"
          stroke="#292D32"
          strokeWidth="2.125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.0417 17H5.19922"
          stroke="#292D32"
          strokeWidth="2.125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
}

export default BackButton;
