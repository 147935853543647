import executeRequest from "../utils/apiService.js";

const authService = {
  async register(email, password, username, fullName, onSuccess, onFailure) {
    return executeRequest({
      method: "POST",
      url: "/api/Auth/register",
      data: { email, password, username, name: fullName },
    })
      .then(onSuccess)
      .catch(onFailure);
  },
  async login(emailOrUsername, password, onSuccess, onFailure) {
    const data = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailOrUsername)
      ? { email: emailOrUsername, password }
      : { username: emailOrUsername, password };
    return executeRequest({
      method: "POST",
      url: "/api/Auth/login",
      data,
    })
      .then((response) => {
        const { token } = response;
        if (token) {
          localStorage.setItem("token", token);
          onSuccess(response);
        } else {
          onFailure("Something went wrong. Please try again.");
        }
      })
      .catch((e) => onFailure(e.response.data.message));
  },
  /**
   * Verifies a user's information based on a given reason, using an OTP.
   * @param {string} reason The reason for verification, which can be one of the following:
   *        - "CHANGE_PASSWORD"
   *        - "CHANGE_PHONE"
   *        - "EMAIL_VERIFICATION"
   *        - "PHONE_VERIFICATION"
   * @param {Function} onSuccess Callback function to execute on successful verification.
   * @param {Function} onFailure Callback function to execute on failed verification.
   */
  async verify(email, otp, reason = "EMAIL_VERIFICATION", onSuccess, onFailure) {
    return executeRequest({
      method: "POST",
      url: "/api/Auth/verify",
      data: { email, otp, reason: reason },
    })
      .then((response) => {
        if (response.success) {
          onSuccess(response);
        } else {
          onFailure(
            "Verification failed. Please check your input and try again."
          );
        }
      })
      .catch((error) => {
        onFailure(error.message);
      });
  },
  async sendOTP(email, reason, onSuccess, onFailure) {
    return executeRequest({
      method: "POST",
      url: "/api/Auth/otp",
      data: { email, reason },
    })
      .then((response) => {
        if (response.success) {
          onSuccess(response);
        } else {
          onFailure(
            "Verification failed. Please check your input and try again."
          );
        }
      })
      .catch((error) => {
        onFailure(error.message);
      });
  },
  async changePassword(email, otp, newPassword, onSuccess, onFailure) {
    return executeRequest({
      method: "POST",
      url: "/api/Auth/change-password",
      data: {
        email,
        reason: "CHANGE_PASSWORD",
        otp,
        password: newPassword,
      },
    })
      .then((response) => {
        if (response.success) {
          onSuccess(response);
        } else {
          onFailure(
            "Password change failed. Please check your input and try again."
          );
        }
      })
      .catch(onFailure);
  },
};
export default authService;
