
function ProblemPopup({ title, msg, btnText, btnText2, closePopup, confirmPopup }) {
  // const [openPop, setOpen] = useState(true);
  // const popArea = useRef(null);
  // function closePOP() {
  //   open = false;
  // }
  // useEffect(() => {
  //   let handle = (e) => {
  //     if (!popArea.current.contains(e.target)) {
  //       // eslint-disable-next-line react-hooks/exhaustive-deps
  //       open = false;
  //     }
  //   };
  //   document.addEventListener("mousedown", handle);
  //   return () => {
  //     document.removeEventListener("mousedown", handle);
  //   };
  // });
  return (
    <div className="problem-pop-up">
      <form
        // ref={popArea}
        className="content"
      >
        <svg
          width="139"
          height="138"
          viewBox="0 0 139 138"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0.5" width="138" height="138" rx="69" fill="#E72E4F" />
          <path
            d="M69.4974 39.4167C53.197 39.4167 39.9141 52.6996 39.9141 69C39.9141 85.3004 53.197 98.5833 69.4974 98.5833C85.7978 98.5833 99.0807 85.3004 99.0807 69C99.0807 52.6996 85.7978 39.4167 69.4974 39.4167ZM67.2786 57.1667C67.2786 55.9538 68.2845 54.9479 69.4974 54.9479C70.7103 54.9479 71.7161 55.9538 71.7161 57.1667V71.9583C71.7161 73.1713 70.7103 74.1771 69.4974 74.1771C68.2845 74.1771 67.2786 73.1713 67.2786 71.9583V57.1667ZM72.2191 81.9575C72.0711 82.3421 71.8641 82.6379 71.5978 82.9338C71.302 83.2 70.9766 83.4071 70.6216 83.555C70.2666 83.7029 69.882 83.7917 69.4974 83.7917C69.1128 83.7917 68.7282 83.7029 68.3732 83.555C68.0182 83.4071 67.6928 83.2 67.397 82.9338C67.1307 82.6379 66.9236 82.3421 66.7757 81.9575C66.6278 81.6025 66.5391 81.2179 66.5391 80.8333C66.5391 80.4488 66.6278 80.0642 66.7757 79.7092C66.9236 79.3542 67.1307 79.0288 67.397 78.7329C67.6928 78.4667 68.0182 78.2596 68.3732 78.1117C69.0832 77.8158 69.9116 77.8158 70.6216 78.1117C70.9766 78.2596 71.302 78.4667 71.5978 78.7329C71.8641 79.0288 72.0711 79.3542 72.2191 79.7092C72.367 80.0642 72.4557 80.4488 72.4557 80.8333C72.4557 81.2179 72.367 81.6025 72.2191 81.9575Z"
            fill="white"
          />
        </svg>
        <h1>{title}</h1>
        <p>{msg}</p>
        <div className="btns">
          <button type="button" onClick={confirmPopup}>
            {btnText2}
          </button>
          <button type="button" onClick={closePopup}>
            {btnText}
          </button>
        </div>
        <div onClick={closePopup} className="close">
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27.0009 26.9999L9.00098 9M27.001 9L9.00098 27.0001"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </form>
    </div>
  );
}

export default ProblemPopup;
