import { useTranslation } from "react-i18next";
// Assets
import dash from "../assets/Dashboard 1.png";

function SignUp() {
  const [t] = useTranslation();
  return (
    <div className="right_cov">
      <div className="content">
        <h3>{t("login.side")}</h3>
        <p>{t("login.sideText")}</p>
        <div className="dash_img">
          <img src={dash} alt="Dashboard" />
        </div>
      </div>
    </div>
  );
}

export default SignUp;
