import { useEffect, useState } from "react";
// import axios from "axios";
import { useTranslation } from "react-i18next";
import telegramService from "../../services/telegram";
import Error from "../Alert/error";

// Assets

const ChooseChannel = ({ StepProgress, selectedChannelId, setSelectedChannelId }) => {
  const [t] = useTranslation();
  const [isFollow, SetIsFollow] = useState(false);
  const [FollowProblem, SetFollowProblem] = useState(false);

  const [channelsList, setChannelsList] = useState([]);
  const [secondChannelsList, setSecondChannelsList] = useState([]);

  useEffect(() => {
    // console.log("useEffect selected channel id: ",selectedChannelId);
    getChannels();
    if (selectedChannelId) {
      SetIsFollow(true);
    }
  }, []);

  // Get Channels
  const getChannels = () => {
    telegramService.getTelegramChannels(
      (response) => {
        // console.log("Function getChannels",response);
        const updatedData = response.channels
          .map(item => ({
            ...item,
            selected: item.channelId === selectedChannelId ? true : false,
          }));
        setChannelsList(updatedData);
        setSecondChannelsList(updatedData);
      },
      (error) => {
        console.log(JSON.stringify(error));
      }
    );
  }
  // Check channel selected
  const checkChannelSelected = (channelId, selected) => {
    if (selected) {
      channelsList.forEach((channel, index) => {
        if (channel.channelId === channelId) {
          channel.selected = false;
          setChannelsList(prevData => {
            const updatedData = [...prevData];
            updatedData[index] = { ...updatedData[index], selected: false };
            return updatedData;
          });
          setSelectedChannelId("");
          SetIsFollow(false);
          SetFollowProblem(false);
        }
      });
    } else {
      if (checkListHasSelectedItem()) {
        SetFollowProblem(true);
      } else {
        channelsList.forEach((channel, index) => {
          if (channel.channelId === channelId) {
            channel.selected = true;
            setChannelsList(prevData => {
              const updatedData = [...prevData];
              updatedData[index] = { ...updatedData[index], selected: true };
              return updatedData;
            });
            setSelectedChannelId(channel.channelId);
            SetIsFollow(true);
          }
        });
      }
    }
  }
  const checkListHasSelectedItem = () => {
    for (const { selected } of channelsList) {
      if (selected) {
        return true;
      }
    }
    return false;
  }
  // Handle Confirme
  const handleConfirm = () => {
    // console.log("selected channel id: ",selectedChannelId);
    StepProgress(3);
  };
  const ChatItem = ({ item, isFollow }) => {
    return (
      <div className="group">
        <div className="info">
          <div className="img">
            <img src={item.photo} alt="" />
          </div>
          <div className="inf">
            <p>
              {item.name}
            </p>
          </div>
        </div>
        <button
          data-follow={`${isFollow}`}
          className={isFollow ? "follow" : " "}
          onClick={e => checkChannelSelected(item.channelId, item.selected)}
        >
          {isFollow
            ? t("Dashboard.telegramBot.Unfollow")
            : t("Dashboard.telegramBot.Follow")}
        </button>
      </div>
    );
  };
  // Handle Search
  const handleSearch = (searchTerm) => {
    if (searchTerm.length === 0) {
      setChannelsList(secondChannelsList);
    } else {
      setChannelsList(channelsList.filter(channel =>
        channel.selected === true || channel.name.toLowerCase().includes(searchTerm.toLowerCase())
      ));
    }
    // console.log(searchTerm.length);
    // console.log("handleSearch",secondChannelsList);
  }

  return (
    <div className="ChooseChannel step2">
      <div className="pop-up-title">
        <h1>{t("Dashboard.telegramBot.stp2Title")}</h1>
        <p>{t("Dashboard.telegramBot.stp2Text")}</p>
      </div>
      <div className="group-list">
        <h3>{t("Dashboard.telegramBot.MyGroups")}</h3>
        <div className="search_box">
          <input type="text" placeholder="Search..." onChange={(e) => handleSearch(e.target.value)} />
          {/* <button>Search</button> */}
        </div>
        {FollowProblem && (
          <Error
            msg={t("Dashboard.telegramBot.msg")}
            close={() => SetFollowProblem(false)}
          />
        )}
        <div className="groups">
          {channelsList &&
            channelsList.map((chat) => (
              <ChatItem
                key={chat.channelId}
                item={chat}
                isFollow={chat.selected}
              />
            ))}
        </div>
      </div>
      <button
        className={`confirm ${isFollow ? "active" : ""}`}
        onClick={handleConfirm}
      >
        {t("Dashboard.telegramBot.stp2Btn")}
      </button>
    </div>
  );
};

export default ChooseChannel;
