// import RoutesApp from "./router.js";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./framework/components/ProtectedRoute";

// Pages
import Home from "./home";
import NewPassword from "./new-password";
import ResetPassword from "./reset-password";
import SignIn from "./signin";
import SignUp from "./signup";
//------- Dashboard -------
import Analytics from "./Dashboard/Analytics";
import Exchange from "./Dashboard/Exchange";
import DashboardHome from "./Dashboard/Home";
import Marketplace from "./Dashboard/Marketplace";
import Referral from "./Dashboard/Referral";
import Subscription from "./Dashboard/Subscription";
import TelegramBot from "./Dashboard/Telegram-Bot";
import TradingViewBot from "./Dashboard/Trading-View-Bot";
import Withdrawal from "./Dashboard/Withdrawal";
import Dashboard from "./dashboard";
// Subscription
import SubscriptionBilling from "./Dashboard/Subscription/subscription-billing";
import SubscriptionPricing from "./Dashboard/Subscription/subscription-pricing";
import SubscriptionPromoCodes from "./Dashboard/Subscription/subscription-promo-codes";
// Settings
import Settings from "./Dashboard/Settings";
import Profile from "./Dashboard/Settings/profile";
import Security from "./Dashboard/Settings/security";
// Analytics
import History from "./Dashboard/Analytics/history";
import Statics from "./Dashboard/Analytics/statics";
import OtpCheckValue from "./otp-check";
import { ToastAlert } from "./Dashboard/Alert/ToastAlert";

function App() {
  const isAuthenticated = localStorage.getItem("token");
  // Language
  const [_, i18n] = useTranslation();
  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      i18n.changeLanguage("en");
      document.body.classList.remove("ar");
    } else if (localStorage.getItem("lang") === "ar") {
      i18n.changeLanguage("ar");
      document.body.classList.add("ar");
    } else {
      i18n.changeLanguage("en");
      document.body.classList.remove("ar");
    }
  }, [i18n]);

  return (
    <div className="app">
      <Routes>
        {!isAuthenticated && <Route path="/" element={<Home />} />}
        <Route path="sign-up" element={<SignUp />} />
        <Route path="sign-up/otp-check" element={<OtpCheckValue reason={"EMAIL_VERIFICATION"} />} />
        <Route path="reset-password/otp-check" element={<OtpCheckValue reason="CHANGE_PASSWORD" />} />
        <Route path="sign-in" element={<SignIn />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="reset-password/new-password" element={<NewPassword />} />
        {/* Dashboard */}
        {isAuthenticated && (
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          >
            <Route index element={<DashboardHome />} />
            <Route
              path="analytics"
              element={
                <ProtectedRoute>
                  <Analytics />
                </ProtectedRoute>
              }
            >
              <Route index element={<Statics />} />
              <Route path="statics" element={<Statics />} />
              <Route path="history" element={<History />} />
              <Route path="*" element={<Statics />} />
            </Route>
            <Route
              path="marketplace"
              element={
                <ProtectedRoute>
                  <Marketplace />
                </ProtectedRoute>
              }
            />
            <Route
              path="subscription"
              element={
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              }
            >
              <Route index element={<SubscriptionPricing />} />
              <Route path="pricing" element={<SubscriptionPricing />} />
              <Route path="billing" element={<SubscriptionBilling />} />
              <Route path="promo-codes" element={<SubscriptionPromoCodes />} />
              <Route path="*" element={<SubscriptionPricing />} />
            </Route>
            <Route
              path="settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            >
              <Route index element={<Profile />} />
              <Route path="profile" element={<Profile />} />
              <Route path="security" element={<Security />} />
              <Route path="*" element={<Profile />} />
            </Route>
            <Route
              path="exchange"
              element={
                <ProtectedRoute>
                  <Exchange />
                </ProtectedRoute>
              }
            />
            <Route
              path="trading-view-bot"
              element={
                <ProtectedRoute>
                  <TradingViewBot />
                </ProtectedRoute>
              }
            />
            <Route
              path="telegram-bot"
              element={
                <ProtectedRoute>
                  <TelegramBot />
                </ProtectedRoute>
              }
            />
            <Route
              path="referral"
              element={
                <ProtectedRoute>
                  <Referral />
                </ProtectedRoute>
              }
            />
            <Route
              path="withdrawal"
              element={
                <ProtectedRoute>
                  <Withdrawal />
                </ProtectedRoute>
              }
            />
          </Route>
        )}
      </Routes>
      {/* <Dashboard /> */}
      <ToastAlert />
    </div>
  );
}

export default App;
