import { useTranslation } from "react-i18next";

function Questions() {
  const [t] = useTranslation();
  function openK(cl) {
    let elm = document.querySelector(`.questions_list ${cl}`);
    if (elm.classList.contains("QsOpen")) {
      elm.classList.remove("QsOpen");
    } else {
      elm.classList.add("QsOpen");
    }
  }
  return (
    <div className="questions_list">
      <div className="item one">
        <div className="qs" onClick={(e) => openK(".one")}>
          <div className="icon">
            <svg
              width="13"
              height="22"
              viewBox="0 0 13 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.89525 15.4707V15.2818C4.9085 14.0493 5.03109 13.0685 5.26302 12.3396C5.50158 11.6107 5.83953 11.021 6.27688 10.5703C6.71423 10.1197 7.24104 9.7089 7.85731 9.33782C8.2549 9.08601 8.61273 8.80438 8.9308 8.49293C9.24888 8.18149 9.50068 7.82366 9.68623 7.41944C9.87177 7.01522 9.96454 6.56793 9.96454 6.07757C9.96454 5.48781 9.82538 4.97756 9.54707 4.54684C9.26876 4.11612 8.89767 3.78479 8.43381 3.55286C7.97658 3.31431 7.46634 3.19503 6.90309 3.19503C6.39285 3.19503 5.9058 3.30105 5.44194 3.5131C4.97808 3.72515 4.59374 4.05648 4.28892 4.50708C3.9841 4.95106 3.8085 5.52425 3.76212 6.22666H0.740421C0.786807 5.03389 1.08831 4.02666 1.64494 3.20497C2.20157 2.37665 2.93711 1.75045 3.85157 1.32635C4.77266 0.902252 5.78983 0.690204 6.90309 0.690204C8.12237 0.690204 9.18924 0.918819 10.1037 1.37605C11.0182 1.82665 11.7272 2.45949 12.2308 3.27455C12.7411 4.08298 12.9962 5.02726 12.9962 6.10739C12.9962 6.84956 12.8802 7.51884 12.6483 8.11522C12.4164 8.70498 12.085 9.23179 11.6543 9.69565C11.2302 10.1595 10.72 10.5703 10.1236 10.9282C9.56032 11.2794 9.10309 11.6438 8.75189 12.0216C8.40731 12.3993 8.1555 12.8466 7.99646 13.3634C7.83743 13.8803 7.75128 14.5198 7.73803 15.2818V15.4707H4.89525ZM6.39616 21.5141C5.85278 21.5141 5.38561 21.3219 4.99465 20.9375C4.60368 20.5466 4.4082 20.0761 4.4082 19.5261C4.4082 18.9827 4.60368 18.5189 4.99465 18.1345C5.38561 17.7436 5.85278 17.5481 6.39616 17.5481C6.93291 17.5481 7.39676 17.7436 7.78773 18.1345C8.18532 18.5189 8.38412 18.9827 8.38412 19.5261C8.38412 19.8906 8.29134 20.2252 8.1058 20.53C7.92689 20.8282 7.68833 21.0668 7.39014 21.2457C7.09194 21.4246 6.76062 21.5141 6.39616 21.5141Z"
                fill="white"
              />
            </svg>
          </div>
          <p>{t("Home.faq.q1.title")}</p>
          <div className="arrow">
            <svg
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.63775 0.896789C9.01196 0.896789 9.38618 1.03466 9.68161 1.33009L16.6341 8.2826C17.2053 8.85377 17.2053 9.79916 16.6341 10.3703C16.063 10.9415 15.1176 10.9415 14.5464 10.3703L8.63775 4.46168L2.7291 10.3703C2.15793 10.9415 1.21254 10.9415 0.641373 10.3703C0.0702029 9.79916 0.0702029 8.85377 0.641373 8.2826L7.59389 1.33009C7.88932 1.03466 8.26353 0.896789 8.63775 0.896789Z"
                fill="#5CC159"
              />
            </svg>
          </div>
        </div>
        <div className="answr">{t("Home.faq.q1.text")}</div>
      </div>
      <div className="item two">
        <div className="qs" onClick={(e) => openK(".two")}>
          <div className="icon">
            <svg
              width="13"
              height="22"
              viewBox="0 0 13 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.89525 15.4707V15.2818C4.9085 14.0493 5.03109 13.0685 5.26302 12.3396C5.50158 11.6107 5.83953 11.021 6.27688 10.5703C6.71423 10.1197 7.24104 9.7089 7.85731 9.33782C8.2549 9.08601 8.61273 8.80438 8.9308 8.49293C9.24888 8.18149 9.50068 7.82366 9.68623 7.41944C9.87177 7.01522 9.96454 6.56793 9.96454 6.07757C9.96454 5.48781 9.82538 4.97756 9.54707 4.54684C9.26876 4.11612 8.89767 3.78479 8.43381 3.55286C7.97658 3.31431 7.46634 3.19503 6.90309 3.19503C6.39285 3.19503 5.9058 3.30105 5.44194 3.5131C4.97808 3.72515 4.59374 4.05648 4.28892 4.50708C3.9841 4.95106 3.8085 5.52425 3.76212 6.22666H0.740421C0.786807 5.03389 1.08831 4.02666 1.64494 3.20497C2.20157 2.37665 2.93711 1.75045 3.85157 1.32635C4.77266 0.902252 5.78983 0.690204 6.90309 0.690204C8.12237 0.690204 9.18924 0.918819 10.1037 1.37605C11.0182 1.82665 11.7272 2.45949 12.2308 3.27455C12.7411 4.08298 12.9962 5.02726 12.9962 6.10739C12.9962 6.84956 12.8802 7.51884 12.6483 8.11522C12.4164 8.70498 12.085 9.23179 11.6543 9.69565C11.2302 10.1595 10.72 10.5703 10.1236 10.9282C9.56032 11.2794 9.10309 11.6438 8.75189 12.0216C8.40731 12.3993 8.1555 12.8466 7.99646 13.3634C7.83743 13.8803 7.75128 14.5198 7.73803 15.2818V15.4707H4.89525ZM6.39616 21.5141C5.85278 21.5141 5.38561 21.3219 4.99465 20.9375C4.60368 20.5466 4.4082 20.0761 4.4082 19.5261C4.4082 18.9827 4.60368 18.5189 4.99465 18.1345C5.38561 17.7436 5.85278 17.5481 6.39616 17.5481C6.93291 17.5481 7.39676 17.7436 7.78773 18.1345C8.18532 18.5189 8.38412 18.9827 8.38412 19.5261C8.38412 19.8906 8.29134 20.2252 8.1058 20.53C7.92689 20.8282 7.68833 21.0668 7.39014 21.2457C7.09194 21.4246 6.76062 21.5141 6.39616 21.5141Z"
                fill="white"
              />
            </svg>
          </div>
          <p>{t("Home.faq.q2.title")}</p>
          <div className="arrow">
            <svg
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.63775 0.896789C9.01196 0.896789 9.38618 1.03466 9.68161 1.33009L16.6341 8.2826C17.2053 8.85377 17.2053 9.79916 16.6341 10.3703C16.063 10.9415 15.1176 10.9415 14.5464 10.3703L8.63775 4.46168L2.7291 10.3703C2.15793 10.9415 1.21254 10.9415 0.641373 10.3703C0.0702029 9.79916 0.0702029 8.85377 0.641373 8.2826L7.59389 1.33009C7.88932 1.03466 8.26353 0.896789 8.63775 0.896789Z"
                fill="#5CC159"
              />
            </svg>
          </div>
        </div>
        <div className="answr">{t("Home.faq.q2.text")}</div>
      </div>
      <div className="item three">
        <div className="qs" onClick={(e) => openK(".three")}>
          <div className="icon">
            <svg
              width="13"
              height="22"
              viewBox="0 0 13 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.89525 15.4707V15.2818C4.9085 14.0493 5.03109 13.0685 5.26302 12.3396C5.50158 11.6107 5.83953 11.021 6.27688 10.5703C6.71423 10.1197 7.24104 9.7089 7.85731 9.33782C8.2549 9.08601 8.61273 8.80438 8.9308 8.49293C9.24888 8.18149 9.50068 7.82366 9.68623 7.41944C9.87177 7.01522 9.96454 6.56793 9.96454 6.07757C9.96454 5.48781 9.82538 4.97756 9.54707 4.54684C9.26876 4.11612 8.89767 3.78479 8.43381 3.55286C7.97658 3.31431 7.46634 3.19503 6.90309 3.19503C6.39285 3.19503 5.9058 3.30105 5.44194 3.5131C4.97808 3.72515 4.59374 4.05648 4.28892 4.50708C3.9841 4.95106 3.8085 5.52425 3.76212 6.22666H0.740421C0.786807 5.03389 1.08831 4.02666 1.64494 3.20497C2.20157 2.37665 2.93711 1.75045 3.85157 1.32635C4.77266 0.902252 5.78983 0.690204 6.90309 0.690204C8.12237 0.690204 9.18924 0.918819 10.1037 1.37605C11.0182 1.82665 11.7272 2.45949 12.2308 3.27455C12.7411 4.08298 12.9962 5.02726 12.9962 6.10739C12.9962 6.84956 12.8802 7.51884 12.6483 8.11522C12.4164 8.70498 12.085 9.23179 11.6543 9.69565C11.2302 10.1595 10.72 10.5703 10.1236 10.9282C9.56032 11.2794 9.10309 11.6438 8.75189 12.0216C8.40731 12.3993 8.1555 12.8466 7.99646 13.3634C7.83743 13.8803 7.75128 14.5198 7.73803 15.2818V15.4707H4.89525ZM6.39616 21.5141C5.85278 21.5141 5.38561 21.3219 4.99465 20.9375C4.60368 20.5466 4.4082 20.0761 4.4082 19.5261C4.4082 18.9827 4.60368 18.5189 4.99465 18.1345C5.38561 17.7436 5.85278 17.5481 6.39616 17.5481C6.93291 17.5481 7.39676 17.7436 7.78773 18.1345C8.18532 18.5189 8.38412 18.9827 8.38412 19.5261C8.38412 19.8906 8.29134 20.2252 8.1058 20.53C7.92689 20.8282 7.68833 21.0668 7.39014 21.2457C7.09194 21.4246 6.76062 21.5141 6.39616 21.5141Z"
                fill="white"
              />
            </svg>
          </div>
          <p>{t("Home.faq.q3.title")}</p>
          <div className="arrow">
            <svg
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.63775 0.896789C9.01196 0.896789 9.38618 1.03466 9.68161 1.33009L16.6341 8.2826C17.2053 8.85377 17.2053 9.79916 16.6341 10.3703C16.063 10.9415 15.1176 10.9415 14.5464 10.3703L8.63775 4.46168L2.7291 10.3703C2.15793 10.9415 1.21254 10.9415 0.641373 10.3703C0.0702029 9.79916 0.0702029 8.85377 0.641373 8.2826L7.59389 1.33009C7.88932 1.03466 8.26353 0.896789 8.63775 0.896789Z"
                fill="#5CC159"
              />
            </svg>
          </div>
        </div>
        <div className="answr">{t("Home.faq.q3.text")}</div>
      </div>
      <div className="item four">
        <div className="qs" onClick={(e) => openK(".four")}>
          <div className="icon">
            <svg
              width="13"
              height="22"
              viewBox="0 0 13 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.89525 15.4707V15.2818C4.9085 14.0493 5.03109 13.0685 5.26302 12.3396C5.50158 11.6107 5.83953 11.021 6.27688 10.5703C6.71423 10.1197 7.24104 9.7089 7.85731 9.33782C8.2549 9.08601 8.61273 8.80438 8.9308 8.49293C9.24888 8.18149 9.50068 7.82366 9.68623 7.41944C9.87177 7.01522 9.96454 6.56793 9.96454 6.07757C9.96454 5.48781 9.82538 4.97756 9.54707 4.54684C9.26876 4.11612 8.89767 3.78479 8.43381 3.55286C7.97658 3.31431 7.46634 3.19503 6.90309 3.19503C6.39285 3.19503 5.9058 3.30105 5.44194 3.5131C4.97808 3.72515 4.59374 4.05648 4.28892 4.50708C3.9841 4.95106 3.8085 5.52425 3.76212 6.22666H0.740421C0.786807 5.03389 1.08831 4.02666 1.64494 3.20497C2.20157 2.37665 2.93711 1.75045 3.85157 1.32635C4.77266 0.902252 5.78983 0.690204 6.90309 0.690204C8.12237 0.690204 9.18924 0.918819 10.1037 1.37605C11.0182 1.82665 11.7272 2.45949 12.2308 3.27455C12.7411 4.08298 12.9962 5.02726 12.9962 6.10739C12.9962 6.84956 12.8802 7.51884 12.6483 8.11522C12.4164 8.70498 12.085 9.23179 11.6543 9.69565C11.2302 10.1595 10.72 10.5703 10.1236 10.9282C9.56032 11.2794 9.10309 11.6438 8.75189 12.0216C8.40731 12.3993 8.1555 12.8466 7.99646 13.3634C7.83743 13.8803 7.75128 14.5198 7.73803 15.2818V15.4707H4.89525ZM6.39616 21.5141C5.85278 21.5141 5.38561 21.3219 4.99465 20.9375C4.60368 20.5466 4.4082 20.0761 4.4082 19.5261C4.4082 18.9827 4.60368 18.5189 4.99465 18.1345C5.38561 17.7436 5.85278 17.5481 6.39616 17.5481C6.93291 17.5481 7.39676 17.7436 7.78773 18.1345C8.18532 18.5189 8.38412 18.9827 8.38412 19.5261C8.38412 19.8906 8.29134 20.2252 8.1058 20.53C7.92689 20.8282 7.68833 21.0668 7.39014 21.2457C7.09194 21.4246 6.76062 21.5141 6.39616 21.5141Z"
                fill="white"
              />
            </svg>
          </div>
          <p>{t("Home.faq.q4.title")}</p>
          <div className="arrow">
            <svg
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.63775 0.896789C9.01196 0.896789 9.38618 1.03466 9.68161 1.33009L16.6341 8.2826C17.2053 8.85377 17.2053 9.79916 16.6341 10.3703C16.063 10.9415 15.1176 10.9415 14.5464 10.3703L8.63775 4.46168L2.7291 10.3703C2.15793 10.9415 1.21254 10.9415 0.641373 10.3703C0.0702029 9.79916 0.0702029 8.85377 0.641373 8.2826L7.59389 1.33009C7.88932 1.03466 8.26353 0.896789 8.63775 0.896789Z"
                fill="#5CC159"
              />
            </svg>
          </div>
        </div>
        <div className="answr">{t("Home.faq.q4.text")}</div>
      </div>
    </div>
  );
}

export default Questions;
