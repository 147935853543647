import { useState } from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import Done from "../Alert/done";

function SubscriptionPromoCodes() {
  const [CloseAlert, SetCloseAlert] = useState(true);
  const [t] = useTranslation();
  return (
    <div className="subscription-promo-codes">
      <div className="code_btn">
        <div className="inpt">
          <input type="text" placeholder={t("Dashboard.Subscription.PromoCodes.inpt")} />
          <button>{t("Dashboard.Subscription.PromoCodes.Apply")}</button>
        </div>
        {CloseAlert && (
          <Done
            msg={t("Dashboard.Subscription.PromoCodes.note")}
            close={() => SetCloseAlert(false)}
          />
        )}
      </div>
      <div className="codes_table table-bor">
        <Table>
          <thead>
            <tr>
              <th>{t("Dashboard.Subscription.PromoCodes.Promo")}</th>
              <th>{t("Dashboard.Subscription.PromoCodes.Claim")}</th>
              <th>{t("Dashboard.Subscription.PromoCodes.End")}</th>
              <th>{t("Dashboard.Subscription.PromoCodes.Expiration")}</th>
              <th>{t("Dashboard.Subscription.PromoCodes.Percentage")}</th>
              <th>{t("Dashboard.Subscription.PromoCodes.Status")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>H58D78R8</td>
              <td>22/09/2023</td>
              <td>22/10/2023</td>
              <td>20%</td>
              <td>Paypal</td>
              <td>
                <p className="Status active">{t("Dashboard.Subscription.PromoCodes.Active")}</p>
              </td>
            </tr>
            <tr>
              <td>H58D78R8</td>
              <td>22/09/2023</td>
              <td>22/10/2023</td>
              <td>20%</td>
              <td>Paypal</td>
              <td>
                <p className="Status used">{t("Dashboard.Subscription.PromoCodes.Used")}</p>
              </td>
            </tr>
            <tr>
              <td>H58D78R8</td>
              <td>22/09/2023</td>
              <td>22/10/2023</td>
              <td>20%</td>
              <td>Paypal</td>
              <td>
                <p className="Status expired">{t("Dashboard.Subscription.PromoCodes.Expired")}</p>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="codes_table table-bor">
        <Table>
          <thead>
            <tr>
              <th>{t("Dashboard.Subscription.PromoCodes.Promo")}</th>
              <th>{t("Dashboard.Subscription.PromoCodes.Claim")}</th>
              <th>{t("Dashboard.Subscription.PromoCodes.End")}</th>
              <th>{t("Dashboard.Subscription.PromoCodes.Expiration")}</th>
              <th>{t("Dashboard.Subscription.PromoCodes.Percentage")}</th>
              <th>{t("Dashboard.Subscription.PromoCodes.Status")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="6" className="empty-table">
                <p>
                  {t("Dashboard.Subscription.PromoCodes.msg")}
                </p>
                <div className="social">
                  <a
                    href="https://twitter.com/insta_signal"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <svg
                      width="26"
                      height="27"
                      viewBox="0 0 26 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_2_21659)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.43289 23.7998C18.0823 23.7998 23.3603 15.9283 23.3603 9.10299C23.3603 8.87891 23.3603 8.65653 23.345 8.43498C24.3717 7.70486 25.2577 6.79833 25.9622 5.76107C25.0058 6.179 23.9894 6.45308 22.9499 6.57392C24.0443 5.92815 24.8639 4.9136 25.2564 3.71647C24.2259 4.31818 23.0995 4.74285 21.9245 4.9707C19.9375 2.89113 16.6145 2.79038 14.501 4.74658C13.1393 6.00791 12.5601 7.88853 12.9834 9.68234C8.76532 9.47338 4.83497 7.51218 2.17041 4.28584C0.778035 6.64611 1.4902 9.66444 3.79548 11.1801C2.96057 11.1561 2.14356 10.9348 1.41349 10.5345V10.6001C1.41477 13.0585 3.17537 15.1754 5.62257 15.6626C4.85031 15.8703 4.03969 15.9004 3.25464 15.7506C3.94124 17.8553 5.91153 19.2969 8.15544 19.3385C6.29766 20.7761 4.00261 21.5567 1.6398 21.5542C1.22298 21.5529 0.806163 21.5289 0.390625 21.4799C2.79052 22.9955 5.58166 23.7998 8.43289 23.796"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2_21659">
                          <rect
                            width="25.5716"
                            height="25.5716"
                            fill="white"
                            transform="translate(0.398438 0.785645)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCq-Sn-BFK9pmwxoHrD-Xuhw"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <svg
                      width="27"
                      height="27"
                      viewBox="0 0 27 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_2_21666)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10.7607 16.8777V9.70255C13.3079 10.9011 15.2807 12.059 17.6139 13.3073C15.6895 14.3745 13.3079 15.572 10.7607 16.8777ZM24.9562 6.13402C24.5169 5.55513 23.768 5.10451 22.9707 4.95533C20.6273 4.51032 6.00767 4.50905 3.66552 4.95533C3.02618 5.07518 2.45686 5.36488 1.9678 5.815C-0.0928782 7.72762 0.552841 17.9844 1.04954 19.6459C1.25841 20.365 1.52843 20.8837 1.86848 21.2241C2.3066 21.6742 2.90645 21.9841 3.59547 22.1231C5.52497 22.5222 15.4654 22.7454 22.9299 22.1831C23.6177 22.0632 24.2265 21.7433 24.7066 21.2741C26.6119 19.3691 26.482 8.53627 24.9562 6.13402Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2_21666">
                          <rect
                            width="25.5716"
                            height="25.5716"
                            fill="white"
                            transform="translate(0.539062 0.785645)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <a
                    href="https://www.instagram.com/insta_signal_automation/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5547 2.86914H8.82552C5.03385 2.86914 2.77344 5.12956 2.77344 8.92122V17.64C2.77344 21.4421 5.03385 23.7025 8.82552 23.7025H17.5443C21.3359 23.7025 23.5964 21.4421 23.5964 17.6504V8.92122C23.6068 5.12956 21.3464 2.86914 17.5547 2.86914ZM13.1901 17.3275C10.9609 17.3275 9.14844 15.515 9.14844 13.2858C9.14844 11.0566 10.9609 9.24414 13.1901 9.24414C15.4193 9.24414 17.2318 11.0566 17.2318 13.2858C17.2318 15.515 15.4193 17.3275 13.1901 17.3275ZM19.3568 7.95247C19.3047 8.07747 19.2318 8.19206 19.138 8.29622C19.0339 8.38997 18.9193 8.46289 18.7943 8.51497C18.6693 8.56706 18.5339 8.59831 18.3984 8.59831C18.1172 8.59831 17.8568 8.49414 17.6589 8.29622C17.5651 8.19206 17.4922 8.07747 17.4401 7.95247C17.388 7.82747 17.3568 7.69206 17.3568 7.55664C17.3568 7.42122 17.388 7.28581 17.4401 7.16081C17.4922 7.02539 17.5651 6.92122 17.6589 6.81706C17.8984 6.57747 18.263 6.46289 18.5964 6.53581C18.6693 6.54622 18.7318 6.56706 18.7943 6.59831C18.8568 6.61914 18.9193 6.65039 18.9818 6.69206C19.0339 6.72331 19.0859 6.77539 19.138 6.81706C19.2318 6.92122 19.3047 7.02539 19.3568 7.16081C19.4089 7.28581 19.4401 7.42122 19.4401 7.55664C19.4401 7.69206 19.4089 7.82747 19.3568 7.95247Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://t.me/+PP3vWOlN23w1OWRk"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.2115 7.98919C17.2163 7.98919 17.2227 7.98919 17.2291 7.98919C17.3961 7.98919 17.5511 8.04113 17.6782 8.13063L17.6758 8.12903C17.7685 8.20973 17.8308 8.3232 17.846 8.45105V8.45345C17.862 8.55093 17.8707 8.6628 17.8707 8.77707C17.8707 8.82901 17.8692 8.88015 17.8652 8.93129V8.9241C17.6854 10.8171 16.9055 15.4077 16.5091 17.5268C16.3413 18.4242 16.0113 18.7246 15.6917 18.7534C14.9973 18.8181 14.4699 18.2947 13.7971 17.8536C12.744 17.1624 12.1487 16.7325 11.1267 16.0589C9.94485 15.2814 10.7111 14.8523 11.384 14.154C11.5606 13.9702 14.6218 11.1854 14.6817 10.9329C14.6849 10.9178 14.6865 10.901 14.6865 10.8834C14.6865 10.8211 14.6633 10.7643 14.6258 10.7212C14.5842 10.694 14.5323 10.6788 14.4779 10.6788C14.442 10.6788 14.4076 10.686 14.3756 10.698L14.3772 10.6972C14.2718 10.7212 12.5895 11.833 9.33037 14.0325C8.97479 14.313 8.52571 14.4904 8.03669 14.5111H8.03189C7.33911 14.4272 6.71024 14.273 6.11334 14.0533L6.17247 14.0725C5.42295 13.8279 4.82765 13.6993 4.87879 13.2854C4.90596 13.0702 5.20268 12.8499 5.76895 12.6246C9.25712 11.1047 11.5832 10.103 12.7472 9.61928C14.0313 8.93768 15.5199 8.37674 17.0853 8.01316L17.2107 7.98839L17.2115 7.98919ZM12.2637 0.785645C5.62991 0.801626 0.257812 6.18331 0.257812 12.8195C0.257812 19.4653 5.64509 24.8534 12.2917 24.8534C18.9383 24.8534 24.3256 19.4661 24.3256 12.8195C24.3256 6.18331 18.9535 0.801626 12.3213 0.785645H12.3197C12.301 0.785645 12.2824 0.785645 12.2637 0.785645Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://www.facebook.com/people/Insta-Signal/100093620508927/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <svg
                      width="27"
                      height="27"
                      viewBox="0 0 27 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24.3396 18.0348C24.3396 21.9129 22.0276 24.2248 18.1495 24.2248H16.8817C16.2957 24.2248 15.8162 23.7454 15.8162 23.1594V17.012C15.8162 16.7243 16.0506 16.4793 16.3383 16.4793L18.2134 16.4473C18.3626 16.4367 18.4905 16.3301 18.5224 16.181L18.8953 14.146C18.9273 13.9542 18.7781 13.7731 18.5757 13.7731L16.3063 13.8051C16.008 13.8051 15.7736 13.5707 15.763 13.283L15.7204 10.6727C15.7204 10.5023 15.8589 10.3531 16.04 10.3531L18.597 10.3105C18.7781 10.3105 18.9166 10.172 18.9166 9.99089L18.874 7.43386C18.874 7.25274 18.7355 7.11425 18.5544 7.11425L15.6777 7.15687C13.9091 7.18884 12.5028 8.63779 12.5348 10.4064L12.588 13.3363C12.5987 13.6346 12.3643 13.869 12.066 13.8797L10.7875 13.9009C10.6064 13.9009 10.4679 14.0394 10.4679 14.2206L10.4998 16.2449C10.4998 16.426 10.6383 16.5645 10.8194 16.5645L12.098 16.5432C12.3963 16.5432 12.6306 16.7776 12.6413 17.0652L12.7372 23.1381C12.7478 23.7348 12.2684 24.2248 11.6718 24.2248H9.22132C5.3432 24.2248 3.03125 21.9129 3.03125 18.0241V9.10657C3.03125 5.22846 5.3432 2.9165 9.22132 2.9165H18.1495C22.0276 2.9165 24.3396 5.22846 24.3396 9.10657V18.0348Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://www.tiktok.com/@instasignal"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <svg
                      width="26"
                      height="27"
                      viewBox="0 0 26 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.4148 2.9165H3.23729C2.95473 2.9165 2.68373 3.02875 2.48393 3.22856C2.28412 3.42836 2.17188 3.69935 2.17188 3.98192V23.1594C2.17188 23.442 2.28412 23.713 2.48393 23.9128C2.68373 24.1126 2.95473 24.2248 3.23729 24.2248H22.4148C22.6974 24.2248 22.9684 24.1126 23.1682 23.9128C23.368 23.713 23.4802 23.442 23.4802 23.1594V3.98192C23.4802 3.69935 23.368 3.42836 23.1682 3.22856C22.9684 3.02875 22.6974 2.9165 22.4148 2.9165ZM18.9192 12.2123C18.803 12.224 18.6858 12.2293 18.5697 12.2304C17.9376 12.2312 17.3152 12.0745 16.7589 11.7745C16.2025 11.4744 15.7296 11.0405 15.383 10.5119V16.3642C15.3833 17.22 15.1297 18.0567 14.6543 18.7684C14.179 19.48 13.5033 20.0348 12.7126 20.3624C11.922 20.69 11.0519 20.7757 10.2126 20.6088C9.37317 20.4419 8.60213 20.0298 7.99698 19.4247C7.39182 18.8195 6.97972 18.0485 6.81281 17.2091C6.6459 16.3697 6.73167 15.4997 7.05928 14.709C7.38689 13.9184 7.94161 13.2427 8.65329 12.7673C9.36497 12.292 10.2016 12.0384 11.0575 12.0386C11.148 12.0386 11.2354 12.0471 11.3249 12.0525V14.1833C11.0268 14.1472 10.7245 14.1724 10.4365 14.2574C10.1485 14.3425 9.88098 14.4855 9.6503 14.6777C9.41963 14.8699 9.23069 15.1072 9.09512 15.3752C8.95954 15.6431 8.88018 15.9359 8.86192 16.2356C8.84365 16.5353 8.88687 16.8356 8.98892 17.1179C9.09096 17.4003 9.24967 17.6589 9.4553 17.8777C9.66093 18.0965 9.90913 18.2709 10.1847 18.3903C10.4602 18.5096 10.7572 18.5714 11.0575 18.5717C11.65 18.5825 12.2231 18.3602 12.6535 17.9527C13.0838 17.5453 13.3371 16.9852 13.3588 16.393L13.3801 6.45156H15.4193C15.5124 7.3374 15.9139 8.16232 16.5537 8.78205C17.1935 9.40178 18.0308 9.77686 18.9192 9.84171V12.2123Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default SubscriptionPromoCodes;
