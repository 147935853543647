import { AreaChart, Area, ResponsiveContainer } from "recharts";
import { Link } from "react-router-dom";
import { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CongratulationsPopup from "./popup/congratulations";
import ProblemPopup from "./popup/problem";
import { useTranslation } from "react-i18next";

// Assets
import trader from "../assets/Frame 1707480129-1.png";

const data = [
  {
    name: "January",
    uv: 0,
  },
  {
    name: "February",
    uv: 300,
  },
  {
    name: "March",
    uv: 200,
  },
  {
    name: "April",
    uv: 1000,
  },
  {
    name: "May",
    uv: 500,
  },
  {
    name: "June",
    uv: 1100,
  },
];

function Marketplace() {
  const [exchange, setExchange] = useState("");
  const [PercentagePortfolio, setPercentagePortfolio] = useState("");
  const [AmountPortfolio, setAmountPortfolio] = useState("");
  const [CustomSettings, setCustomSettings] = useState(false);
  const [choosePosition, setChoosePosition] = useState("PositionSize");
  const [Leverage, setLeverage] = useState("");
  const [PaymentPop, setPaymentPop] = useState(false);
  const [dateFilter, setDateFilter] = useState("");
  const [showCongrats, setShowCongrats] = useState(false);
  const [confirmUnfollow, setConfirmUnfollow] = useState("");
  const [t] = useTranslation();

  function followBtn(e) {
    if (e.target.innerHTML === "Unfollow") {
      e.target.innerHTML = "Follow";
      e.target.classList.add("follow");
    }
  }
  return (
    <div className="dashboard-marketplace">
      <div className="filter">
        <Select
          value={dateFilter}
          onChange={(e) => setDateFilter(e.target.value)}
          displayEmpty
          className="select_filter"
        >
          <MenuItem disabled hidden value="">
            {t("Dashboard.select1.title")}
          </MenuItem>
          <MenuItem value={t("Dashboard.select1.a")}>
            {t("Dashboard.select1.a")}
          </MenuItem>
          <MenuItem value={t("Dashboard.select1.b")}>
            {t("Dashboard.select1.b")}
          </MenuItem>
          <MenuItem value={t("Dashboard.select1.c")}>
            {t("Dashboard.select1.c")}
          </MenuItem>
          <MenuItem value={t("Dashboard.select1.d")}>
            {t("Dashboard.select1.d")}
          </MenuItem>
          <MenuItem value={t("Dashboard.select1.e")}>
            {t("Dashboard.select1.e")}
          </MenuItem>
        </Select>
      </div>
      <div className="row top_traders">
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="item">
            <div className="picture">
              <img src={trader} alt="Alexander Morgan" />
            </div>
            <div className="info">
              <h1>Bryan hook</h1>
              <div className="nums">
                <div className="num">
                  <div className="icon">
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 9.5C11.0711 9.5 12.75 7.82107 12.75 5.75C12.75 3.67893 11.0711 2 9 2C6.92893 2 5.25 3.67893 5.25 5.75C5.25 7.82107 6.92893 9.5 9 9.5Z"
                        stroke="#5CC159"
                        strokeWidth="1.125"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.4397 17C15.4397 14.0975 12.5522 11.75 8.99718 11.75C5.44218 11.75 2.55469 14.0975 2.55469 17"
                        stroke="#5CC159"
                        strokeWidth="1.125"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="txt">
                    <p>200</p>
                    <span>{t("Dashboard.marketplace.Followers")}</span>
                  </div>
                </div>
                <div className="num">
                  <div className="icon">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.42871 4.31791V3.79572C4.42871 2.58447 5.40309 1.39476 6.61434 1.28171C8.05708 1.14174 9.27371 2.27762 9.27371 3.69344V4.43634"
                        stroke="#5CC159"
                        strokeWidth="0.8075"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.23599 12.0322H8.46599C10.6301 12.0322 11.0177 11.1655 11.1307 10.1104L11.5345 6.88036C11.6798 5.56683 11.303 4.49554 9.00432 4.49554H4.69765C2.39897 4.49554 2.02214 5.56683 2.16749 6.88036L2.57124 10.1104C2.68429 11.1655 3.07189 12.0322 5.23599 12.0322Z"
                        stroke="#5CC159"
                        strokeWidth="0.8075"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.73301 6.64893H8.73784"
                        stroke="#5CC159"
                        strokeWidth="1.07667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.96396 6.64893H4.9688"
                        stroke="#5CC159"
                        strokeWidth="1.07667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="txt">
                    <p>$10</p>
                    <span>{t("Dashboard.marketplace.Subscription")}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="roi">
              <div className="roi_info">
                <p>{t("Dashboard.marketplace.roi")}</p>
                <h3>80%</h3>
              </div>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={200}
                  height={110}
                  data={data}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <defs>
                    <linearGradient
                      id="GradientMarket"
                      x1="0"
                      x2="0"
                      y1="0"
                      y2="1"
                    >
                      <stop stopColor="#5CC159" stop-opacity="1" />
                      <stop
                        offset="1"
                        stopColor={`${localStorage.getItem("darkMode") === "yes"
                            ? "transparent"
                            : "#FFF"
                          }`}
                        stop-opacity="1"
                      />
                    </linearGradient>
                  </defs>
                  <Area
                    type="monotone"
                    dataKey="uv"
                    strokeWidth="2.5"
                    stroke="#5CC159"
                    fill="url(#GradientMarket)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <button
              onClick={(e) => {
                setConfirmUnfollow(true);
                followBtn(e);
              }}
            >
              {t("Dashboard.marketplace.Unfollow")}
            </button>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="item" onClick={(e) => setPaymentPop(true)}>
            <div className="picture">
              <img src={trader} alt="Alexander Morgan" />
            </div>
            <div className="info">
              <h1>Bryan hook</h1>
              <div className="nums">
                <div className="num">
                  <div className="icon">
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 9.5C11.0711 9.5 12.75 7.82107 12.75 5.75C12.75 3.67893 11.0711 2 9 2C6.92893 2 5.25 3.67893 5.25 5.75C5.25 7.82107 6.92893 9.5 9 9.5Z"
                        stroke="#5CC159"
                        strokeWidth="1.125"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.4397 17C15.4397 14.0975 12.5522 11.75 8.99718 11.75C5.44218 11.75 2.55469 14.0975 2.55469 17"
                        stroke="#5CC159"
                        strokeWidth="1.125"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="txt">
                    <p>200</p>
                    <span>{t("Dashboard.marketplace.Followers")}</span>
                  </div>
                </div>
                <div className="num">
                  <div className="icon">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.42871 4.31791V3.79572C4.42871 2.58447 5.40309 1.39476 6.61434 1.28171C8.05708 1.14174 9.27371 2.27762 9.27371 3.69344V4.43634"
                        stroke="#5CC159"
                        strokeWidth="0.8075"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.23599 12.0322H8.46599C10.6301 12.0322 11.0177 11.1655 11.1307 10.1104L11.5345 6.88036C11.6798 5.56683 11.303 4.49554 9.00432 4.49554H4.69765C2.39897 4.49554 2.02214 5.56683 2.16749 6.88036L2.57124 10.1104C2.68429 11.1655 3.07189 12.0322 5.23599 12.0322Z"
                        stroke="#5CC159"
                        strokeWidth="0.8075"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.73301 6.64893H8.73784"
                        stroke="#5CC159"
                        strokeWidth="1.07667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.96396 6.64893H4.9688"
                        stroke="#5CC159"
                        strokeWidth="1.07667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="txt">
                    <p>$10</p>
                    <span>{t("Dashboard.marketplace.Subscription")}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="roi">
              <div className="roi_info">
                <p>{t("Dashboard.marketplace.roi")}</p>
                <h3>80%</h3>
              </div>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={200}
                  height={110}
                  data={data}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <defs>
                    <linearGradient
                      id="GradientMarket"
                      x1="0"
                      x2="0"
                      y1="0"
                      y2="1"
                    >
                      <stop stopColor="#5CC159" stop-opacity="1" />
                      <stop
                        offset="1"
                        stopColor={`${localStorage.getItem("darkMode") === "yes"
                            ? "transparent"
                            : "#FFF"
                          }`}
                        stop-opacity="1"
                      />
                    </linearGradient>
                  </defs>
                  <Area
                    type="monotone"
                    dataKey="uv"
                    strokeWidth="2.5"
                    stroke="#5CC159"
                    fill="url(#GradientMarket)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <button
              className="follow"
              onClick={(e) => {
                followBtn(e);
                setPaymentPop(true);
              }}
            >
              {t("Dashboard.marketplace.Follow")}
            </button>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="item">
            <div className="picture">
              <img src={trader} alt="Alexander Morgan" />
            </div>
            <div className="info">
              <h1>Bryan hook</h1>
              <div className="nums">
                <div className="num">
                  <div className="icon">
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 9.5C11.0711 9.5 12.75 7.82107 12.75 5.75C12.75 3.67893 11.0711 2 9 2C6.92893 2 5.25 3.67893 5.25 5.75C5.25 7.82107 6.92893 9.5 9 9.5Z"
                        stroke="#5CC159"
                        strokeWidth="1.125"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.4397 17C15.4397 14.0975 12.5522 11.75 8.99718 11.75C5.44218 11.75 2.55469 14.0975 2.55469 17"
                        stroke="#5CC159"
                        strokeWidth="1.125"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="txt">
                    <p>200</p>
                    <span>{t("Dashboard.marketplace.Followers")}</span>
                  </div>
                </div>
                <div className="num">
                  <div className="icon">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.42871 4.31791V3.79572C4.42871 2.58447 5.40309 1.39476 6.61434 1.28171C8.05708 1.14174 9.27371 2.27762 9.27371 3.69344V4.43634"
                        stroke="#5CC159"
                        strokeWidth="0.8075"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.23599 12.0322H8.46599C10.6301 12.0322 11.0177 11.1655 11.1307 10.1104L11.5345 6.88036C11.6798 5.56683 11.303 4.49554 9.00432 4.49554H4.69765C2.39897 4.49554 2.02214 5.56683 2.16749 6.88036L2.57124 10.1104C2.68429 11.1655 3.07189 12.0322 5.23599 12.0322Z"
                        stroke="#5CC159"
                        strokeWidth="0.8075"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.73301 6.64893H8.73784"
                        stroke="#5CC159"
                        strokeWidth="1.07667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.96396 6.64893H4.9688"
                        stroke="#5CC159"
                        strokeWidth="1.07667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="txt">
                    <p>$10</p>
                    <span>{t("Dashboard.marketplace.Subscription")}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="roi">
              <div className="roi_info">
                <p>{t("Dashboard.marketplace.roi")}</p>
                <h3>80%</h3>
              </div>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={200}
                  height={110}
                  data={data}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <defs>
                    <linearGradient
                      id="GradientMarket"
                      x1="0"
                      x2="0"
                      y1="0"
                      y2="1"
                    >
                      <stop stopColor="#5CC159" stop-opacity="1" />
                      <stop
                        offset="1"
                        stopColor={`${localStorage.getItem("darkMode") === "yes"
                            ? "transparent"
                            : "#FFF"
                          }`}
                        stop-opacity="1"
                      />
                    </linearGradient>
                  </defs>
                  <Area
                    type="monotone"
                    dataKey="uv"
                    strokeWidth="2.5"
                    stroke="#5CC159"
                    fill="url(#GradientMarket)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <button
              onClick={(e) => {
                setConfirmUnfollow(true);
                followBtn(e);
              }}
            >
              {t("Dashboard.marketplace.Unfollow")}
            </button>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="item" onClick={(e) => setPaymentPop(true)}>
            <div className="picture">
              <img src={trader} alt="Alexander Morgan" />
            </div>
            <div className="info">
              <h1>Bryan hook</h1>
              <div className="nums">
                <div className="num">
                  <div className="icon">
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 9.5C11.0711 9.5 12.75 7.82107 12.75 5.75C12.75 3.67893 11.0711 2 9 2C6.92893 2 5.25 3.67893 5.25 5.75C5.25 7.82107 6.92893 9.5 9 9.5Z"
                        stroke="#5CC159"
                        strokeWidth="1.125"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.4397 17C15.4397 14.0975 12.5522 11.75 8.99718 11.75C5.44218 11.75 2.55469 14.0975 2.55469 17"
                        stroke="#5CC159"
                        strokeWidth="1.125"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="txt">
                    <p>200</p>
                    <span>{t("Dashboard.marketplace.Followers")}</span>
                  </div>
                </div>
                <div className="num">
                  <div className="icon">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.42871 4.31791V3.79572C4.42871 2.58447 5.40309 1.39476 6.61434 1.28171C8.05708 1.14174 9.27371 2.27762 9.27371 3.69344V4.43634"
                        stroke="#5CC159"
                        strokeWidth="0.8075"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.23599 12.0322H8.46599C10.6301 12.0322 11.0177 11.1655 11.1307 10.1104L11.5345 6.88036C11.6798 5.56683 11.303 4.49554 9.00432 4.49554H4.69765C2.39897 4.49554 2.02214 5.56683 2.16749 6.88036L2.57124 10.1104C2.68429 11.1655 3.07189 12.0322 5.23599 12.0322Z"
                        stroke="#5CC159"
                        strokeWidth="0.8075"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.73301 6.64893H8.73784"
                        stroke="#5CC159"
                        strokeWidth="1.07667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.96396 6.64893H4.9688"
                        stroke="#5CC159"
                        strokeWidth="1.07667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="txt">
                    <p>$10</p>
                    <span>{t("Dashboard.marketplace.Subscription")}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="roi">
              <div className="roi_info">
                <p>{t("Dashboard.marketplace.roi")}</p>
                <h3>80%</h3>
              </div>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={200}
                  height={110}
                  data={data}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <defs>
                    <linearGradient
                      id="GradientMarket"
                      x1="0"
                      x2="0"
                      y1="0"
                      y2="1"
                    >
                      <stop stopColor="#5CC159" stop-opacity="1" />
                      <stop
                        offset="1"
                        stopColor={`${localStorage.getItem("darkMode") === "yes"
                            ? "transparent"
                            : "#FFF"
                          }`}
                        stop-opacity="1"
                      />
                    </linearGradient>
                  </defs>
                  <Area
                    type="monotone"
                    dataKey="uv"
                    strokeWidth="2.5"
                    stroke="#5CC159"
                    fill="url(#GradientMarket)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <button
              className="follow"
              onClick={(e) => {
                followBtn(e);
                setPaymentPop(true);
              }}
            >
              {t("Dashboard.marketplace.Follow")}
            </button>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="item">
            <div className="picture">
              <img src={trader} alt="Alexander Morgan" />
            </div>
            <div className="info">
              <h1>Bryan hook</h1>
              <div className="nums">
                <div className="num">
                  <div className="icon">
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 9.5C11.0711 9.5 12.75 7.82107 12.75 5.75C12.75 3.67893 11.0711 2 9 2C6.92893 2 5.25 3.67893 5.25 5.75C5.25 7.82107 6.92893 9.5 9 9.5Z"
                        stroke="#5CC159"
                        strokeWidth="1.125"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.4397 17C15.4397 14.0975 12.5522 11.75 8.99718 11.75C5.44218 11.75 2.55469 14.0975 2.55469 17"
                        stroke="#5CC159"
                        strokeWidth="1.125"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="txt">
                    <p>200</p>
                    <span>{t("Dashboard.marketplace.Followers")}</span>
                  </div>
                </div>
                <div className="num">
                  <div className="icon">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.42871 4.31791V3.79572C4.42871 2.58447 5.40309 1.39476 6.61434 1.28171C8.05708 1.14174 9.27371 2.27762 9.27371 3.69344V4.43634"
                        stroke="#5CC159"
                        strokeWidth="0.8075"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.23599 12.0322H8.46599C10.6301 12.0322 11.0177 11.1655 11.1307 10.1104L11.5345 6.88036C11.6798 5.56683 11.303 4.49554 9.00432 4.49554H4.69765C2.39897 4.49554 2.02214 5.56683 2.16749 6.88036L2.57124 10.1104C2.68429 11.1655 3.07189 12.0322 5.23599 12.0322Z"
                        stroke="#5CC159"
                        strokeWidth="0.8075"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.73301 6.64893H8.73784"
                        stroke="#5CC159"
                        strokeWidth="1.07667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.96396 6.64893H4.9688"
                        stroke="#5CC159"
                        strokeWidth="1.07667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="txt">
                    <p>$10</p>
                    <span>{t("Dashboard.marketplace.Subscription")}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="roi">
              <div className="roi_info">
                <p>{t("Dashboard.marketplace.roi")}</p>
                <h3>80%</h3>
              </div>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={200}
                  height={110}
                  data={data}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <defs>
                    <linearGradient
                      id="GradientMarket"
                      x1="0"
                      x2="0"
                      y1="0"
                      y2="1"
                    >
                      <stop stopColor="#5CC159" stop-opacity="1" />
                      <stop
                        offset="1"
                        stopColor={`${localStorage.getItem("darkMode") === "yes"
                            ? "transparent"
                            : "#FFF"
                          }`}
                        stop-opacity="1"
                      />
                    </linearGradient>
                  </defs>
                  <Area
                    type="monotone"
                    dataKey="uv"
                    strokeWidth="2.5"
                    stroke="#5CC159"
                    fill="url(#GradientMarket)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <button
              onClick={(e) => {
                setConfirmUnfollow(true);
                followBtn(e);
              }}
            >
              {t("Dashboard.marketplace.Unfollow")}
            </button>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="item" onClick={(e) => setPaymentPop(true)}>
            <div className="picture">
              <img src={trader} alt="Alexander Morgan" />
            </div>
            <div className="info">
              <h1>Bryan hook</h1>
              <div className="nums">
                <div className="num">
                  <div className="icon">
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 9.5C11.0711 9.5 12.75 7.82107 12.75 5.75C12.75 3.67893 11.0711 2 9 2C6.92893 2 5.25 3.67893 5.25 5.75C5.25 7.82107 6.92893 9.5 9 9.5Z"
                        stroke="#5CC159"
                        strokeWidth="1.125"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.4397 17C15.4397 14.0975 12.5522 11.75 8.99718 11.75C5.44218 11.75 2.55469 14.0975 2.55469 17"
                        stroke="#5CC159"
                        strokeWidth="1.125"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="txt">
                    <p>200</p>
                    <span>{t("Dashboard.marketplace.Followers")}</span>
                  </div>
                </div>
                <div className="num">
                  <div className="icon">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.42871 4.31791V3.79572C4.42871 2.58447 5.40309 1.39476 6.61434 1.28171C8.05708 1.14174 9.27371 2.27762 9.27371 3.69344V4.43634"
                        stroke="#5CC159"
                        strokeWidth="0.8075"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.23599 12.0322H8.46599C10.6301 12.0322 11.0177 11.1655 11.1307 10.1104L11.5345 6.88036C11.6798 5.56683 11.303 4.49554 9.00432 4.49554H4.69765C2.39897 4.49554 2.02214 5.56683 2.16749 6.88036L2.57124 10.1104C2.68429 11.1655 3.07189 12.0322 5.23599 12.0322Z"
                        stroke="#5CC159"
                        strokeWidth="0.8075"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.73301 6.64893H8.73784"
                        stroke="#5CC159"
                        strokeWidth="1.07667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.96396 6.64893H4.9688"
                        stroke="#5CC159"
                        strokeWidth="1.07667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="txt">
                    <p>$10</p>
                    <span>{t("Dashboard.marketplace.Subscription")}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="roi">
              <div className="roi_info">
                <p>{t("Dashboard.marketplace.roi")}</p>
                <h3>80%</h3>
              </div>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={200}
                  height={110}
                  data={data}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <defs>
                    <linearGradient
                      id="GradientMarket"
                      x1="0"
                      x2="0"
                      y1="0"
                      y2="1"
                    >
                      <stop stopColor="#5CC159" stop-opacity="1" />
                      <stop
                        offset="1"
                        stopColor={`${localStorage.getItem("darkMode") === "yes"
                            ? "transparent"
                            : "#FFF"
                          }`}
                        stop-opacity="1"
                      />
                    </linearGradient>
                  </defs>
                  <Area
                    type="monotone"
                    dataKey="uv"
                    strokeWidth="2.5"
                    stroke="#5CC159"
                    fill="url(#GradientMarket)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <button
              className="follow"
              onClick={(e) => {
                followBtn(e);
                setPaymentPop(true);
              }}
            >
              {t("Dashboard.marketplace.Follow")}
            </button>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="item">
            <div className="picture">
              <img src={trader} alt="Alexander Morgan" />
            </div>
            <div className="info">
              <h1>Bryan hook</h1>
              <div className="nums">
                <div className="num">
                  <div className="icon">
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 9.5C11.0711 9.5 12.75 7.82107 12.75 5.75C12.75 3.67893 11.0711 2 9 2C6.92893 2 5.25 3.67893 5.25 5.75C5.25 7.82107 6.92893 9.5 9 9.5Z"
                        stroke="#5CC159"
                        strokeWidth="1.125"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.4397 17C15.4397 14.0975 12.5522 11.75 8.99718 11.75C5.44218 11.75 2.55469 14.0975 2.55469 17"
                        stroke="#5CC159"
                        strokeWidth="1.125"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="txt">
                    <p>200</p>
                    <span>{t("Dashboard.marketplace.Followers")}</span>
                  </div>
                </div>
                <div className="num">
                  <div className="icon">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.42871 4.31791V3.79572C4.42871 2.58447 5.40309 1.39476 6.61434 1.28171C8.05708 1.14174 9.27371 2.27762 9.27371 3.69344V4.43634"
                        stroke="#5CC159"
                        strokeWidth="0.8075"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.23599 12.0322H8.46599C10.6301 12.0322 11.0177 11.1655 11.1307 10.1104L11.5345 6.88036C11.6798 5.56683 11.303 4.49554 9.00432 4.49554H4.69765C2.39897 4.49554 2.02214 5.56683 2.16749 6.88036L2.57124 10.1104C2.68429 11.1655 3.07189 12.0322 5.23599 12.0322Z"
                        stroke="#5CC159"
                        strokeWidth="0.8075"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.73301 6.64893H8.73784"
                        stroke="#5CC159"
                        strokeWidth="1.07667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.96396 6.64893H4.9688"
                        stroke="#5CC159"
                        strokeWidth="1.07667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="txt">
                    <p>$10</p>
                    <span>{t("Dashboard.marketplace.Subscription")}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="roi">
              <div className="roi_info">
                <p>{t("Dashboard.marketplace.roi")}</p>
                <h3>80%</h3>
              </div>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={200}
                  height={110}
                  data={data}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <defs>
                    <linearGradient
                      id="GradientMarket"
                      x1="0"
                      x2="0"
                      y1="0"
                      y2="1"
                    >
                      <stop stopColor="#5CC159" stop-opacity="1" />
                      <stop
                        offset="1"
                        stopColor={`${localStorage.getItem("darkMode") === "yes"
                            ? "transparent"
                            : "#FFF"
                          }`}
                        stop-opacity="1"
                      />
                    </linearGradient>
                  </defs>
                  <Area
                    type="monotone"
                    dataKey="uv"
                    strokeWidth="2.5"
                    stroke="#5CC159"
                    fill="url(#GradientMarket)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <button
              onClick={(e) => {
                setConfirmUnfollow(true);
                followBtn(e);
              }}
            >
              {t("Dashboard.marketplace.Unfollow")}
            </button>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="item" onClick={(e) => setPaymentPop(true)}>
            <div className="picture">
              <img src={trader} alt="Alexander Morgan" />
            </div>
            <div className="info">
              <h1>Bryan hook</h1>
              <div className="nums">
                <div className="num">
                  <div className="icon">
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 9.5C11.0711 9.5 12.75 7.82107 12.75 5.75C12.75 3.67893 11.0711 2 9 2C6.92893 2 5.25 3.67893 5.25 5.75C5.25 7.82107 6.92893 9.5 9 9.5Z"
                        stroke="#5CC159"
                        strokeWidth="1.125"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.4397 17C15.4397 14.0975 12.5522 11.75 8.99718 11.75C5.44218 11.75 2.55469 14.0975 2.55469 17"
                        stroke="#5CC159"
                        strokeWidth="1.125"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="txt">
                    <p>200</p>
                    <span>{t("Dashboard.marketplace.Followers")}</span>
                  </div>
                </div>
                <div className="num">
                  <div className="icon">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.42871 4.31791V3.79572C4.42871 2.58447 5.40309 1.39476 6.61434 1.28171C8.05708 1.14174 9.27371 2.27762 9.27371 3.69344V4.43634"
                        stroke="#5CC159"
                        strokeWidth="0.8075"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.23599 12.0322H8.46599C10.6301 12.0322 11.0177 11.1655 11.1307 10.1104L11.5345 6.88036C11.6798 5.56683 11.303 4.49554 9.00432 4.49554H4.69765C2.39897 4.49554 2.02214 5.56683 2.16749 6.88036L2.57124 10.1104C2.68429 11.1655 3.07189 12.0322 5.23599 12.0322Z"
                        stroke="#5CC159"
                        strokeWidth="0.8075"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.73301 6.64893H8.73784"
                        stroke="#5CC159"
                        strokeWidth="1.07667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.96396 6.64893H4.9688"
                        stroke="#5CC159"
                        strokeWidth="1.07667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="txt">
                    <p>$10</p>
                    <span>{t("Dashboard.marketplace.Subscription")}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="roi">
              <div className="roi_info">
                <p>{t("Dashboard.marketplace.roi")}</p>
                <h3>80%</h3>
              </div>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={200}
                  height={110}
                  data={data}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <defs>
                    <linearGradient
                      id="GradientMarket"
                      x1="0"
                      x2="0"
                      y1="0"
                      y2="1"
                    >
                      <stop stopColor="#5CC159" stop-opacity="1" />
                      <stop
                        offset="1"
                        stopColor={`${localStorage.getItem("darkMode") === "yes"
                            ? "transparent"
                            : "#FFF"
                          }`}
                        stop-opacity="1"
                      />
                    </linearGradient>
                  </defs>
                  <Area
                    type="monotone"
                    dataKey="uv"
                    strokeWidth="2.5"
                    stroke="#5CC159"
                    fill="url(#GradientMarket)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <button
              className="follow"
              onClick={(e) => {
                followBtn(e);
                setPaymentPop(true);
              }}
            >
              {t("Dashboard.marketplace.Follow")}
            </button>
          </div>
        </div>
      </div>
      <div className="pagination">
        <button className="active">1</button>
        <button>2</button>
        <button>3</button>
      </div>
      {/* Payment Popup */}
      {PaymentPop && (
        <div className="pop-up payment-pop-up">
          <div className="pop-up-content">
            <div className="pop-up-title">
              <h1>{t("Dashboard.marketplace.Payment")}</h1>
              <div className="close" onClick={(e) => setPaymentPop(false)}>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.0009 26.9999L9.00098 9M27.001 9L9.00098 27.0001"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="payment_content">
              <div className="prt_one">
                <div className="inpt">
                  <label>{t("Dashboard.marketplace.Choose")}</label>
                  <Select
                    value={exchange}
                    onChange={(e) => setExchange(e.target.value)}
                    displayEmpty
                    className="select_filter"
                  >
                    <MenuItem value="">45515312</MenuItem>
                    <MenuItem value="5246954845">5246954845</MenuItem>
                    <MenuItem value="54848997">54848997</MenuItem>
                    <MenuItem value="54848997854">54848997854</MenuItem>
                  </Select>
                  <div className="wrong-input">
                    <svg
                      width="43"
                      height="44"
                      viewBox="0 0 43 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.75"
                        width="42.5001"
                        height="42.5"
                        rx="6.25001"
                        fill="#E72E4F"
                      />
                      <path
                        d="M21.75 9.49998C14.8625 9.49998 9.25 15.1125 9.25 22C9.25 28.8875 14.8625 34.5 21.75 34.5C28.6375 34.5 34.25 28.8875 34.25 22C34.25 15.1125 28.6375 9.49998 21.75 9.49998ZM27.725 19.125L20.6375 26.2125C20.4625 26.3875 20.225 26.4875 19.975 26.4875C19.725 26.4875 19.4875 26.3875 19.3125 26.2125L15.775 22.675C15.4125 22.3125 15.4125 21.7125 15.775 21.35C16.1375 20.9875 16.7375 20.9875 17.1 21.35L19.975 24.225L26.4 17.8C26.7625 17.4375 27.3625 17.4375 27.725 17.8C28.0875 18.1625 28.0875 18.75 27.725 19.125Z"
                        fill="white"
                      />
                    </svg>
                    <p>{t("Dashboard.marketplace.error")}</p>
                  </div>
                </div>
                <div className="inpt">
                  <label>{t("Dashboard.marketplace.Following")}</label>
                  <div className="check_inpt">
                    <p>
                      <input
                        type="radio"
                        id="AllActions"
                        name="Following-type"
                        defaultChecked
                      />
                      <label for="AllActions">
                        {t("Dashboard.marketplace.All")}
                      </label>
                    </p>
                    <p>
                      <input
                        type="radio"
                        id="Entry/ExitSignal"
                        name="Following-type"
                      />
                      <label for="Entry/ExitSignal">
                        {t("Dashboard.marketplace.Entry/Exit")}
                      </label>
                    </p>
                  </div>
                </div>
                <div className="inpt">
                  <label>{t("Dashboard.marketplace.Set")}</label>
                  <div className="check_inpt">
                    <p>
                      <input
                        type="radio"
                        id="TradersSettings"
                        name="copy-trading-volume"
                        defaultChecked
                        onClick={() => setCustomSettings(false)}
                      />
                      <label for="TradersSettings">
                        {t("Dashboard.marketplace.Traders")}
                      </label>
                    </p>
                    <p>
                      <input
                        type="radio"
                        id="CustomSettings"
                        name="copy-trading-volume"
                        onClick={() => setCustomSettings(true)}
                      />
                      <label for="CustomSettings">
                        {t("Dashboard.marketplace.Custom")}
                      </label>
                    </p>
                  </div>
                </div>
                {CustomSettings && (
                  <>
                    <div className="inpt">
                      <label>{t("Dashboard.marketplace.Position")}</label>
                      <div className="check_btns">
                        <p onClick={(e) => setChoosePosition("PositionSize")}>
                          <input
                            type="radio"
                            id="PositionSize"
                            name="ChoosePostionSize"
                            defaultChecked
                          />
                          <label for="PositionSize">
                            {t("Dashboard.marketplace.Position")}
                          </label>
                        </p>
                        <p onClick={(e) => setChoosePosition("FixedAmount")}>
                          <input
                            type="radio"
                            id="FixedAmount"
                            name="ChoosePostionSize"
                          />
                          <label for="FixedAmount">
                            {t("Dashboard.marketplace.FixedAmount")}
                          </label>
                        </p>
                      </div>
                    </div>
                    {choosePosition === "PositionSize" && (
                      <div className="inpt">
                        <label>{t("Dashboard.marketplace.Percentage")}</label>
                        <Select
                          value={PercentagePortfolio}
                          onChange={(e) =>
                            setPercentagePortfolio(e.target.value)
                          }
                          displayEmpty
                          className="select_filter"
                        >
                          <MenuItem disabled hidden value="">
                            {t("Dashboard.marketplace.SelectPercentage")}
                          </MenuItem>
                          <MenuItem value="10%">10%</MenuItem>
                          <MenuItem value="30%">30%</MenuItem>
                          <MenuItem value="40%">40%</MenuItem>
                          <MenuItem value="80%">80%</MenuItem>
                        </Select>
                      </div>
                    )}
                    {choosePosition === "FixedAmount" && (
                      <div className="inpt">
                        <label>{t("Dashboard.marketplace.FixedAmount")}</label>
                        <Select
                          value={AmountPortfolio}
                          onChange={(e) => setAmountPortfolio(e.target.value)}
                          displayEmpty
                          className="select_filter"
                        >
                          <MenuItem disabled hidden value="">
                            {t("Dashboard.marketplace.SelectAmount")}
                          </MenuItem>
                          <MenuItem value="10$">10$</MenuItem>
                          <MenuItem value="30$">30$</MenuItem>
                          <MenuItem value="40$">40$</MenuItem>
                          <MenuItem value="80$">80$</MenuItem>
                        </Select>
                      </div>
                    )}
                    <div className="inpt">
                      <label>{t("Dashboard.marketplace.Leverage")}</label>
                      <Select
                        value={Leverage}
                        onChange={(e) => setLeverage(e.target.value)}
                        displayEmpty
                        className="select_filter"
                      >
                        <MenuItem disabled hidden value="">
                          {t("Dashboard.marketplace.Select")}
                        </MenuItem>
                        <MenuItem value="leverage 1">leverage 1</MenuItem>
                        <MenuItem value="leverage 2">leverage 2</MenuItem>
                        <MenuItem value="leverage 3">leverage 3</MenuItem>
                        <MenuItem value="leverage 4">leverage 4</MenuItem>
                      </Select>
                    </div>
                  </>
                )}
              </div>
              <div className="prt_two">
                <div className="inpt">
                  <label>{t("Dashboard.marketplace.PaymentMethod")}</label>
                  <div className="items">
                    <div className="item">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M35.9111 12.3587C35.9111 13.4312 35.0336 14.3087 33.9611 14.3087H5.36113C4.28863 14.3087 3.41113 13.4312 3.41113 12.3587V12.3425C3.41113 8.62124 6.41738 5.61499 10.1386 5.61499H29.1674C32.8886 5.61499 35.9111 8.63749 35.9111 12.3587Z"
                          fill="black"
                        />
                        <path
                          d="M3.41113 18.6962V26.8375C3.41113 30.5587 6.41738 33.565 10.1386 33.565H29.1674C32.8886 33.565 35.9111 30.5425 35.9111 26.8212V18.6962C35.9111 17.6237 35.0336 16.7462 33.9611 16.7462H5.36113C4.28863 16.7462 3.41113 17.6237 3.41113 18.6962ZM13.1611 28.1212H9.91113C9.24488 28.1212 8.69238 27.5687 8.69238 26.9025C8.69238 26.2362 9.24488 25.6837 9.91113 25.6837H13.1611C13.8274 25.6837 14.3799 26.2362 14.3799 26.9025C14.3799 27.5687 13.8274 28.1212 13.1611 28.1212ZM23.7236 28.1212H17.2236C16.5574 28.1212 16.0049 27.5687 16.0049 26.9025C16.0049 26.2362 16.5574 25.6837 17.2236 25.6837H23.7236C24.3899 25.6837 24.9424 26.2362 24.9424 26.9025C24.9424 27.5687 24.3899 28.1212 23.7236 28.1212Z"
                          fill="black"
                        />
                      </svg>
                      <div className="info">
                        <span>{t("Dashboard.marketplace.Stripe")}</span>
                        <p>{t("Dashboard.marketplace.Credit")} (XXX4575X..)</p>
                      </div>
                      <div className="options">
                        <button className="edit">
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M25.8676 27.521H3.83314C3.33125 27.521 2.91504 27.1048 2.91504 26.6029C2.91504 26.101 3.33125 25.6848 3.83314 25.6848H25.8676C26.3695 25.6848 26.7857 26.101 26.7857 26.6029C26.7857 27.1048 26.3695 27.521 25.8676 27.521Z"
                              fill="#99A399"
                            />
                            <path
                              d="M23.4437 4.84992C21.0689 2.47509 18.743 2.41389 16.307 4.84992L14.8257 6.33113C14.7033 6.45354 14.6544 6.64941 14.7033 6.82078C15.6337 10.0648 18.2289 12.6599 21.4728 13.5903C21.5218 13.6025 21.5707 13.6148 21.6197 13.6148C21.7544 13.6148 21.8768 13.5658 21.9747 13.4679L23.4437 11.9866C24.6556 10.787 25.2432 9.62406 25.2432 8.44889C25.2554 7.23699 24.6678 6.06182 23.4437 4.84992Z"
                              fill="#99A399"
                            />
                            <path
                              d="M19.2703 14.7042C18.9153 14.5329 18.5726 14.3615 18.2421 14.1656C17.9728 14.0065 17.7157 13.8351 17.4586 13.6515C17.2505 13.5168 17.0057 13.321 16.7731 13.1251C16.7486 13.1129 16.6629 13.0394 16.565 12.9415C16.161 12.5987 15.7081 12.158 15.3041 11.6684C15.2674 11.6439 15.2062 11.5582 15.1205 11.448C14.9981 11.3011 14.79 11.0563 14.6064 10.7748C14.4595 10.5911 14.2881 10.3218 14.129 10.0525C13.9331 9.72201 13.7617 9.39149 13.5903 9.04873C13.5644 8.99316 13.5393 8.93787 13.515 8.8829C13.3343 8.47488 12.8023 8.35559 12.4868 8.67112L5.47432 15.6836C5.31518 15.8427 5.16828 16.1487 5.13156 16.3568L4.47052 21.0453C4.34811 21.8777 4.58069 22.6611 5.09483 23.1875C5.53552 23.616 6.14759 23.8486 6.80863 23.8486C6.95552 23.8486 7.10242 23.8363 7.24932 23.8118L11.95 23.1508C12.1703 23.1141 12.4764 22.9672 12.6233 22.808L19.627 15.8044C19.9447 15.4866 19.8248 14.9413 19.4115 14.765C19.365 14.7451 19.318 14.7249 19.2703 14.7042Z"
                              fill="#99A399"
                            />
                          </svg>
                        </button>
                        <button className="remove">
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M26.3327 6.99219C24.3618 6.79632 22.391 6.64943 20.4079 6.53926V6.52701L20.1386 4.93563C19.9549 3.80943 19.6856 2.12012 16.8211 2.12012H13.6139C10.7617 2.12012 10.4923 3.73598 10.2965 4.92339L10.0394 6.49029C8.90097 6.56374 7.76252 6.63719 6.62407 6.74736L4.12683 6.99219C3.61269 7.04115 3.24545 7.49408 3.29442 7.99598C3.34338 8.49788 3.78407 8.86512 4.29821 8.81615L6.79545 8.57132C13.2099 7.93477 19.6734 8.1796 26.1613 8.82839C26.198 8.82839 26.2225 8.82839 26.2592 8.82839C26.7244 8.82839 27.1284 8.47339 27.1773 7.99598C27.2141 7.49408 26.8468 7.04115 26.3327 6.99219Z"
                              fill="#E72E4F"
                            />
                            <path
                              d="M24.0797 10.5544C23.7859 10.2484 23.3819 10.077 22.9657 10.077H7.4926C7.07639 10.077 6.66019 10.2484 6.37863 10.5544C6.09708 10.8605 5.93794 11.2767 5.96243 11.7051L6.72139 24.2648C6.85605 26.1255 7.02743 28.4513 11.2997 28.4513H19.1586C23.4309 28.4513 23.6023 26.1377 23.7369 24.2648L24.4959 11.7174C24.5204 11.2767 24.3612 10.8605 24.0797 10.5544ZM17.2612 22.3184H13.1848C12.6829 22.3184 12.2667 21.9022 12.2667 21.4003C12.2667 20.8984 12.6829 20.4822 13.1848 20.4822H17.2612C17.7631 20.4822 18.1793 20.8984 18.1793 21.4003C18.1793 21.9022 17.7631 22.3184 17.2612 22.3184ZM18.2895 17.4219H12.1688C11.6669 17.4219 11.2507 17.0056 11.2507 16.5038C11.2507 16.0019 11.6669 15.5856 12.1688 15.5856H18.2895C18.7914 15.5856 19.2076 16.0019 19.2076 16.5038C19.2076 17.0056 18.7914 17.4219 18.2895 17.4219Z"
                              fill="#E72E4F"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="item">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M31.0364 11.465C31.0364 11.7575 31.0201 12.0337 30.9876 12.3099C30.7926 14.6662 29.7526 16.7949 28.1763 18.3549C26.4213 20.1262 23.9839 21.215 21.2864 21.215H16.2001C15.3876 21.215 14.7051 21.8162 14.5914 22.6124L13.3563 31.1925C13.2588 31.8262 12.8039 32.3462 12.2026 32.5087C12.0564 32.5737 11.9101 32.59 11.7476 32.59H8.57884C7.57134 32.59 6.80761 31.6962 6.97011 30.705L10.8538 7.41873C11.2438 5.06248 13.2751 3.33997 15.6639 3.33997H22.9114C27.3964 3.33997 31.0364 6.97997 31.0364 11.465Z"
                          fill="#0C7FCC"
                        />
                        <path
                          d="M34.2856 17.965C34.2856 20.2075 33.3756 22.2388 31.9131 23.7175C30.4343 25.18 28.4031 26.09 26.1606 26.09H22.6668C21.8706 26.09 21.1881 26.6588 21.0581 27.455L19.8881 34.475C19.7581 35.2713 19.0756 35.84 18.2793 35.84H13.5181C13.2256 35.84 12.9656 35.775 12.7381 35.645C12.3806 35.45 12.6081 34.9788 12.9981 34.8163C14.4606 34.345 15.5331 33.0938 15.7606 31.5338L16.8006 24.3513C16.8656 23.945 17.2069 23.6525 17.5969 23.6525H21.2856C24.5518 23.6525 27.6231 22.385 29.8981 20.0938C31.2468 18.7613 32.2543 17.12 32.8393 15.3488C32.9693 14.9425 33.5056 14.8613 33.6843 15.235C34.0743 16.08 34.2856 16.99 34.2856 17.965Z"
                          fill="#0C7FCC"
                        />
                      </svg>
                      <div className="info">
                        <span>{t("Dashboard.marketplace.Paypal")}</span>
                        <p>Achraf Hamdani</p>
                      </div>
                      <div className="options">
                        <button className="edit">
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M25.8676 27.521H3.83314C3.33125 27.521 2.91504 27.1048 2.91504 26.6029C2.91504 26.101 3.33125 25.6848 3.83314 25.6848H25.8676C26.3695 25.6848 26.7857 26.101 26.7857 26.6029C26.7857 27.1048 26.3695 27.521 25.8676 27.521Z"
                              fill="#99A399"
                            />
                            <path
                              d="M23.4437 4.84992C21.0689 2.47509 18.743 2.41389 16.307 4.84992L14.8257 6.33113C14.7033 6.45354 14.6544 6.64941 14.7033 6.82078C15.6337 10.0648 18.2289 12.6599 21.4728 13.5903C21.5218 13.6025 21.5707 13.6148 21.6197 13.6148C21.7544 13.6148 21.8768 13.5658 21.9747 13.4679L23.4437 11.9866C24.6556 10.787 25.2432 9.62406 25.2432 8.44889C25.2554 7.23699 24.6678 6.06182 23.4437 4.84992Z"
                              fill="#99A399"
                            />
                            <path
                              d="M19.2703 14.7042C18.9153 14.5329 18.5726 14.3615 18.2421 14.1656C17.9728 14.0065 17.7157 13.8351 17.4586 13.6515C17.2505 13.5168 17.0057 13.321 16.7731 13.1251C16.7486 13.1129 16.6629 13.0394 16.565 12.9415C16.161 12.5987 15.7081 12.158 15.3041 11.6684C15.2674 11.6439 15.2062 11.5582 15.1205 11.448C14.9981 11.3011 14.79 11.0563 14.6064 10.7748C14.4595 10.5911 14.2881 10.3218 14.129 10.0525C13.9331 9.72201 13.7617 9.39149 13.5903 9.04873C13.5644 8.99316 13.5393 8.93787 13.515 8.8829C13.3343 8.47488 12.8023 8.35559 12.4868 8.67112L5.47432 15.6836C5.31518 15.8427 5.16828 16.1487 5.13156 16.3568L4.47052 21.0453C4.34811 21.8777 4.58069 22.6611 5.09483 23.1875C5.53552 23.616 6.14759 23.8486 6.80863 23.8486C6.95552 23.8486 7.10242 23.8363 7.24932 23.8118L11.95 23.1508C12.1703 23.1141 12.4764 22.9672 12.6233 22.808L19.627 15.8044C19.9447 15.4866 19.8248 14.9413 19.4115 14.765C19.365 14.7451 19.318 14.7249 19.2703 14.7042Z"
                              fill="#99A399"
                            />
                          </svg>
                        </button>
                        <button className="remove">
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M26.3327 6.99219C24.3618 6.79632 22.391 6.64943 20.4079 6.53926V6.52701L20.1386 4.93563C19.9549 3.80943 19.6856 2.12012 16.8211 2.12012H13.6139C10.7617 2.12012 10.4923 3.73598 10.2965 4.92339L10.0394 6.49029C8.90097 6.56374 7.76252 6.63719 6.62407 6.74736L4.12683 6.99219C3.61269 7.04115 3.24545 7.49408 3.29442 7.99598C3.34338 8.49788 3.78407 8.86512 4.29821 8.81615L6.79545 8.57132C13.2099 7.93477 19.6734 8.1796 26.1613 8.82839C26.198 8.82839 26.2225 8.82839 26.2592 8.82839C26.7244 8.82839 27.1284 8.47339 27.1773 7.99598C27.2141 7.49408 26.8468 7.04115 26.3327 6.99219Z"
                              fill="#E72E4F"
                            />
                            <path
                              d="M24.0797 10.5544C23.7859 10.2484 23.3819 10.077 22.9657 10.077H7.4926C7.07639 10.077 6.66019 10.2484 6.37863 10.5544C6.09708 10.8605 5.93794 11.2767 5.96243 11.7051L6.72139 24.2648C6.85605 26.1255 7.02743 28.4513 11.2997 28.4513H19.1586C23.4309 28.4513 23.6023 26.1377 23.7369 24.2648L24.4959 11.7174C24.5204 11.2767 24.3612 10.8605 24.0797 10.5544ZM17.2612 22.3184H13.1848C12.6829 22.3184 12.2667 21.9022 12.2667 21.4003C12.2667 20.8984 12.6829 20.4822 13.1848 20.4822H17.2612C17.7631 20.4822 18.1793 20.8984 18.1793 21.4003C18.1793 21.9022 17.7631 22.3184 17.2612 22.3184ZM18.2895 17.4219H12.1688C11.6669 17.4219 11.2507 17.0056 11.2507 16.5038C11.2507 16.0019 11.6669 15.5856 12.1688 15.5856H18.2895C18.7914 15.5856 19.2076 16.0019 19.2076 16.5038C19.2076 17.0056 18.7914 17.4219 18.2895 17.4219Z"
                              fill="#E72E4F"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="amount">
                  <p>{t("Dashboard.marketplace.Amount")}</p>
                  <p>10$</p>
                </div>
                <div className="btn_submit">
                  <div className="pr_tu">
                    {t("Dashboard.marketplace.accept")}
                    <Link to="/terms-of-use">
                      {t("Dashboard.marketplace.Terms")}
                    </Link>
                    <input type="checkbox" />
                    <div className="box">
                      <svg
                        width="9"
                        height="7"
                        viewBox="0 0 9 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.46387 6.36764C3.29261 6.36764 3.12991 6.29914 3.01002 6.17925L0.58664 3.75587C0.338308 3.50754 0.338308 3.09651 0.58664 2.84817C0.834972 2.59984 1.246 2.59984 1.49434 2.84817L3.46387 4.81771L7.86534 0.41623C8.11368 0.167897 8.52471 0.167897 8.77304 0.41623C9.02137 0.664562 9.02137 1.07559 8.77304 1.32393L3.91772 6.17925C3.79783 6.29914 3.63513 6.36764 3.46387 6.36764Z"
                          fill="#5CC159"
                        />
                      </svg>
                    </div>
                  </div>
                  <button
                    onClick={(e) => {
                      setPaymentPop(false);
                      setShowCongrats(true);
                    }}
                  >
                    {t("Dashboard.marketplace.Follow")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Congratulations Popup */}
      {showCongrats && (
        <CongratulationsPopup
          title={t("Dashboard.popup.CongratulationsTitle")}
          msg={t("Dashboard.marketplace.Congratulations")}
          btnText={t("Dashboard.popup.Got")}
          closePopup={() => setShowCongrats(false)}
        />
      )}
      {/* Problem Popup */}
      {confirmUnfollow && (
        <ProblemPopup
          title={t("Dashboard.marketplace.errorTitle")}
          msg={t("Dashboard.marketplace.errorText")}
          btnText={t("Dashboard.popup.Cancel")}
          btnText2={t("Dashboard.popup.Confirm")}
          closePopup={() => setConfirmUnfollow(false)}
        />
      )}
    </div>
  );
}

export default Marketplace;
