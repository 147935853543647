import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useState } from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";

function History() {
  const [historyFilter1, setHistoryFilter1] = useState("");
  const [historyFilter2, setHistoryFilter2] = useState("");
  const [t] = useTranslation();
  return (
    <>
      <div className="trades_history">
        <div className="title_sec">
          <h2>{t("Dashboard.Analytics.TradesHistory")}</h2>
          <div className="filter">
            <Select
              value={historyFilter1}
              onChange={(e) => setHistoryFilter1(e.target.value)}
              displayEmpty
              className="select_filter"
            >
              <MenuItem value="">Binance</MenuItem>
              <MenuItem value="Exchange 2">Gate.io</MenuItem>
              <MenuItem value="Exchange 3">CoinBase</MenuItem>
            </Select>
            <Select
              value={historyFilter2}
              onChange={(e) => setHistoryFilter2(e.target.value)}
              displayEmpty
              className="select_filter"
            >
              <MenuItem value="">{t("Dashboard.select1.a")}</MenuItem>
              <MenuItem value={t("Dashboard.select1.b")}>
                {t("Dashboard.select1.b")}
              </MenuItem>
              <MenuItem value={t("Dashboard.select1.c")}>
                {t("Dashboard.select1.c")}
              </MenuItem>
              <MenuItem value={t("Dashboard.select1.d")}>
                {t("Dashboard.select1.d")}
              </MenuItem>
              <MenuItem value={t("Dashboard.select1.e")}>
                {t("Dashboard.select1.e")}
              </MenuItem>
            </Select>
          </div>
        </div>
        <div className="trades_history_table table-bor">
          <Table>
            <thead>
              <tr>
                <th>{t("Dashboard.Analytics.TradeID")}</th>
                <th>{t("Dashboard.Analytics.Pair")}</th>
                <th>{t("Dashboard.Analytics.Direction")}</th>
                <th>{t("Dashboard.Analytics.Profit/Loss")}</th>
                <th>{t("Dashboard.Analytics.EntryPrice")}</th>
                <th>{t("Dashboard.Analytics.StartDateTime")}</th>
                <th>{t("Dashboard.Analytics.EndDateTime")}</th>
                <th>{t("Dashboard.Analytics.Quantity")}</th>
                <th>{t("Dashboard.Analytics.Status")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>458848</td>
                <td>BTC/USD</td>
                <td>
                  <p className="buy">{t("Dashboard.Analytics.Buy")}</p>
                </td>
                <td>$540.10</td>
                <td>23 Jul 11:45 am</td>
                <td>23 Jul 11:45 am</td>
                <td>7</td>
                <td>300</td>
                <td>
                  <p className="status-success">
                    {t("Dashboard.Analytics.Success")}
                  </p>
                </td>
              </tr>
              <tr>
                <td>458848</td>
                <td>BTC/USD</td>
                <td>
                  <p className="sell">{t("Dashboard.Analytics.Sell")}</p>
                </td>
                <td>$540.10</td>
                <td>23 Jul 11:45 am</td>
                <td>23 Jul 11:45 am</td>
                <td>7</td>
                <td>300</td>
                <td>
                  <p className="status-failed">
                    {t("Dashboard.Analytics.Failed")}
                  </p>
                </td>
              </tr>
              <tr>
                <td>458848</td>
                <td>BTC/USD</td>
                <td>
                  <p className="buy">{t("Dashboard.Analytics.Buy")}</p>
                </td>
                <td>$540.10</td>
                <td>23 Jul 11:45 am</td>
                <td>23 Jul 11:45 am</td>
                <td>7</td>
                <td>300</td>
                <td>
                  <p className="status-success">
                    {t("Dashboard.Analytics.Success")}
                  </p>
                </td>
              </tr>
              <tr>
                <td>458848</td>
                <td>BTC/USD</td>
                <td>
                  <p className="sell">{t("Dashboard.Analytics.Sell")}</p>
                </td>
                <td>$540.10</td>
                <td>23 Jul 11:45 am</td>
                <td>23 Jul 11:45 am</td>
                <td>7</td>
                <td>300</td>
                <td>
                  <p className="status-failed">
                    {t("Dashboard.Analytics.Failed")}
                  </p>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default History;
