/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Area, AreaChart, ResponsiveContainer } from "recharts";
import "./css/home.scss";
import Features from "./landing/features.js";
import Plans from "./landing/plans.js";
import Questions from "./landing/questions.js";
import SecTitle from "./landing/sec-title.js";
import Testimonials from "./landing/testimonials.js";

// Assets
import Binance from "./assets/Binance.png";
import BitFinex from "./assets/BitFinex.png";
import BitGet from "./assets/BitGet.png";
import BitMex from "./assets/BitMex.png";
import Bybit from "./assets/Bybit.png";
import CoinBase from "./assets/CoinBase.png";
import dash from "./assets/Dashboard 1.png";
import Deribit from "./assets/Deribit.png";
import trader2 from "./assets/Frame 1707480129-1.png";
import trader3 from "./assets/Frame 1707480129-2.png";
import trader1 from "./assets/Frame 1707480129.png";
import Gateio from "./assets/Gateio.png";
import referral from "./assets/Group 92.png";
import Huobi from "./assets/Huobi.png";
import Kraken from "./assets/Kraken.png";
import KuCoin from "./assets/KuCoin.png";
import MexcGlobal from "./assets/MexcGlobal.png";
import OKX from "./assets/OKX.png";
import en from "./assets/en.svg";
import ar from "./assets/icons8-saudi-arabia-48.png";
import Logo from "./assets/insta-signal_FINAL 1.png";

// Statistics
const data = [
  {
    name: "January",
    uv: 0,
  },
  {
    name: "February",
    uv: 2500,
  },
  {
    name: "March",
    uv: 800,
  },
  {
    name: "April",
    uv: 1500,
  },
  {
    name: "May",
    uv: 1800,
  },
  {
    name: "June",
    uv: 2500,
  },
  {
    name: "July",
    uv: 2000,
  },
  {
    name: "August",
    uv: 1302,
  },
  {
    name: "September",
    uv: 2600,
  },
  {
    name: "October",
    uv: 900,
  },
  {
    name: "November",
    uv: 1800,
  },
  {
    name: "December",
    uv: 4000,
  },
];

function Waitlist() {
  const [langOpen, setLangOpen] = useState(false);
  const langRef = useRef();
  const [menu, SetMenu] = useState(false);
  const menuRef = useRef();
  const [toTop, setToTop] = useState(false);
  window.addEventListener("click", (e) => {
    if (e.target !== langRef.current) {
      setLangOpen(false);
    }
    if (e.target !== menuRef.current) {
      SetMenu(false);
    }
  });
  useEffect(() => {
    let url = window.location.href.split("/");
    let target = url[url.length - 1].toLowerCase();
    let element = document.getElementById(target);
    element && element.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);
  function goSec(e, elm) {
    let hero = document.getElementById(`${elm}`);
    e.preventDefault(); // Stop Page Reloading
    hero && hero.scrollIntoView();
  }
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  window.addEventListener("scroll", (e) => {
    if (window.scrollY >= 1000) {
      setToTop(true);
    } else {
      setToTop(false);
    }
  });
  // Language
  const [lang, SetLang] = useState(localStorage.getItem("lang"));
  const [t, i18n] = useTranslation();
  function CheckLanguage(lang) {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
    SetLang(lang);
    setLangOpen(!langOpen);
    if (lang === "ar") {
      document.body.classList.add("ar");
    } else {
      document.body.classList.remove("ar");
    }
  }
  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      i18n.changeLanguage("en");
      SetLang("en");
    } else if (localStorage.getItem("lang") === "ar") {
      i18n.changeLanguage("ar");
      SetLang("ar");
    } else {
      i18n.changeLanguage("en");
      SetLang("en");
    }
  }, [i18n, lang]);

  return (
    <div className="home">
      {/* Header */}
      <header>
        <nav>
          <div className="container">
            <div className="logo">
              <img src={Logo} alt="logo" />
            </div>
            <div
              className={`menu ${menu ? "active" : ""}`}
              ref={menuRef}
              onClick={() => SetMenu(!menu)}
            >
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
              >
                <path
                  fill="#000000"
                  fillRule="evenodd"
                  d="M18 5a1 1 0 100-2H2a1 1 0 000 2h16zm0 4a1 1 0 100-2H2a1 1 0 100 2h16zm1 3a1 1 0 01-1 1H2a1 1 0 110-2h16a1 1 0 011 1zm-1 5a1 1 0 100-2H2a1 1 0 100 2h16z"
                />
              </svg>
            </div>
            <div className="right_section">
              <ul
                className={`list-unstyled links ${menu ? "active-menu" : ""}`}
              >
                <li>
                  <a href="/" onClick={(e) => goSec(e, "brokers")}>
                    {t("Home.header.link1")}
                  </a>
                </li>
                <li>
                  <a href="/" onClick={(e) => goSec(e, "features")}>
                    {t("Home.header.link2")}
                  </a>
                </li>
                <li>
                  <a href="/" onClick={(e) => goSec(e, "traders")}>
                    {t("Home.header.link3")}
                  </a>
                </li>
                <li>
                  <a href="/" onClick={(e) => goSec(e, "pricing")}>
                    {t("Home.header.link4")}
                  </a>
                </li>
                <li>
                  <a href="/" onClick={(e) => goSec(e, "faq")}>
                    {t("Home.header.link5")}
                  </a>
                </li>
                <li>
                  <a href="/" onClick={(e) => goSec(e, "referrals")}>
                    {t("Home.header.link6")}
                  </a>
                </li>
                <li>
                  <Link to="/sign-in">{t("Home.header.link7")}</Link>
                </li>
                <li>
                  <Link to="/sign-up">{t("Home.header.link8")}</Link>
                </li>
              </ul>
              <div className="lang">
                <div
                  className="top"
                  ref={langRef}
                  onClick={() => setLangOpen(!langOpen)}
                >
                  {lang === "en" && (
                    <>
                      <img src={en} alt="EN" />
                      <p>{t("Home.header.en")}</p>
                    </>
                  )}
                  {lang === "ar" && (
                    <>
                      <img src={ar} alt="AR" />
                      <p>{t("Home.header.ar")}</p>
                    </>
                  )}
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.8531 6.60547H9.18064H4.97314C4.25314 6.60547 3.89314 7.47547 4.40314 7.98547L8.28814 11.8705C8.91064 12.493 9.92314 12.493 10.5456 11.8705L12.0231 10.393L14.4306 7.98547C14.9331 7.47547 14.5731 6.60547 13.8531 6.60547Z"
                      fill="black"
                    />
                  </svg>
                </div>
                {langOpen && (
                  <div className="down_list">
                    {lang === "en" && (
                      <a onClick={(e) => CheckLanguage("ar")}>
                        <img src={ar} alt="Arabic Language" />
                        {t("Home.header.ar")}
                      </a>
                    )}
                    {lang === "ar" && (
                      <a onClick={(e) => CheckLanguage("en")}>
                        <img src={en} alt="English Language" />
                        {t("Home.header.en")}
                      </a>
                    )}
                  </div>
                )}
              </div>
              <div className="btns">
                <Link to="/sign-in">{t("Home.header.link7")}</Link>
                <Link to="/sign-up">{t("Home.header.link8")}</Link>
              </div>
            </div>
          </div>
        </nav>
        <div className="header_content">
          <div className="container">
            <div className="head_sec">
              <div className="lef-sec">
                <h4>{t("Home.header.h4")}</h4>
                <h1>
                  {t("Home.header.h1")}
                  <span>{t("Home.header.span")}</span>
                </h1>
                <p>{t("Home.header.p")}</p>
                <Link to="/sign-up">{t("Home.header.a")}</Link>
              </div>
              <div className="dash_img">
                <img src={dash} alt="Dashboard" />
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* Supported Brokers */}
      <div id="brokers" className="supported_brokers">
        <SecTitle
          title={{
            one: t("Home.supportedBrokers.title.one"),
            color: t("Home.supportedBrokers.title.color"),
            last: "",
          }}
          text={t("Home.supportedBrokers.title.txt")}
        />
        <div className="brokers">
          <div className="items">
            <div className="item">
              <img src={Binance} alt="Binance" />
              <p>Binance</p>
            </div>
            <div className="item">
              <img src={BitMex} alt="BitMex" />
              <p>BitMex</p>
            </div>
            <div className="item">
              <img src={KuCoin} alt="KuCoin" />
              <p>KuCoin</p>
            </div>
            <div className="item">
              <img src={Kraken} alt="Kraken" />
              <p>Kraken</p>
            </div>
          </div>
          <div className="items">
            <div className="item">
              <img src={Bybit} alt="Bybit" />
              <p>Bybit</p>
            </div>
            <div className="item">
              <img src={OKX} alt="OKX" />
              <p>OKX</p>
            </div>
            <div className="item">
              <img src={Deribit} alt="Deribit" />
              <p>Deribit</p>
            </div>
            <div className="item">
              <img src={Huobi} alt="Huobi" />
              <p>Huobi</p>
            </div>
            <div className="item">
              <img src={BitFinex} alt="BitFinex" />
              <p>BitFinex</p>
            </div>
          </div>
          <div className="items">
            <div className="item">
              <img src={BitGet} alt="BitGet" />
              <p>BitGet</p>
            </div>
            <div className="item">
              <img src={MexcGlobal} alt="Mexc Global" />
              <p>Mexc Global</p>
            </div>
            <div className="item">
              <img src={Gateio} alt="Gate.io" />
              <p>Gate.io</p>
            </div>
            <div className="item">
              <img src={CoinBase} alt="CoinBase" />
              <p>CoinBase</p>
            </div>
          </div>
        </div>
      </div>
      {/* Features */}
      <div id="features" className="features">
        <div className="container">
          <SecTitle
            title={{
              one: t("Home.Features.title.one"),
              color: t("Home.Features.title.color"),
              last: "",
            }}
            text={t("Home.Features.title.txt")}
          />
          <Features />
        </div>
      </div>
      {/* Top Traders */}
      <div id="traders" className="top_traders">
        <div className="container">
          <SecTitle
            title={{
              one: t("Home.TopTraders.title.one"),
              color: t("Home.TopTraders.title.color"),
              last: t("Home.TopTraders.title.last"),
            }}
            text={t("Home.TopTraders.title.txt")}
          />
          <div className="items">
            <div className="item">
              <div className="picture">
                <img src={trader1} alt="Alexander Morgan" />
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.2178 3.0115C14.0659 2.28629 15.4549 2.28629 16.3153 3.0115L18.2574 4.68316C18.6261 5.00275 19.3145 5.26087 19.8061 5.26087L21.8957 5.26087C23.1986 5.26087 24.268 6.33025 24.268 7.63316V9.72275C24.268 10.2021 24.5261 10.9027 24.8457 11.2715L26.5174 13.2136C27.2426 14.0617 27.2426 15.4507 26.5174 16.3111L24.8457 18.2532C24.5261 18.6219 24.268 19.3102 24.268 19.8019V21.8915C24.268 23.1944 23.1986 24.2638 21.8957 24.2638H19.8061C19.3268 24.2638 18.6261 24.5219 18.2574 24.8415L16.3153 26.5132C15.4672 27.2384 14.0782 27.2384 13.2178 26.5132L11.2757 24.8415C10.907 24.5219 10.2186 24.2638 9.72698 24.2638H7.60052C6.2976 24.2638 5.22823 23.1944 5.22823 21.8915L5.22823 19.7896C5.22823 19.3102 4.9701 18.6219 4.66281 18.2532L3.00344 16.2988C2.29052 15.4507 2.29052 14.074 3.00344 13.2259L4.66281 11.2715C4.9701 10.9027 5.22823 10.2144 5.22823 9.73504V7.62087C5.22823 6.31795 6.2976 5.24858 7.60052 5.24858L9.72698 5.24858C10.2064 5.24858 10.907 4.99045 11.2757 4.67087L13.2178 3.0115Z"
                    fill="#5CC159"
                  />
                  <path
                    d="M13.2684 18.6465C13.0226 18.6465 12.789 18.5482 12.6169 18.3761L9.64234 15.4015C9.28589 15.0451 9.28589 14.4551 9.64234 14.0986C9.9988 13.7421 10.5888 13.7421 10.9453 14.0986L13.2684 16.4217L18.5538 11.1363C18.9103 10.7798 19.5003 10.7798 19.8567 11.1363C20.2132 11.4928 20.2132 12.0828 19.8567 12.4392L13.9198 18.3761C13.7478 18.5482 13.5142 18.6465 13.2684 18.6465Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="info">
                <h1>{t("Home.TopTraders.trader1.name")}</h1>
                <div className="nums">
                  <div className="num">
                    <div className="icon">
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 9.5C11.0711 9.5 12.75 7.82107 12.75 5.75C12.75 3.67893 11.0711 2 9 2C6.92893 2 5.25 3.67893 5.25 5.75C5.25 7.82107 6.92893 9.5 9 9.5Z"
                          stroke="#5CC159"
                          strokeWidth="1.125"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.4397 17C15.4397 14.0975 12.5522 11.75 8.99718 11.75C5.44218 11.75 2.55469 14.0975 2.55469 17"
                          stroke="#5CC159"
                          strokeWidth="1.125"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="txt">
                      <p>280k</p>
                      <span>{t("Home.TopTraders.trader1.followers")}</span>
                    </div>
                  </div>
                  <div className="num">
                    <div className="icon">
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2967 3.13251L11.6167 5.77251C11.7967 6.14001 12.2767 6.49251 12.6817 6.56001L15.0742 6.95751C16.6042 7.21251 16.9642 8.32251 15.8617 9.41751L14.0017 11.2775C13.6867 11.5925 13.5142 12.2 13.6117 12.635L14.1442 14.9375C14.5642 16.76 13.5967 17.465 11.9842 16.5125L9.7417 15.185C9.3367 14.945 8.6692 14.945 8.2567 15.185L6.0142 16.5125C4.4092 17.465 3.4342 16.7525 3.8542 14.9375L4.3867 12.635C4.4842 12.2 4.3117 11.5925 3.9967 11.2775L2.1367 9.41751C1.0417 8.32251 1.3942 7.21251 2.9242 6.95751L5.3167 6.56001C5.7142 6.49251 6.1942 6.14001 6.3742 5.77251L7.6942 3.13251C8.4142 1.70001 9.5842 1.70001 10.2967 3.13251Z"
                          stroke="#5CC159"
                          strokeWidth="1.2375"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="txt">
                      <p>5.0</p>
                      <span>{t("Home.TopTraders.trader1.ratings")}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="roi">
                <p>{t("Home.TopTraders.trader1.roi")}</p>
                <h3>70%</h3>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    width={200}
                    height={110}
                    data={data}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <Area
                      type="monotone"
                      dataKey="uv"
                      strokeWidth="2.5"
                      stroke="#5CC159"
                      fill="#5cc15961"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="item">
              <div className="picture">
                <img src={trader2} alt="Alexander Morgan" />
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.2178 3.0115C14.0659 2.28629 15.4549 2.28629 16.3153 3.0115L18.2574 4.68316C18.6261 5.00275 19.3145 5.26087 19.8061 5.26087L21.8957 5.26087C23.1986 5.26087 24.268 6.33025 24.268 7.63316V9.72275C24.268 10.2021 24.5261 10.9027 24.8457 11.2715L26.5174 13.2136C27.2426 14.0617 27.2426 15.4507 26.5174 16.3111L24.8457 18.2532C24.5261 18.6219 24.268 19.3102 24.268 19.8019V21.8915C24.268 23.1944 23.1986 24.2638 21.8957 24.2638H19.8061C19.3268 24.2638 18.6261 24.5219 18.2574 24.8415L16.3153 26.5132C15.4672 27.2384 14.0782 27.2384 13.2178 26.5132L11.2757 24.8415C10.907 24.5219 10.2186 24.2638 9.72698 24.2638H7.60052C6.2976 24.2638 5.22823 23.1944 5.22823 21.8915L5.22823 19.7896C5.22823 19.3102 4.9701 18.6219 4.66281 18.2532L3.00344 16.2988C2.29052 15.4507 2.29052 14.074 3.00344 13.2259L4.66281 11.2715C4.9701 10.9027 5.22823 10.2144 5.22823 9.73504V7.62087C5.22823 6.31795 6.2976 5.24858 7.60052 5.24858L9.72698 5.24858C10.2064 5.24858 10.907 4.99045 11.2757 4.67087L13.2178 3.0115Z"
                    fill="#5CC159"
                  />
                  <path
                    d="M13.2684 18.6465C13.0226 18.6465 12.789 18.5482 12.6169 18.3761L9.64234 15.4015C9.28589 15.0451 9.28589 14.4551 9.64234 14.0986C9.9988 13.7421 10.5888 13.7421 10.9453 14.0986L13.2684 16.4217L18.5538 11.1363C18.9103 10.7798 19.5003 10.7798 19.8567 11.1363C20.2132 11.4928 20.2132 12.0828 19.8567 12.4392L13.9198 18.3761C13.7478 18.5482 13.5142 18.6465 13.2684 18.6465Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="info">
                <h1>{t("Home.TopTraders.trader2.name")}</h1>
                <div className="nums">
                  <div className="num">
                    <div className="icon">
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 9.5C11.0711 9.5 12.75 7.82107 12.75 5.75C12.75 3.67893 11.0711 2 9 2C6.92893 2 5.25 3.67893 5.25 5.75C5.25 7.82107 6.92893 9.5 9 9.5Z"
                          stroke="#5CC159"
                          strokeWidth="1.125"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.4397 17C15.4397 14.0975 12.5522 11.75 8.99718 11.75C5.44218 11.75 2.55469 14.0975 2.55469 17"
                          stroke="#5CC159"
                          strokeWidth="1.125"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="txt">
                      <p>196k</p>
                      <span>{t("Home.TopTraders.trader1.followers")}</span>
                    </div>
                  </div>
                  <div className="num">
                    <div className="icon">
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2967 3.13251L11.6167 5.77251C11.7967 6.14001 12.2767 6.49251 12.6817 6.56001L15.0742 6.95751C16.6042 7.21251 16.9642 8.32251 15.8617 9.41751L14.0017 11.2775C13.6867 11.5925 13.5142 12.2 13.6117 12.635L14.1442 14.9375C14.5642 16.76 13.5967 17.465 11.9842 16.5125L9.7417 15.185C9.3367 14.945 8.6692 14.945 8.2567 15.185L6.0142 16.5125C4.4092 17.465 3.4342 16.7525 3.8542 14.9375L4.3867 12.635C4.4842 12.2 4.3117 11.5925 3.9967 11.2775L2.1367 9.41751C1.0417 8.32251 1.3942 7.21251 2.9242 6.95751L5.3167 6.56001C5.7142 6.49251 6.1942 6.14001 6.3742 5.77251L7.6942 3.13251C8.4142 1.70001 9.5842 1.70001 10.2967 3.13251Z"
                          stroke="#5CC159"
                          strokeWidth="1.2375"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="txt">
                      <p>5.0</p>
                      <span>{t("Home.TopTraders.trader1.ratings")}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="roi">
                <p>{t("Home.TopTraders.trader1.roi")}</p>
                <h3>80%</h3>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    width={200}
                    height={110}
                    data={data}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <Area
                      type="monotone"
                      dataKey="uv"
                      strokeWidth="2.5"
                      stroke="#5CC159"
                      fill="#5cc15961"
                    />
                  </AreaChart>
                </ResponsiveContainer>
                {/* <svg
                  width="277"
                  height="117"
                  viewBox="0 0 277 117"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.7965 35.915L2.49489 108.169C2.33247 108.72 2.25 109.292 2.25 109.866V110.5C2.25 113.814 4.93628 116.5 8.24999 116.5H265.314C268.628 116.5 271.314 113.814 271.314 110.5V1.5L249.369 54.9544C247.572 59.3318 241.652 60.0035 238.92 56.1399L229.582 42.9346C226.59 38.7036 220.003 39.9997 218.837 45.0489L212.773 71.3102C211.485 76.8926 203.852 77.6654 201.47 72.4547L197.187 63.0846C194.698 57.6379 186.642 58.799 185.791 64.727L181.479 94.7864C180.613 100.83 172.324 101.868 169.994 96.2241L162.058 77L151.074 45.0065C149.259 39.7183 141.835 39.5688 139.808 44.7796L127.024 77.644C125.713 81.0151 121.739 82.4682 118.563 80.7381L93.5683 67.1258C91.2971 65.8888 88.4888 66.2464 86.6 68.0131L67.0494 86.2996C64.0542 89.1012 59.1938 88.139 57.4918 84.4077L35.0106 35.1216C32.7062 30.0697 25.3667 30.589 23.7965 35.915Z"
                    fill="#5CC159"
                    fill-opacity="0.15"
                  />
                  <path
                    d="M2.25 109L23.7965 35.915C25.3667 30.589 32.7062 30.0698 35.0106 35.1216L57.4918 84.4077C59.1938 88.139 64.0542 89.1012 67.0494 86.2996L86.6 68.0131C88.4888 66.2464 91.2971 65.8888 93.5683 67.1258L118.563 80.7381C121.739 82.4682 125.713 81.0151 127.024 77.644L139.808 44.7796C141.835 39.5688 149.259 39.7183 151.074 45.0065L162.058 77L169.994 96.2241C172.324 101.868 180.613 100.83 181.479 94.7864L185.791 64.727C186.642 58.799 194.698 57.6379 197.187 63.0846L201.47 72.4547C203.852 77.6654 211.485 76.8926 212.773 71.3102L218.837 45.0489C220.003 39.9997 226.59 38.7036 229.582 42.9346L238.92 56.1399C241.652 60.0035 247.572 59.3318 249.369 54.9544L271.314 1.5"
                    stroke="#5CC159"
                    strokeWidth="3"
                  />
                  <ellipse
                    cx="269.141"
                    cy="7.5"
                    rx="7.6099"
                    ry="7"
                    fill="#5CC159"
                  />
                </svg> */}
              </div>
            </div>
            <div className="item">
              <div className="picture">
                <img src={trader3} alt="Alexander Morgan" />
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.2178 3.0115C14.0659 2.28629 15.4549 2.28629 16.3153 3.0115L18.2574 4.68316C18.6261 5.00275 19.3145 5.26087 19.8061 5.26087L21.8957 5.26087C23.1986 5.26087 24.268 6.33025 24.268 7.63316V9.72275C24.268 10.2021 24.5261 10.9027 24.8457 11.2715L26.5174 13.2136C27.2426 14.0617 27.2426 15.4507 26.5174 16.3111L24.8457 18.2532C24.5261 18.6219 24.268 19.3102 24.268 19.8019V21.8915C24.268 23.1944 23.1986 24.2638 21.8957 24.2638H19.8061C19.3268 24.2638 18.6261 24.5219 18.2574 24.8415L16.3153 26.5132C15.4672 27.2384 14.0782 27.2384 13.2178 26.5132L11.2757 24.8415C10.907 24.5219 10.2186 24.2638 9.72698 24.2638H7.60052C6.2976 24.2638 5.22823 23.1944 5.22823 21.8915L5.22823 19.7896C5.22823 19.3102 4.9701 18.6219 4.66281 18.2532L3.00344 16.2988C2.29052 15.4507 2.29052 14.074 3.00344 13.2259L4.66281 11.2715C4.9701 10.9027 5.22823 10.2144 5.22823 9.73504V7.62087C5.22823 6.31795 6.2976 5.24858 7.60052 5.24858L9.72698 5.24858C10.2064 5.24858 10.907 4.99045 11.2757 4.67087L13.2178 3.0115Z"
                    fill="#5CC159"
                  />
                  <path
                    d="M13.2684 18.6465C13.0226 18.6465 12.789 18.5482 12.6169 18.3761L9.64234 15.4015C9.28589 15.0451 9.28589 14.4551 9.64234 14.0986C9.9988 13.7421 10.5888 13.7421 10.9453 14.0986L13.2684 16.4217L18.5538 11.1363C18.9103 10.7798 19.5003 10.7798 19.8567 11.1363C20.2132 11.4928 20.2132 12.0828 19.8567 12.4392L13.9198 18.3761C13.7478 18.5482 13.5142 18.6465 13.2684 18.6465Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="info">
                <h1>{t("Home.TopTraders.trader2.name")}</h1>
                <div className="nums">
                  <div className="num">
                    <div className="icon">
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 9.5C11.0711 9.5 12.75 7.82107 12.75 5.75C12.75 3.67893 11.0711 2 9 2C6.92893 2 5.25 3.67893 5.25 5.75C5.25 7.82107 6.92893 9.5 9 9.5Z"
                          stroke="#5CC159"
                          strokeWidth="1.125"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.4397 17C15.4397 14.0975 12.5522 11.75 8.99718 11.75C5.44218 11.75 2.55469 14.0975 2.55469 17"
                          stroke="#5CC159"
                          strokeWidth="1.125"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="txt">
                      <p>140k</p>
                      <span>{t("Home.TopTraders.trader1.followers")}</span>
                    </div>
                  </div>
                  <div className="num">
                    <div className="icon">
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2967 3.13251L11.6167 5.77251C11.7967 6.14001 12.2767 6.49251 12.6817 6.56001L15.0742 6.95751C16.6042 7.21251 16.9642 8.32251 15.8617 9.41751L14.0017 11.2775C13.6867 11.5925 13.5142 12.2 13.6117 12.635L14.1442 14.9375C14.5642 16.76 13.5967 17.465 11.9842 16.5125L9.7417 15.185C9.3367 14.945 8.6692 14.945 8.2567 15.185L6.0142 16.5125C4.4092 17.465 3.4342 16.7525 3.8542 14.9375L4.3867 12.635C4.4842 12.2 4.3117 11.5925 3.9967 11.2775L2.1367 9.41751C1.0417 8.32251 1.3942 7.21251 2.9242 6.95751L5.3167 6.56001C5.7142 6.49251 6.1942 6.14001 6.3742 5.77251L7.6942 3.13251C8.4142 1.70001 9.5842 1.70001 10.2967 3.13251Z"
                          stroke="#5CC159"
                          strokeWidth="1.2375"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="txt">
                      <p>5.0</p>
                      <span>{t("Home.TopTraders.trader1.ratings")}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="roi">
                <p>{t("Home.TopTraders.trader1.roi")}</p>
                <h3>40%</h3>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    width={200}
                    height={110}
                    data={data}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <Area
                      type="monotone"
                      dataKey="uv"
                      strokeWidth="2.5"
                      stroke="#5CC159"
                      fill="#5cc15961"
                    />
                  </AreaChart>
                </ResponsiveContainer>
                {/* <svg
                  width="277"
                  height="117"
                  viewBox="0 0 277 117"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.7965 35.915L2.49489 108.169C2.33247 108.72 2.25 109.292 2.25 109.866V110.5C2.25 113.814 4.93628 116.5 8.24999 116.5H265.314C268.628 116.5 271.314 113.814 271.314 110.5V1.5L249.369 54.9544C247.572 59.3318 241.652 60.0035 238.92 56.1399L229.582 42.9346C226.59 38.7036 220.003 39.9997 218.837 45.0489L212.773 71.3102C211.485 76.8926 203.852 77.6654 201.47 72.4547L197.187 63.0846C194.698 57.6379 186.642 58.799 185.791 64.727L181.479 94.7864C180.613 100.83 172.324 101.868 169.994 96.2241L162.058 77L151.074 45.0065C149.259 39.7183 141.835 39.5688 139.808 44.7796L127.024 77.644C125.713 81.0151 121.739 82.4682 118.563 80.7381L93.5683 67.1258C91.2971 65.8888 88.4888 66.2464 86.6 68.0131L67.0494 86.2996C64.0542 89.1012 59.1938 88.139 57.4918 84.4077L35.0106 35.1216C32.7062 30.0697 25.3667 30.589 23.7965 35.915Z"
                    fill="#5CC159"
                    fill-opacity="0.15"
                  />
                  <path
                    d="M2.25 109L23.7965 35.915C25.3667 30.589 32.7062 30.0698 35.0106 35.1216L57.4918 84.4077C59.1938 88.139 64.0542 89.1012 67.0494 86.2996L86.6 68.0131C88.4888 66.2464 91.2971 65.8888 93.5683 67.1258L118.563 80.7381C121.739 82.4682 125.713 81.0151 127.024 77.644L139.808 44.7796C141.835 39.5688 149.259 39.7183 151.074 45.0065L162.058 77L169.994 96.2241C172.324 101.868 180.613 100.83 181.479 94.7864L185.791 64.727C186.642 58.799 194.698 57.6379 197.187 63.0846L201.47 72.4547C203.852 77.6654 211.485 76.8926 212.773 71.3102L218.837 45.0489C220.003 39.9997 226.59 38.7036 229.582 42.9346L238.92 56.1399C241.652 60.0035 247.572 59.3318 249.369 54.9544L271.314 1.5"
                    stroke="#5CC159"
                    strokeWidth="3"
                  />
                  <ellipse
                    cx="269.141"
                    cy="7.5"
                    rx="7.6099"
                    ry="7"
                    fill="#5CC159"
                  />
                </svg> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Subscriptions */}
      <div id="pricing" className="subscriptions">
        <div className="container">
          <SecTitle
            title={{
              one: t("Home.Subscriptions.title.one"),
              color: "",
              last: "",
            }}
            text={t("Home.Subscriptions.title.txt")}
          />
          <Plans />
        </div>
      </div>
      {/* Questions */}
      <div id="faq" className="questions">
        <div className="container">
          <SecTitle
            title={{
              one: t("Home.faq.title.one"),
              color: t("Home.faq.title.color"),
              last: t("Home.faq.title.last"),
            }}
            text={t("Home.faq.title.txt")}
          />
          <Questions />
        </div>
      </div>
      {/* Testimonials */}
      <div className="testimonials">
        <div className="container">
          <SecTitle
            title={{
              one: t("Home.Testimonials.title.one"),
              color: "",
              last: "",
            }}
            text={t("Home.Testimonials.title.txt")}
          />
          <Testimonials />
        </div>
      </div>
      {/* Referral */}
      <div id="referrals" className="container">
        <div className="referral">
          <h1>
            {t("Home.Referral.title.one")}
            <span> {t("Home.Referral.title.color")}</span>
            {t("Home.Referral.title.last")}
          </h1>
          <p>{t("Home.Referral.txt")}</p>
          <Link to="/sign-in">{t("Home.Referral.btn")}</Link>
          <img src={referral} alt="" />
        </div>
      </div>
      {/* Footer */}
      <footer>
        <div className="container">
          <ul className="list-unstyled">
            <li>
              <a href="/" onClick={(e) => goSec(e, "brokers")}>
                {t("Home.header.link1")}
              </a>
            </li>
            <li>
              <a href="/" onClick={(e) => goSec(e, "features")}>
                {t("Home.header.link2")}
              </a>
            </li>
            <li>
              <a href="/" onClick={(e) => goSec(e, "traders")}>
                {t("Home.header.link3")}
              </a>
            </li>
            <li>
              <a href="/" onClick={(e) => goSec(e, "pricing")}>
                {t("Home.header.link4")}
              </a>
            </li>
            <li>
              <a href="/" onClick={(e) => goSec(e, "faq")}>
                {t("Home.header.link5")}
              </a>
            </li>
            <li>
              <a href="/" onClick={(e) => goSec(e, "referrals")}>
                {t("Home.header.link6")}
              </a>
            </li>
            <li>
              <Link to="/terms-of-use">{t("Home.footer.linkfot1")}</Link>
            </li>
            <li>
              <Link to="/privacy-policy">{t("Home.footer.linkfot2")}</Link>
            </li>
          </ul>
          <div className="lst_prt">
            <div className="social">
              <a
                href="https://twitter.com/insta_signal"
                rel="noreferrer"
                target="_blank"
              >
                <svg
                  width="26"
                  height="27"
                  viewBox="0 0 26 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_2_21659)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.43289 23.7998C18.0823 23.7998 23.3603 15.9283 23.3603 9.10299C23.3603 8.87891 23.3603 8.65653 23.345 8.43498C24.3717 7.70486 25.2577 6.79833 25.9622 5.76107C25.0058 6.179 23.9894 6.45308 22.9499 6.57392C24.0443 5.92815 24.8639 4.9136 25.2564 3.71647C24.2259 4.31818 23.0995 4.74285 21.9245 4.9707C19.9375 2.89113 16.6145 2.79038 14.501 4.74658C13.1393 6.00791 12.5601 7.88853 12.9834 9.68234C8.76532 9.47338 4.83497 7.51218 2.17041 4.28584C0.778035 6.64611 1.4902 9.66444 3.79548 11.1801C2.96057 11.1561 2.14356 10.9348 1.41349 10.5345V10.6001C1.41477 13.0585 3.17537 15.1754 5.62257 15.6626C4.85031 15.8703 4.03969 15.9004 3.25464 15.7506C3.94124 17.8553 5.91153 19.2969 8.15544 19.3385C6.29766 20.7761 4.00261 21.5567 1.6398 21.5542C1.22298 21.5529 0.806163 21.5289 0.390625 21.4799C2.79052 22.9955 5.58166 23.7998 8.43289 23.796"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2_21659">
                      <rect
                        width="25.5716"
                        height="25.5716"
                        fill="white"
                        transform="translate(0.398438 0.785645)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a
                href="https://www.youtube.com/channel/UCq-Sn-BFK9pmwxoHrD-Xuhw"
                rel="noreferrer"
                target="_blank"
              >
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_2_21666)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.7607 16.8777V9.70255C13.3079 10.9011 15.2807 12.059 17.6139 13.3073C15.6895 14.3745 13.3079 15.572 10.7607 16.8777ZM24.9562 6.13402C24.5169 5.55513 23.768 5.10451 22.9707 4.95533C20.6273 4.51032 6.00767 4.50905 3.66552 4.95533C3.02618 5.07518 2.45686 5.36488 1.9678 5.815C-0.0928782 7.72762 0.552841 17.9844 1.04954 19.6459C1.25841 20.365 1.52843 20.8837 1.86848 21.2241C2.3066 21.6742 2.90645 21.9841 3.59547 22.1231C5.52497 22.5222 15.4654 22.7454 22.9299 22.1831C23.6177 22.0632 24.2265 21.7433 24.7066 21.2741C26.6119 19.3691 26.482 8.53627 24.9562 6.13402Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2_21666">
                      <rect
                        width="25.5716"
                        height="25.5716"
                        fill="white"
                        transform="translate(0.539062 0.785645)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a
                href="https://www.instagram.com/insta_signal_automation/"
                rel="noreferrer"
                target="_blank"
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5547 2.86914H8.82552C5.03385 2.86914 2.77344 5.12956 2.77344 8.92122V17.64C2.77344 21.4421 5.03385 23.7025 8.82552 23.7025H17.5443C21.3359 23.7025 23.5964 21.4421 23.5964 17.6504V8.92122C23.6068 5.12956 21.3464 2.86914 17.5547 2.86914ZM13.1901 17.3275C10.9609 17.3275 9.14844 15.515 9.14844 13.2858C9.14844 11.0566 10.9609 9.24414 13.1901 9.24414C15.4193 9.24414 17.2318 11.0566 17.2318 13.2858C17.2318 15.515 15.4193 17.3275 13.1901 17.3275ZM19.3568 7.95247C19.3047 8.07747 19.2318 8.19206 19.138 8.29622C19.0339 8.38997 18.9193 8.46289 18.7943 8.51497C18.6693 8.56706 18.5339 8.59831 18.3984 8.59831C18.1172 8.59831 17.8568 8.49414 17.6589 8.29622C17.5651 8.19206 17.4922 8.07747 17.4401 7.95247C17.388 7.82747 17.3568 7.69206 17.3568 7.55664C17.3568 7.42122 17.388 7.28581 17.4401 7.16081C17.4922 7.02539 17.5651 6.92122 17.6589 6.81706C17.8984 6.57747 18.263 6.46289 18.5964 6.53581C18.6693 6.54622 18.7318 6.56706 18.7943 6.59831C18.8568 6.61914 18.9193 6.65039 18.9818 6.69206C19.0339 6.72331 19.0859 6.77539 19.138 6.81706C19.2318 6.92122 19.3047 7.02539 19.3568 7.16081C19.4089 7.28581 19.4401 7.42122 19.4401 7.55664C19.4401 7.69206 19.4089 7.82747 19.3568 7.95247Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a
                href="https://t.me/+PP3vWOlN23w1OWRk"
                rel="noreferrer"
                target="_blank"
              >
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.2115 7.98919C17.2163 7.98919 17.2227 7.98919 17.2291 7.98919C17.3961 7.98919 17.5511 8.04113 17.6782 8.13063L17.6758 8.12903C17.7685 8.20973 17.8308 8.3232 17.846 8.45105V8.45345C17.862 8.55093 17.8707 8.6628 17.8707 8.77707C17.8707 8.82901 17.8692 8.88015 17.8652 8.93129V8.9241C17.6854 10.8171 16.9055 15.4077 16.5091 17.5268C16.3413 18.4242 16.0113 18.7246 15.6917 18.7534C14.9973 18.8181 14.4699 18.2947 13.7971 17.8536C12.744 17.1624 12.1487 16.7325 11.1267 16.0589C9.94485 15.2814 10.7111 14.8523 11.384 14.154C11.5606 13.9702 14.6218 11.1854 14.6817 10.9329C14.6849 10.9178 14.6865 10.901 14.6865 10.8834C14.6865 10.8211 14.6633 10.7643 14.6258 10.7212C14.5842 10.694 14.5323 10.6788 14.4779 10.6788C14.442 10.6788 14.4076 10.686 14.3756 10.698L14.3772 10.6972C14.2718 10.7212 12.5895 11.833 9.33037 14.0325C8.97479 14.313 8.52571 14.4904 8.03669 14.5111H8.03189C7.33911 14.4272 6.71024 14.273 6.11334 14.0533L6.17247 14.0725C5.42295 13.8279 4.82765 13.6993 4.87879 13.2854C4.90596 13.0702 5.20268 12.8499 5.76895 12.6246C9.25712 11.1047 11.5832 10.103 12.7472 9.61928C14.0313 8.93768 15.5199 8.37674 17.0853 8.01316L17.2107 7.98839L17.2115 7.98919ZM12.2637 0.785645C5.62991 0.801626 0.257812 6.18331 0.257812 12.8195C0.257812 19.4653 5.64509 24.8534 12.2917 24.8534C18.9383 24.8534 24.3256 19.4661 24.3256 12.8195C24.3256 6.18331 18.9535 0.801626 12.3213 0.785645H12.3197C12.301 0.785645 12.2824 0.785645 12.2637 0.785645Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a
                href="https://www.facebook.com/people/Insta-Signal/100093620508927/"
                rel="noreferrer"
                target="_blank"
              >
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.3396 18.0348C24.3396 21.9129 22.0276 24.2248 18.1495 24.2248H16.8817C16.2957 24.2248 15.8162 23.7454 15.8162 23.1594V17.012C15.8162 16.7243 16.0506 16.4793 16.3383 16.4793L18.2134 16.4473C18.3626 16.4367 18.4905 16.3301 18.5224 16.181L18.8953 14.146C18.9273 13.9542 18.7781 13.7731 18.5757 13.7731L16.3063 13.8051C16.008 13.8051 15.7736 13.5707 15.763 13.283L15.7204 10.6727C15.7204 10.5023 15.8589 10.3531 16.04 10.3531L18.597 10.3105C18.7781 10.3105 18.9166 10.172 18.9166 9.99089L18.874 7.43386C18.874 7.25274 18.7355 7.11425 18.5544 7.11425L15.6777 7.15687C13.9091 7.18884 12.5028 8.63779 12.5348 10.4064L12.588 13.3363C12.5987 13.6346 12.3643 13.869 12.066 13.8797L10.7875 13.9009C10.6064 13.9009 10.4679 14.0394 10.4679 14.2206L10.4998 16.2449C10.4998 16.426 10.6383 16.5645 10.8194 16.5645L12.098 16.5432C12.3963 16.5432 12.6306 16.7776 12.6413 17.0652L12.7372 23.1381C12.7478 23.7348 12.2684 24.2248 11.6718 24.2248H9.22132C5.3432 24.2248 3.03125 21.9129 3.03125 18.0241V9.10657C3.03125 5.22846 5.3432 2.9165 9.22132 2.9165H18.1495C22.0276 2.9165 24.3396 5.22846 24.3396 9.10657V18.0348Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a
                href="https://www.tiktok.com/@instasignal"
                rel="noreferrer"
                target="_blank"
              >
                <svg
                  width="26"
                  height="27"
                  viewBox="0 0 26 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.4148 2.9165H3.23729C2.95473 2.9165 2.68373 3.02875 2.48393 3.22856C2.28412 3.42836 2.17188 3.69935 2.17188 3.98192V23.1594C2.17188 23.442 2.28412 23.713 2.48393 23.9128C2.68373 24.1126 2.95473 24.2248 3.23729 24.2248H22.4148C22.6974 24.2248 22.9684 24.1126 23.1682 23.9128C23.368 23.713 23.4802 23.442 23.4802 23.1594V3.98192C23.4802 3.69935 23.368 3.42836 23.1682 3.22856C22.9684 3.02875 22.6974 2.9165 22.4148 2.9165ZM18.9192 12.2123C18.803 12.224 18.6858 12.2293 18.5697 12.2304C17.9376 12.2312 17.3152 12.0745 16.7589 11.7745C16.2025 11.4744 15.7296 11.0405 15.383 10.5119V16.3642C15.3833 17.22 15.1297 18.0567 14.6543 18.7684C14.179 19.48 13.5033 20.0348 12.7126 20.3624C11.922 20.69 11.0519 20.7757 10.2126 20.6088C9.37317 20.4419 8.60213 20.0298 7.99698 19.4247C7.39182 18.8195 6.97972 18.0485 6.81281 17.2091C6.6459 16.3697 6.73167 15.4997 7.05928 14.709C7.38689 13.9184 7.94161 13.2427 8.65329 12.7673C9.36497 12.292 10.2016 12.0384 11.0575 12.0386C11.148 12.0386 11.2354 12.0471 11.3249 12.0525V14.1833C11.0268 14.1472 10.7245 14.1724 10.4365 14.2574C10.1485 14.3425 9.88098 14.4855 9.6503 14.6777C9.41963 14.8699 9.23069 15.1072 9.09512 15.3752C8.95954 15.6431 8.88018 15.9359 8.86192 16.2356C8.84365 16.5353 8.88687 16.8356 8.98892 17.1179C9.09096 17.4003 9.24967 17.6589 9.4553 17.8777C9.66093 18.0965 9.90913 18.2709 10.1847 18.3903C10.4602 18.5096 10.7572 18.5714 11.0575 18.5717C11.65 18.5825 12.2231 18.3602 12.6535 17.9527C13.0838 17.5453 13.3371 16.9852 13.3588 16.393L13.3801 6.45156H15.4193C15.5124 7.3374 15.9139 8.16232 16.5537 8.78205C17.1935 9.40178 18.0308 9.77686 18.9192 9.84171V12.2123Z"
                    fill="white"
                  />
                </svg>
              </a>
            </div>
            <h3>{t("Home.footer.last")}</h3>
          </div>
        </div>
      </footer>
      {/* Scroll to top */}
      <div
        className={`scroll_top ${toTop ? "active" : ""}`}
        onClick={() => scrollTop()}
      >
        <svg
          width="25px"
          height="25px"
          viewBox="0 0 18 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.63775 0.896789C9.01196 0.896789 9.38618 1.03466 9.68161 1.33009L16.6341 8.2826C17.2053 8.85377 17.2053 9.79916 16.6341 10.3703C16.063 10.9415 15.1176 10.9415 14.5464 10.3703L8.63775 4.46168L2.7291 10.3703C2.15793 10.9415 1.21254 10.9415 0.641373 10.3703C0.0702029 9.79916 0.0702029 8.85377 0.641373 8.2826L7.59389 1.33009C7.88932 1.03466 8.26353 0.896789 8.63775 0.896789Z"
            fill="#FFF"
          ></path>
        </svg>
      </div>
    </div>
  );
}

export default Waitlist;
