import executeRequest from "../utils/apiService";

const telegramService = {
  /**
   * Telegram Channel Add
   * @param {String} exchangeId
   * @param {Number} maxTradesPerDay
   * @param {Number} maxProfitPerDay
   * @param {Number} maxLossPerDay
   * @param {Number} tradeAmount
   * @param {String} tradeAmountMode will be  "fixed" OR "percentage"
   * @param {String} tradeSignalMarket  could be "spot" OR "futures"
   * @param {Boolean} isIsolated
   * @param {Function} onSuccess
   * @param {Function} onFailure
   */

  // Add Channel
  async addChannel({
    exchangeId,
    maxTradesPerDay,
    maxProfitPerDay,
    maxLossPerDay,
    tradeAmount,
    tradeAmountMode,
    tradeSignalMarket,
    isIsolated,
    onSuccess,
    onFailure,
  }) {
    return executeRequest({
      method: "POST",
      url: "/api/Telegram/me/channel",
      data: {
        exchangeId,
        maxTradesPerDay,
        maxProfitPerDay,
        maxLossPerDay,
        tradeAmount,
        tradeAmountMode,
        tradeSignalMarket,
        isIsolated,
      },
    })
      .then(onSuccess)
      .catch(onFailure);
  },

  verifySignal(signal, onSuccess, onFailure) {
    return executeRequest({
      method: "POST",
      url: "/api/Signal/verify", // TODO: backend not ready
      data: { signal },
    })
      .then(onSuccess)
      .catch(onFailure);
  },

  followChannel(channelId, onSuccess, onFailure) {
    return executeRequest({
      method: "POST",
      url: `/api/Telegram/me/channel/status/${channelId}`,
    })
      .then(onSuccess)
      .catch(onFailure);
  },
  unfollowChannel(channelId, onSuccess, onFailure) {
    return executeRequest({
      method: "DELETE",
      url: `/api/Telegram/me/channel/status/${channelId}`,
    })
      .then(onSuccess)
      .catch(onFailure);
  },

  getFollowedChannels(onSuccess, onFailure) {
    return executeRequest({
      method: "GET",
      url: "/api/Telegram/me/channel",
    })
      .then(onSuccess)
      .catch(onFailure);
  },

  getTelegramChannels(onSuccess, onFailure) {
    return executeRequest({
      method: "GET",
      url: "/api/Telegram/channel",
    })
      .then(onSuccess)
      .catch(onFailure);
  },

  getTelegramConnectStatus(onSuccess, onFailure) {
    return executeRequest({
      method: "GET",
      url: "/api/Telegram/status",
    })
      .then(onSuccess)
      .catch(onFailure);
  },

  configureTelegramChannel(
    {
      exchangeId,
      channelId,
      maxTradesPerDay,
      maxProfitPerDay,
      maxLossPerDay,
      tradeAmount,
      tradeAmountMode,
      tradeSignalMarket,
      isIsolated,
    },
    onSuccess,
    onFailure
  ) {
    return executeRequest({
      method: "POST",
      url: "/api/Telegram/me/channel",
      data: {
        exchangeId,
        channelId,
        maxTradesPerDay,
        maxProfitPerDay,
        maxLossPerDay,
        tradeAmount,
        tradeAmountMode,
        tradeSignalMarket,
        isIsolated,
      },
    })
      .then(onSuccess)
      .catch(onFailure);
  },
  
  loginWithQrCode(onSuccess, onFailure) {
    return executeRequest({
      method: "POST",
      url: "/api/Telegram/loginWithQr",
    })
      .then(onSuccess)
      .catch(onFailure);
  },
  loginWithPhoneNumber(phone, code, onSuccess, onFailure) {
    return executeRequest({
      method: "POST",
      url: "/api/Telegram/loginWithPhoneNumber",
      data: { phone, code },
    })
      .then(onSuccess)
      .catch(onFailure);
  },

  LogoutTelegramConnect(onSuccess, onFailure) {
    return executeRequest({
      method: "POST",
      url: "/api/telegram/logout",
    })
      .then(onSuccess)
      .catch(onFailure);
  },
};

export default telegramService;
