import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Features() {
  const [t] = useTranslation();
  return (
    <div className="features_items">
      <div className="item">
        <div className="icon">
          <svg
            width="38"
            height="37"
            viewBox="0 0 38 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35.7898 23.4856C35.1637 23.4856 34.6445 24.0048 34.6445 24.6309C34.6445 29.1511 31.4987 32.9382 27.2687 33.9308L27.681 33.2437C28.0017 32.6939 27.8337 31.9914 27.284 31.6708C26.7495 31.3501 26.0317 31.5181 25.7111 32.0678L24.1076 34.7402C23.8938 35.0914 23.8938 35.5343 24.0923 35.8855C24.2909 36.252 24.6726 36.4658 25.085 36.4658C31.6056 36.4658 36.9198 31.1516 36.9198 24.6309C36.9351 24.0048 36.4159 23.4856 35.7898 23.4856Z"
              fill="white"
            />
            <path
              d="M12.8896 0.579346C6.36894 0.579346 1.05469 5.8936 1.05469 12.4142C1.05469 13.0403 1.5739 13.5596 2.2 13.5596C2.8261 13.5596 3.34531 13.0403 3.34531 12.4142C3.34531 7.89407 6.4911 4.10691 10.7211 3.1143L10.3088 3.80149C9.98812 4.35124 10.1561 5.0537 10.7059 5.37439C11.2403 5.69508 11.9581 5.5271 12.2788 4.97735L13.8822 2.30495C14.0807 1.95372 14.096 1.52614 13.8822 1.15964C13.6837 0.808408 13.3019 0.579346 12.8896 0.579346Z"
              fill="white"
            />
            <path
              d="M33.5111 13.4831C33.5111 18.1866 30.0904 22.0653 25.6161 22.7831C25.6161 22.7678 25.6161 22.7678 25.6161 22.7525C25.1732 17.0565 20.5614 12.4142 14.7891 11.9561C15.5068 7.4817 19.3856 4.06104 24.089 4.06104C29.2964 4.06104 33.5111 8.27579 33.5111 13.4831Z"
              fill="white"
            />
            <path
              d="M15.648 21.9282C15.648 21.6991 15.3884 21.3479 15.0677 21.3479H11.9219V22.4932H15.0677C15.3884 22.5085 15.648 22.2489 15.648 21.9282Z"
              fill="white"
            />
            <path
              d="M15.6785 24.7991H15.0677H11.9219V25.9444H15.6938C16.1824 25.9444 16.5184 25.639 16.5184 25.3641C16.5184 25.0892 16.1672 24.7991 15.6785 24.7991Z"
              fill="white"
            />
            <path
              d="M23.3211 22.9204C22.9699 18.2934 19.2744 14.5978 14.6473 14.2466C14.403 14.2313 14.1739 14.2161 13.9143 14.2161C8.70694 14.2161 4.49219 18.4308 4.49219 23.6534C4.49219 28.8608 8.70694 33.0755 13.9143 33.0755C19.1216 33.0755 23.3517 28.8608 23.3517 23.6534C23.3517 23.3938 23.3364 23.1648 23.3211 22.9204ZM15.6857 28.2347H14.6778V28.815C14.6778 29.4411 14.1586 29.9603 13.5325 29.9603C12.9064 29.9603 12.3872 29.4411 12.3872 28.815V28.2347H10.7838C10.1577 28.2347 9.63846 27.7155 9.63846 27.0894V23.6534V20.2175C9.63846 19.5914 10.1577 19.0722 10.7838 19.0722H12.3872V18.4919C12.3872 17.8658 12.9064 17.3466 13.5325 17.3466C14.1586 17.3466 14.6778 17.8658 14.6778 18.4919V19.0722H15.0749C16.602 19.0722 17.9458 20.416 17.9458 21.9431C17.9458 22.386 17.8389 22.7983 17.6556 23.18C18.3581 23.6993 18.801 24.4933 18.801 25.379C18.801 26.9519 17.4113 28.2347 15.6857 28.2347Z"
              fill="white"
            />
          </svg>
        </div>
        <h3>{t("Home.Features.feat1.title")}</h3>
        <p>{t("Home.Features.feat1.text")}</p>
        <Link to="/sign-up">
          {t("Home.Features.feat1.btn")}
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 11.0289L21.7 2.82886"
              stroke="#5CC159"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.5031 6.82881V2.02881H17.7031"
              stroke="#5CC159"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.5 2.02881H9.5C4.5 2.02881 2.5 4.02881 2.5 9.02881V15.0288C2.5 20.0288 4.5 22.0288 9.5 22.0288H15.5C20.5 22.0288 22.5 20.0288 22.5 15.0288V13.0288"
              stroke="#5CC159"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      </div>
      <div className="item">
        <div className="icon">
          <svg
            width="38"
            height="37"
            viewBox="0 0 38 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.9974 24.2187V24.3867C18.9974 24.8143 18.6614 25.1502 18.2339 25.1502H4.4901C4.06252 25.1502 3.72656 24.8143 3.72656 24.3867V24.2187C3.72656 21.531 4.39848 20.8591 7.11669 20.8591H15.6073C18.3255 20.8591 18.9974 21.531 18.9974 24.2187Z"
              fill="white"
            />
            <path
              d="M4.4901 26.6772C4.06252 26.6772 3.72656 27.0132 3.72656 27.4408V28.9679V30.4797C3.72656 33.1674 4.39848 33.8393 7.11669 33.8393H15.6073C18.3255 33.8393 18.9974 33.1674 18.9974 30.4797V28.9679V27.4408C18.9974 27.0132 18.6614 26.6772 18.2339 26.6772H4.4901Z"
              fill="white"
            />
            <path
              d="M34.2677 23.1497C34.2677 29.0595 29.4879 33.8392 23.5781 33.8392L25.1816 31.1668"
              stroke="white"
              strokeWidth="2.29063"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.72656 13.9872C3.72656 8.07738 8.50633 3.29761 14.4161 3.29761L12.8127 5.97"
              stroke="white"
              strokeWidth="2.29063"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M28.9266 17.0414C32.7218 17.0414 35.7984 13.9647 35.7984 10.1695C35.7984 6.37425 32.7218 3.29761 28.9266 3.29761C25.1313 3.29761 22.0547 6.37425 22.0547 10.1695C22.0547 13.9647 25.1313 17.0414 28.9266 17.0414Z"
              fill="white"
            />
          </svg>
        </div>
        <h3>{t("Home.Features.feat2.title")}</h3>
        <p>{t("Home.Features.feat2.text")}</p>
        <Link to="/sign-up">
          {t("Home.Features.feat2.btn")}
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 11.0289L21.7 2.82886"
              stroke="#5CC159"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.5031 6.82881V2.02881H17.7031"
              stroke="#5CC159"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.5 2.02881H9.5C4.5 2.02881 2.5 4.02881 2.5 9.02881V15.0288C2.5 20.0288 4.5 22.0288 9.5 22.0288H15.5C20.5 22.0288 22.5 20.0288 22.5 15.0288V13.0288"
              stroke="#5CC159"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      </div>
      <div className="item">
        <div className="icon">
          <svg
            width="38"
            height="37"
            viewBox="0 0 38 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.49429 17.683L4.83717 15.0259L2.19531 17.683"
              stroke="white"
              strokeWidth="2.29063"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M30.5 19.4543L33.1571 22.1115L35.8143 19.4543"
              stroke="white"
              strokeWidth="2.29063"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M33.1347 22.1119V18.569C33.1347 10.7504 26.7973 4.42822 18.9939 4.42822C14.5349 4.42822 10.5492 6.5051 7.95312 9.72725"
              stroke="white"
              strokeWidth="2.29063"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.85938 15.0259V18.5687C4.85938 26.3874 11.1968 32.7095 19.0002 32.7095C23.4593 32.7095 27.4449 30.6326 30.041 27.4105"
              stroke="white"
              strokeWidth="2.29063"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.4141 13.2239H21.1027C22.584 13.2239 23.7751 14.5677 23.7751 15.8963C23.7751 17.3775 22.584 18.5687 21.1027 18.5687H14.4141V13.2239Z"
              stroke="white"
              strokeWidth="2.29063"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.4141 18.5686H22.0495C23.7293 18.5686 25.1036 19.7597 25.1036 21.241C25.1036 22.7223 23.7293 23.9134 22.0495 23.9134H14.4141V18.5686Z"
              stroke="white"
              strokeWidth="2.29063"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.6875 23.9136V26.586"
              stroke="white"
              strokeWidth="2.29063"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.6875 10.5515V13.2239"
              stroke="white"
              strokeWidth="2.29063"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <h3>{t("Home.Features.feat3.title")}</h3>
        <p>{t("Home.Features.feat3.text")}</p>
        <Link to="/sign-up">
          {t("Home.Features.feat3.btn")}
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 11.0289L21.7 2.82886"
              stroke="#5CC159"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.5031 6.82881V2.02881H17.7031"
              stroke="#5CC159"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.5 2.02881H9.5C4.5 2.02881 2.5 4.02881 2.5 9.02881V15.0288C2.5 20.0288 4.5 22.0288 9.5 22.0288H15.5C20.5 22.0288 22.5 20.0288 22.5 15.0288V13.0288"
              stroke="#5CC159"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      </div>
      <div className="item">
        <div className="icon">
          <svg
            width="38"
            height="37"
            viewBox="0 0 38 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.3959 3.29785H12.5989C7.04033 3.29785 3.72656 6.61162 3.72656 12.1702V24.9519C3.72656 30.5257 7.04033 33.8395 12.5989 33.8395H25.3806C30.9392 33.8395 34.253 30.5257 34.253 24.9672V12.1702C34.2682 6.61162 30.9545 3.29785 25.3959 3.29785ZM17.3634 26.4332C17.3634 26.5859 17.3329 26.7233 17.2718 26.876C17.1496 27.1509 16.9358 27.38 16.6457 27.5021C16.5082 27.5632 16.3555 27.5937 16.2028 27.5937C16.0501 27.5937 15.9127 27.5632 15.76 27.5021C15.6225 27.441 15.5004 27.3647 15.3935 27.2578L10.7511 22.6155C10.3083 22.1726 10.3083 21.4396 10.7511 20.9967C11.194 20.5539 11.927 20.5539 12.3699 20.9967L15.0575 23.6844V10.7042C15.0575 10.0781 15.5767 9.55889 16.2028 9.55889C16.8289 9.55889 17.3481 10.0781 17.3481 10.7042V26.4332H17.3634ZM27.2284 16.1559C26.9993 16.385 26.7092 16.4919 26.419 16.4919C26.1289 16.4919 25.8387 16.385 25.6097 16.1559L22.922 13.4682V26.4484C22.922 27.0745 22.4028 27.5937 21.7767 27.5937C21.1506 27.5937 20.6314 27.0745 20.6314 26.4484V10.7042C20.6314 10.5515 20.6619 10.4141 20.723 10.2614C20.8452 9.98648 21.059 9.75741 21.3491 9.63525C21.624 9.51308 21.9447 9.51308 22.2195 9.63525C22.357 9.69633 22.4791 9.77268 22.586 9.87958L27.2284 14.5219C27.6712 14.98 27.6712 15.6978 27.2284 16.1559Z"
              fill="white"
            />
          </svg>
        </div>
        <h3>{t("Home.Features.feat4.title")}</h3>
        <p>{t("Home.Features.feat4.text")}</p>
        <Link to="/sign-up">
          {t("Home.Features.feat4.btn")}
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 11.0289L21.7 2.82886"
              stroke="#5CC159"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.5031 6.82881V2.02881H17.7031"
              stroke="#5CC159"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.5 2.02881H9.5C4.5 2.02881 2.5 4.02881 2.5 9.02881V15.0288C2.5 20.0288 4.5 22.0288 9.5 22.0288H15.5C20.5 22.0288 22.5 20.0288 22.5 15.0288V13.0288"
              stroke="#5CC159"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      </div>
      <div className="item">
        <div className="icon">
          <svg
            width="38"
            height="37"
            viewBox="0 0 38 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.3959 3.29785H12.5989C7.04033 3.29785 3.72656 6.61162 3.72656 12.1702V24.9519C3.72656 30.5257 7.04033 33.8395 12.5989 33.8395H25.3806C30.9392 33.8395 34.253 30.5257 34.253 24.9672V12.1702C34.2682 6.61162 30.9545 3.29785 25.3959 3.29785ZM26.4496 17.851C26.4496 18.4465 25.9762 18.9199 25.3806 18.9199C24.785 18.9199 24.3116 18.4465 24.3116 17.851V17.5761L20.158 21.7297C19.9289 21.9588 19.6235 22.0657 19.3028 22.0352C18.9821 22.0046 18.692 21.8366 18.524 21.5618L16.9664 19.2406L13.3319 22.8751C13.1181 23.0889 12.8585 23.1805 12.5836 23.1805C12.3088 23.1805 12.0339 23.0736 11.8354 22.8751C11.4231 22.4627 11.4231 21.7908 11.8354 21.3632L16.3861 16.8125C16.6151 16.5835 16.9206 16.4766 17.2413 16.5071C17.5619 16.5377 17.8521 16.7056 18.0201 16.9805L19.5777 19.3017L22.7998 16.0795H22.525C21.9294 16.0795 21.456 15.6061 21.456 15.0106C21.456 14.415 21.9294 13.9416 22.525 13.9416H25.3653C25.5028 13.9416 25.6402 13.9722 25.7776 14.018C26.0373 14.1249 26.251 14.3387 26.3579 14.5983C26.419 14.7357 26.4343 14.8731 26.4343 15.0106V17.851H26.4496Z"
              fill="white"
            />
          </svg>
        </div>
        <h3>{t("Home.Features.feat5.title")}</h3>
        <p>{t("Home.Features.feat5.text")}</p>
        <Link to="/sign-up">
          {t("Home.Features.feat5.btn")}
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 11.0289L21.7 2.82886"
              stroke="#5CC159"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.5031 6.82881V2.02881H17.7031"
              stroke="#5CC159"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.5 2.02881H9.5C4.5 2.02881 2.5 4.02881 2.5 9.02881V15.0288C2.5 20.0288 4.5 22.0288 9.5 22.0288H15.5C20.5 22.0288 22.5 20.0288 22.5 15.0288V13.0288"
              stroke="#5CC159"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      </div>
      <div className="item">
        <div className="icon">
          <svg
            width="38"
            height="37"
            viewBox="0 0 38 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27.441 12.1091C27.3341 12.0939 27.2272 12.0939 27.1203 12.1091C24.7533 12.0328 22.875 10.0934 22.875 7.71112C22.875 5.28306 24.8449 3.29785 27.2883 3.29785C29.7163 3.29785 31.7015 5.26779 31.7015 7.71112C31.6863 10.0934 29.808 12.0328 27.441 12.1091Z"
              fill="white"
            />
            <path
              d="M32.4219 22.6917C30.7116 23.837 28.3141 24.2646 26.0998 23.9745C26.6801 22.7223 26.9855 21.3326 27.0008 19.8666C27.0008 18.3395 26.6648 16.8888 26.0234 15.6213C28.2835 15.3159 30.681 15.7435 32.4066 16.8888C34.8194 18.477 34.8194 21.0883 32.4219 22.6917Z"
              fill="white"
            />
            <path
              d="M10.5031 12.1091C10.61 12.0939 10.7169 12.0939 10.8238 12.1091C13.1908 12.0328 15.0691 10.0934 15.0691 7.71112C15.0691 5.26779 13.0991 3.29785 10.6558 3.29785C8.22775 3.29785 6.25781 5.26779 6.25781 7.71112C6.25781 10.0934 8.13613 12.0328 10.5031 12.1091Z"
              fill="white"
            />
            <path
              d="M10.6746 19.8668C10.6746 21.3481 10.9953 22.753 11.5756 24.0205C9.42241 24.2495 7.17759 23.7914 5.52834 22.7072C3.11555 21.1037 3.11555 18.4924 5.52834 16.889C7.16232 15.7895 9.46822 15.3466 11.6367 15.591C11.0106 16.8737 10.6746 18.3244 10.6746 19.8668Z"
              fill="white"
            />
            <path
              d="M19.1782 24.4785C19.0561 24.4632 18.9186 24.4632 18.7812 24.4785C15.9714 24.3869 13.7266 22.081 13.7266 19.2406C13.7418 16.3391 16.0783 13.9874 18.995 13.9874C21.8965 13.9874 24.2482 16.3391 24.2482 19.2406C24.2329 22.081 22.0034 24.3869 19.1782 24.4785Z"
              fill="white"
            />
            <path
              d="M14.2138 27.6396C11.9079 29.182 11.9079 31.7169 14.2138 33.244C16.8404 35.0002 21.1468 35.0002 23.7733 33.244C26.0792 31.7017 26.0792 29.1667 23.7733 27.6396C21.162 25.8835 16.8557 25.8835 14.2138 27.6396Z"
              fill="white"
            />
          </svg>
        </div>
        <h3>{t("Home.Features.feat6.title")}</h3>
        <p>{t("Home.Features.feat6.text")}</p>
        <Link to="/sign-up">
          {t("Home.Features.feat6.btn")}
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 11.0289L21.7 2.82886"
              stroke="#5CC159"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.5031 6.82881V2.02881H17.7031"
              stroke="#5CC159"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.5 2.02881H9.5C4.5 2.02881 2.5 4.02881 2.5 9.02881V15.0288C2.5 20.0288 4.5 22.0288 9.5 22.0288H15.5C20.5 22.0288 22.5 20.0288 22.5 15.0288V13.0288"
              stroke="#5CC159"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
}

export default Features;
