import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import "./css/login.scss";
import RightCov from "./login&sign/RightCov";
import Wrong from "./login&sign/wrong";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import authService from "./services/auth";
// Assets
import Logo from "./assets/insta-signal_FINAL icon 1.png";
import { setEmailValue, setOtpValue } from "./redux/features/email/userSlice";
const OtpCheckValue = ({ reason }) => {
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false)
  const [t, _] = useTranslation();
  const navigate = useNavigate()
  const { email } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  useEffect(() => {
    setOtpError(false)
  }, [otp])

  const otpCheck = (e) => {
    authService.verify(
      email,
      otp,
      reason,
      (d) => {
        console.log(reason)
        if (reason === "CHANGE_PASSWORD") {
          dispatch(setOtpValue(otp))
          dispatch(setEmailValue(email))
          navigate('/reset-password/new-password')
        } else {
          navigate('/sign-in')
        }
      },
      (e) => {
        setOtpError(true)
      })
  }

  return (
    <div className="otp-check">
      <div className="otp-content">
        <form>
          <div className="top_part">
            <img src={Logo} alt="" />
            <h1>{t("OTP.title1")}</h1>
            <p>{t("OTP.title2")}</p>
          </div>
          {/* Add Class "error" for red border in input */}
          <div className="otp_section">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              shouldAutoFocus={true}
              placeholder={"000000"}
              renderSeparator={""}
              renderInput={(props) => <input {...props} />}
            />
          </div>
          {
            otpError && <Wrong msg={t("OTP.wrong")} />
          }
          <button className="btn_sign" disabled={otp.length < 6} type="button" onClick={e => otpCheck(e)}>
            {t("OTP.btn")}
          </button>
          <div className="qs_sign">
            {t("OTP.note1")}
            <Link to="/sign-up/otp-check">{t("OTP.note2")}</Link>
          </div>
        </form>
        <Link to="/sign-up" className="back">
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5582 8.40088L4.95898 17L13.5582 25.5992"
              stroke="#292D32"
              strokeWidth="2.125"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M29.0417 17H5.19922"
              stroke="#292D32"
              strokeWidth="2.125"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      </div>
      <RightCov />
    </div>
  );
}

export default OtpCheckValue;
