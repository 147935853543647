import { useState } from "react";
import Qr from "../../assets/Rectangle 6300 (2).png";
import { useTranslation } from "react-i18next";

function SecuritySettings() {
  const [TwoFA, setTwoFA] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [showPassword2, setShowPassword2] = useState(true);
  const [showPassword3, setShowPassword3] = useState(true);
  const [t] = useTranslation();
  return (
    <div className="security-settings">
      <form className="settings_form">
        <div className="inpt switch">
          <label>{t("Dashboard.settings.2FAuthentication")}</label>
          <div className="check">
            <input
              type="checkbox"
              onClick={(e) => {
                if (e.target.checked) {
                  setTwoFA(true);
                } else {
                  setTwoFA(false);
                }
              }}
            />
            <p>{t("Dashboard.settings.Allow")}</p>
          </div>
          {TwoFA && (
            <div className="qr_box">
              <p>{t("Dashboard.settings.Scan")}</p>
              <div className="qr">
                <img src={Qr} alt="" />
              </div>
              <div className="field">
                <input
                  type="text"
                  placeholder={t("Dashboard.settings.Enter6")}
                ></input>
                <button type="button">{t("Dashboard.settings.Verify")}</button>
              </div>
            </div>
          )}
        </div>
        <div className="inpt">
          <label>{t("Dashboard.settings.ChangePassword")}</label>
          <div className="btx">
            <input
              type={`${showPassword ? "password" : "text"}`}
              placeholder={t("Dashboard.settings.YourPassword")}
            />
            <div
              className="show_pwd"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.7036 15.6372C9.51814 15.6372 7.74414 13.8632 7.74414 11.6777C7.74414 9.49226 9.51814 7.71826 11.7036 7.71826C13.8891 7.71826 15.6631 9.49226 15.6631 11.6777C15.6631 13.8632 13.8891 15.6372 11.7036 15.6372ZM11.7036 9.08991C10.2771 9.08991 9.11579 10.2512 9.11579 11.6777C9.11579 13.1043 10.2771 14.2656 11.7036 14.2656C13.1301 14.2656 14.2915 13.1043 14.2915 11.6777C14.2915 10.2512 13.1301 9.08991 11.7036 9.08991Z"
                  fill="#8B948B"
                />
                <path
                  d="M11.7032 19.9259C8.26496 19.9259 5.01873 17.9142 2.78752 14.421C1.81822 12.9122 1.81822 10.4524 2.78752 8.93444C5.02788 5.44131 8.27411 3.42957 11.7032 3.42957C15.1323 3.42957 18.3786 5.44131 20.6098 8.93444C21.5791 10.4433 21.5791 12.9031 20.6098 14.421C18.3786 17.9142 15.1323 19.9259 11.7032 19.9259ZM11.7032 4.80121C8.74961 4.80121 5.92402 6.57521 3.94885 9.67513C3.26302 10.745 3.26302 12.6105 3.94885 13.6803C5.92402 16.7803 8.74961 18.5543 11.7032 18.5543C14.6568 18.5543 17.4824 16.7803 19.4576 13.6803C20.1434 12.6105 20.1434 10.745 19.4576 9.67513C17.4824 6.57521 14.6568 4.80121 11.7032 4.80121Z"
                  fill="#8B948B"
                />
              </svg>
            </div>
          </div>
          <div className="btx">
            <input
              type={`${showPassword2 ? "password" : "text"}`}
              placeholder={t("Dashboard.settings.NewPassword")}
            />
            <div
              className="show_pwd"
              onClick={() => {
                setShowPassword2(!showPassword2);
              }}
            >
              <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.7036 15.6372C9.51814 15.6372 7.74414 13.8632 7.74414 11.6777C7.74414 9.49226 9.51814 7.71826 11.7036 7.71826C13.8891 7.71826 15.6631 9.49226 15.6631 11.6777C15.6631 13.8632 13.8891 15.6372 11.7036 15.6372ZM11.7036 9.08991C10.2771 9.08991 9.11579 10.2512 9.11579 11.6777C9.11579 13.1043 10.2771 14.2656 11.7036 14.2656C13.1301 14.2656 14.2915 13.1043 14.2915 11.6777C14.2915 10.2512 13.1301 9.08991 11.7036 9.08991Z"
                  fill="#8B948B"
                />
                <path
                  d="M11.7032 19.9259C8.26496 19.9259 5.01873 17.9142 2.78752 14.421C1.81822 12.9122 1.81822 10.4524 2.78752 8.93444C5.02788 5.44131 8.27411 3.42957 11.7032 3.42957C15.1323 3.42957 18.3786 5.44131 20.6098 8.93444C21.5791 10.4433 21.5791 12.9031 20.6098 14.421C18.3786 17.9142 15.1323 19.9259 11.7032 19.9259ZM11.7032 4.80121C8.74961 4.80121 5.92402 6.57521 3.94885 9.67513C3.26302 10.745 3.26302 12.6105 3.94885 13.6803C5.92402 16.7803 8.74961 18.5543 11.7032 18.5543C14.6568 18.5543 17.4824 16.7803 19.4576 13.6803C20.1434 12.6105 20.1434 10.745 19.4576 9.67513C17.4824 6.57521 14.6568 4.80121 11.7032 4.80121Z"
                  fill="#8B948B"
                />
              </svg>
            </div>
          </div>
          <div className="btx">
            <input
              type={`${showPassword3 ? "password" : "text"}`}
              placeholder={t("Dashboard.settings.NewPassword")}
            />
            <div
              className="show_pwd"
              onClick={() => {
                setShowPassword3(!showPassword3);
              }}
            >
              <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.7036 15.6372C9.51814 15.6372 7.74414 13.8632 7.74414 11.6777C7.74414 9.49226 9.51814 7.71826 11.7036 7.71826C13.8891 7.71826 15.6631 9.49226 15.6631 11.6777C15.6631 13.8632 13.8891 15.6372 11.7036 15.6372ZM11.7036 9.08991C10.2771 9.08991 9.11579 10.2512 9.11579 11.6777C9.11579 13.1043 10.2771 14.2656 11.7036 14.2656C13.1301 14.2656 14.2915 13.1043 14.2915 11.6777C14.2915 10.2512 13.1301 9.08991 11.7036 9.08991Z"
                  fill="#8B948B"
                />
                <path
                  d="M11.7032 19.9259C8.26496 19.9259 5.01873 17.9142 2.78752 14.421C1.81822 12.9122 1.81822 10.4524 2.78752 8.93444C5.02788 5.44131 8.27411 3.42957 11.7032 3.42957C15.1323 3.42957 18.3786 5.44131 20.6098 8.93444C21.5791 10.4433 21.5791 12.9031 20.6098 14.421C18.3786 17.9142 15.1323 19.9259 11.7032 19.9259ZM11.7032 4.80121C8.74961 4.80121 5.92402 6.57521 3.94885 9.67513C3.26302 10.745 3.26302 12.6105 3.94885 13.6803C5.92402 16.7803 8.74961 18.5543 11.7032 18.5543C14.6568 18.5543 17.4824 16.7803 19.4576 13.6803C20.1434 12.6105 20.1434 10.745 19.4576 9.67513C17.4824 6.57521 14.6568 4.80121 11.7032 4.80121Z"
                  fill="#8B948B"
                />
              </svg>
            </div>
          </div>
        </div>
        <button type="button" className="save">
          {t("Dashboard.settings.SaveChanges")}
        </button>
      </form>
    </div>
  );
}

export default SecuritySettings;
