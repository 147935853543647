import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import telegramService from "../../services/telegram";

const VerifySignals = ({ StepProgress, signalText, setSignalText }) => {
  const [t] = useTranslation();
  const [signalValidData, setSignalValidData] = useState([]);

  const [signalsValid, setSignalsValid] = useState(false);
  const [signalsError, setSignalsError] = useState(false);

  const [enterNextStep, setEnterNextStep] = useState(false);
  const [verifyCheck, setVerifyCheck] = useState(false);
  const [lodaing, setLodaing] = useState(false);

  useEffect(() => {
    if (signalText) {
      setVerifyCheck(true);
    }
  }, [signalText]);

  const handleInput = (e) => {
    if (e.target.value.length > 0) {
      setVerifyCheck(true);
    } else {
      setVerifyCheck(false);
    }
    setSignalText(e.target.value);
    setSignalsValid(false);
    setEnterNextStep(false);
  };

  const HandleVerifySignal = () => {
    setLodaing(true);
    !lodaing &&
      telegramService.verifySignal(
        signalText,
        (res) => {
          if (res.success) {
            setSignalsValid(true);
            setEnterNextStep(true);
            setSignalValidData(res.signal);
          } else {
            setSignalsError(true);
          }
          // console.log(res);
          setLodaing(false);
        },
        (err) => {
          setSignalsError(true);
          setLodaing(false);
          // console.log(err);
        }
      );
  };

  return (
    <div className="VerifySignals step3">
      <div className="pop-up-title">
        <h1>{t("Dashboard.telegramBot.stp3Title")}</h1>
        <p>{t("Dashboard.telegramBot.stp3Text")}</p>
      </div>
      <div className="field">
        <textarea
          placeholder={t("Dashboard.telegramBot.inpt")}
          value={signalText}
          onChange={(e) => handleInput(e)}
        ></textarea>
        {signalsValid && (
          <div className="msg valid">
            <table>
              <thead>
                <tr>
                  <th>Pair</th>
                  <th>Entry Price</th>
                  <th>Target 1</th>
                  <th>Target 2</th>
                  <th>Target 3</th>
                  <th>Target 4</th>
                  {/* <th>Target 5</th> */}
                  <th>Stop Loss</th>
                  {/* <th>Leverage</th> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{signalValidData?.pair}</td>
                  <td>{signalValidData?.price}</td>
                  <td>{signalValidData?.take_profit1}</td>
                  <td>{signalValidData?.take_profit2}</td>
                  <td>{signalValidData?.take_profit3}</td>
                  <td>{signalValidData?.take_profit4}</td>
                  {/* <td>{signalValidData?.take_profit5}</td> */}
                  <td>{signalValidData?.stop_loss}</td>
                  {/* <td>{signalValidData?.price}</td> */}
                </tr>
              </tbody>
            </table>
            {/* Pair: {signalValidData?.pair}
            <br />
            Entry Price: {signalValidData?.price}
            <br />
            Target 1: {signalValidData?.take_profit1}
            <br />
            Target 2: {signalValidData?.take_profit2}
            <br />
            Target 3: {signalValidData?.take_profit3}
            <br />
            Target 4: {signalValidData?.take_profit4}
            <br />
            Target 5: {signalValidData?.take_profit5}
            <br />
            Stop Loss: {signalValidData?.stop_loss}
            <br />
            Leverage: {signalValidData?.price} */}
          </div>
        )}
        {signalsError && !signalsValid && (
          <div className="msg notvalid">
            Unfortunately we can't recognize your signal. Please try to contact
            your signal provider
          </div>
        )}
      </div>
      {!enterNextStep && (
        <button
          className={`confirm ${verifyCheck ? "active" : ""}`}
          onClick={(e) => HandleVerifySignal()}
          disabled={!verifyCheck}
        >
          {t("Verify signal")}
          {lodaing && (
            <svg
              className="lodaingSVG"
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
              >
                <animateTransform
                  attributeName="transform"
                  dur="0.75s"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 12 12;360 12 12"
                />
              </path>
            </svg>
          )}
        </button>
      )}
      {enterNextStep && (
        <button className={`confirm active`} onClick={(e) => StepProgress(4)}>
          {t("Dashboard.telegramBot.stp2Btn")}
        </button>
      )}
    </div>
  );
};

export default VerifySignals;
