import { NavLink, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

function Subscription() {
  const [DefaultActiveLink, SetDefaultActiveLink] = useState(true);
  const [t] = useTranslation();
  useEffect(() => {
    if (window.location.pathname !== "/subscription") {
      SetDefaultActiveLink(false);
    }
  }, [DefaultActiveLink]);
  return (
    <div className="dashboard-subscription">
      <div className="title">
        <h1>{t("Dashboard.Subscription.title")}</h1>
        <ul className="list-unstyled links">
          <li>
            <NavLink
              exact
              to="pricing"
              className={`${DefaultActiveLink ? "active" : ""}`}
              onClick={(e) => SetDefaultActiveLink(false)}
            >
              {t("Dashboard.Subscription.subtitle1")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="billing"
              exact
              onClick={(e) => SetDefaultActiveLink(false)}
            >
              {t("Dashboard.Subscription.subtitle2")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="promo-codes"
              exact
              onClick={(e) => SetDefaultActiveLink(false)}
            >
              {t("Dashboard.Subscription.subtitle3")}
            </NavLink>
          </li>
        </ul>
      </div>
      <Outlet />
    </div>
  );
}

export default Subscription;
