import { useState, useEffect } from "react";
import telegramService from "../../services/telegram";
import { ToastAlert, toast } from "../Alert/ToastAlert";

export default function TelegramBot({ SetSwitchPage }) {
  const [channels, setChannel] = useState([]);

  const getChannels = () => {
    telegramService.getFollowedChannels(
      (response) => {
        setChannel(response.channels)
      },
      (error) => {
        console.log(JSON.stringify(error));
      }
    );
  }
  useEffect(() => {
    getChannels();
  }, []);

  const enableChannel = (id) => {
    telegramService.followChannel(id,
      (response) => {
        getChannels();
        toast.success("The channel has been Enable");
        // console.log("enable Channel Done",response);
      },
      (error) => {
        // console.log("enable Channel error",error);
        // toast.error(error.response.data.message);
        toast.error("Already have an active channel");
      }
    );
  }
  const disableChannel = (id) => {
    telegramService.unfollowChannel(id,
      (response) => {
        // console.log("disable Channel Done",response);
        getChannels();
        toast.success("The channel has been Disable");
      },
      (error) => {
        toast.error(error.response.data.message);
        // console.log("disable Channel error",error);
      }
    );
  }
  const deleteChannel = (id) => {
    console.log(id);
  }

  return (
    <div className="added_channels">
      <div className="trades_table">
        <table className="table">
          <thead>
            <tr>
              <th>Channel</th>
              <th>Trades/Day</th>
              <th>Profit/Day</th>
              <th>Loss/Day</th>
              <th>Amount</th>
              <th>Mode</th>
              <th>Signal Market</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {channels.length === 0 && (
              <tr>
                <td colSpan="9">
                  <div className="empty-table">
                    <p>There are no channels at the moment.</p>
                    <a onClick={e => SetSwitchPage(false)}>Add Channel</a>
                  </div>
                </td>
              </tr>
            )}
            {channels && (
              channels.map((chat) => (
                <tr key={chat._id}>
                  <td title={chat?.channelName}>{chat?.channelName.substring(0, 30)}</td>
                  <td>{chat?.maxTradesPerDay}</td>
                  <td>{chat?.maxProfitPerDay}</td>
                  <td>{chat?.maxLossPerDay}</td>
                  <td>
                    {chat?.tradeAmount}
                    {chat?.tradeAmountMode === "percentage" && <>%</>}
                  </td>
                  <td>{chat?.tradeAmountMode}</td>
                  <td>{chat?.tradeSignalMarket}</td>
                  <td className="status">
                    {chat?.active && (<p className="active">Enable</p>)}
                    {!chat?.active && (<p className="not-active">Disable</p>)}
                  </td>
                  <td className="btns">
                    {chat?.active && (<button className="delete" onClick={e => disableChannel(chat?._id)}>Disable</button>)}
                    {!chat?.active && (<button onClick={e => enableChannel(chat?._id)}>Enable</button>)}
                    {/* <button className="delete" onClick={e => deleteChannel(chat?._id)}>Delete</button> */}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

    </div>
  );
}
