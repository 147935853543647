import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import WebhookImg from "../assets/[removal 1.png";
import backImg from "../assets/Ellipse 7417.png";
import Binance from "../assets/Binance.png";
import step1 from "../assets/image 7.png";
import { useTranslation } from "react-i18next";
import exchangeService from "../services/exchange";

let jsonData = {
  exchange: "{{exchange}}", // name of target exchange
  secret: "{{secret}}", // secret for target exchange
  category: "{{category}}", // spot or future
  action: "{{action}}", // buy or sell
  size: "{{size}}", // amount 1
  price: "{{price}}", // price 100
  pair: "{{pair}}", // BTCUSDT
  mode: "{{mode}}", // limit or market
  stop_loss: "{{stoploss}}", // stop loss 10
};

function TradingViewBot() {
  const [step, setStep] = useState(false);
  const [messageGenerator, setMessageGenerator] = useState(false);
  const [basicPlan, setBasicPlan] = useState(false);
  const [exchanges, setExchanges] = useState([]);
  const { t } = useTranslation();

  const getSupportedExchangesWithUserConnections = async () => {
    try {
      const data =
        await exchangeService.getSupportedExchangesWithUserConnection();
      setExchanges(data.exchanges);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSupportedExchangesWithUserConnections();
  }, []);

  const copyToClipboard = (e) => {
    const tempInput = document.createElement("input");
    tempInput.value = JSON.stringify(jsonData, null, 2);
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    e.target.classList.add("copied");
    setTimeout(() => {
      e.target.classList.remove("copied");
    }, 3000);
  };

  const onPressGenerateMessage = (exchangeConnection) => {
    jsonData.category = exchangeConnection.isSpot ? "spot" : "future";
    jsonData.exchange = exchangeConnection.provider;
    jsonData.secret = exchangeConnection.secret;
    setMessageGenerator(true);
  };
  return (
    <div className="dashboard-trading-view-bot">
      {!step ? (
        <>
          <div className="title">
            <h1>{t("Dashboard.tradingViewBot.title")}</h1>
          </div>
          <div className="webhook" onClick={() => setBasicPlan(true)}>
            <div className="txt">
              <h3>{t("Dashboard.tradingViewBot.cardTitle")}</h3>
              <p>{t("Dashboard.tradingViewBot.cardText")}</p>
            </div>
            <div className="img">
              <img src={backImg} alt="" />
            </div>
            <div className="person">
              <img src={WebhookImg} alt="" />
            </div>
          </div>
          <div className="trading-view-table table-bor">
            <Table>
              <thead>
                <tr>
                  <th>{t("Dashboard.tradingViewBot.Exchange")}</th>
                  <th>{t("Dashboard.tradingViewBot.SupportedTrades")}</th>
                  <th>{t("Dashboard.tradingViewBot.Status")}</th>
                  <th>{t("Dashboard.tradingViewBot.Instructions")}</th>
                </tr>
              </thead>
              <tbody>
                {exchanges.map((exchange) => (
                  <tr key={exchange._id}>
                    <td>
                      <div className="logo">
                        <img src={Binance} alt="Binance" />
                        <p>{exchange.provider}</p>
                      </div>
                    </td>
                    <td>
                      <p className="supported">
                        {exchange.isSpot
                          ? t("Dashboard.tradingViewBot.Spot")
                          : t("Dashboard.tradingViewBot.Futures")}
                      </p>
                    </td>
                    <td>
                      <p
                        className={`status ${exchange.connected ? "connected" : "NotConnected"
                          }`}
                      >
                        {exchange.connected
                          ? t("Dashboard.tradingViewBot.Connected")
                          : t("Dashboard.tradingViewBot.NotConnected")}
                      </p>
                    </td>
                    <td>
                      <div className="instr">
                        {!exchange.connected ?
                          <Link to="/exchange">
                            {t("Dashboard.tradingViewBot.CreateAPIkey")}
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.5 11.0289L21.7 2.82886"
                                stroke="#5CC159"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M22.5031 6.82881V2.02881H17.7031"
                                stroke="#5CC159"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M11.5 2.02881H9.5C4.5 2.02881 2.5 4.02881 2.5 9.02881V15.0288C2.5 20.0288 4.5 22.0288 9.5 22.0288H15.5C20.5 22.0288 22.5 20.0288 22.5 15.0288V13.0288"
                                stroke="#5CC159"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Link>
                          :
                          <Link >
                            <div
                              class="copy"
                              onClick={() => {
                                navigator.clipboard.writeText(exchange.secret);
                              }}
                            >
                              {t("Copy api key")}
                              <svg
                                width="29"
                                height="28"
                                viewBox="0 0 29 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.5052 15.05V19.95C19.5052 24.0333 17.8719 25.6666 13.7885 25.6666H8.88854C4.80521 25.6666 3.17188 24.0333 3.17188 19.95V15.05C3.17188 10.9666 4.80521 9.33331 8.88854 9.33331H13.7885C17.8719 9.33331 19.5052 10.9666 19.5052 15.05Z"
                                  fill="#5CC159"
                                ></path>
                                <path
                                  d="M20.7925 2.33331H15.8925C12.2956 2.33331 10.6086 3.60975 10.2572 6.69549C10.1837 7.34105 10.7183 7.87498 11.3681 7.87498H13.7925C18.6925 7.87498 20.9675 10.15 20.9675 15.05V17.4744C20.9675 18.1242 21.5014 18.6588 22.147 18.5853C25.2327 18.2338 26.5092 16.5469 26.5092 12.95V8.04998C26.5092 3.96665 24.8758 2.33331 20.7925 2.33331Z"
                                  fill="#5CC159"
                                ></path>
                              </svg>
                            </div>
                          </Link>
                        }
                        <button onClick={() => setStep(true)}>
                          {t("Dashboard.tradingViewBot.SetupSteps")}
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.5 11.0289L21.7 2.82886"
                              stroke="#5CC159"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M22.5031 6.82881V2.02881H17.7031"
                              stroke="#5CC159"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.5 2.02881H9.5C4.5 2.02881 2.5 4.02881 2.5 9.02881V15.0288C2.5 20.0288 4.5 22.0288 9.5 22.0288H15.5C20.5 22.0288 22.5 20.0288 22.5 15.0288V13.0288"
                              stroke="#5CC159"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                        <button onClick={() => onPressGenerateMessage(exchange)}>
                          {t("Dashboard.tradingViewBot.MessageGenerator")}
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.5 11.0289L21.7 2.82886"
                              stroke="#5CC159"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M22.5031 6.82881V2.02881H17.7031"
                              stroke="#5CC159"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.5 2.02881H9.5C4.5 2.02881 2.5 4.02881 2.5 9.02881V15.0288C2.5 20.0288 4.5 22.0288 9.5 22.0288H15.5C20.5 22.0288 22.5 20.0288 22.5 15.0288V13.0288"
                              stroke="#5CC159"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                        <button>
                          {t("Dashboard.tradingViewBot.SupportedSymbols")}
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.5 11.0289L21.7 2.82886"
                              stroke="#5CC159"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M22.5031 6.82881V2.02881H17.7031"
                              stroke="#5CC159"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.5 2.02881H9.5C4.5 2.02881 2.5 4.02881 2.5 9.02881V15.0288C2.5 20.0288 4.5 22.0288 9.5 22.0288H15.5C20.5 22.0288 22.5 20.0288 22.5 15.0288V13.0288"
                              stroke="#5CC159"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <div className="instructions-trading-view">
          <div className="back_to" onClick={() => setStep(false)}>
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.4978 29.8799L12.7178 20.0999C11.5628 18.9449 11.5628 17.0549 12.7178 15.8999L22.4978 6.11987"
                stroke="#5CC159"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {t("Dashboard.popup.Back")}
          </div>
          <div className="title">
            <h1>{t("Dashboard.tradingViewBot.ReferralProgramme")}</h1>
            <p>{t("Dashboard.tradingViewBot.StepsText")}</p>
          </div>
          <div className="inst_steps">
            <div className="inst_step">
              <h3>
                {t("Dashboard.tradingViewBot.Step1Title")}.{" "}
                {t("Dashboard.tradingViewBot.Step1Text")}
              </h3>
              <img src={step1} alt="Step 1" />
            </div>
            <div className="inst_step">
              <h3>
                {t("Dashboard.tradingViewBot.Step2Title")}.{" "}
                {t("Dashboard.tradingViewBot.Step2Text")}
              </h3>
              <img src={step1} alt="Step 1" />
            </div>
          </div>
        </div>
      )}

      {messageGenerator && (
        <div className="pop-up message-generator-bot-pop-up">
          <div className="pop-up-content">
            <div className="pop-up-title">
              <h1>{t("Dashboard.tradingViewBot.Pop2Title")}</h1>
              <div className="close" onClick={() => setMessageGenerator(false)}>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.0009 26.9999L9.00098 9M27.001 9L9.00098 27.0001"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="JSON-msg">
              <pre>{JSON.stringify(jsonData, null, 2)}</pre>
            </div>
            <div className="btns">
              <button onClick={copyToClipboard}>
                {t("Dashboard.popup.Copy")}
              </button>
              <button onClick={() => setMessageGenerator(false)}>
                {t("Dashboard.popup.Cancel")}
              </button>
            </div>
          </div>
        </div>
      )}

      {basicPlan && (
        <div className="problem-pop-up">
          <form className="content">
            <svg
              width="139"
              height="138"
              viewBox="0 0 139 138"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0.5" width="138" height="138" rx="69" fill="#E72E4F" />
              <path
                d="M69.4974 39.4167C53.197 39.4167 39.9141 52.6996 39.9141 69C39.9141 85.3004 53.197 98.5833 69.4974 98.5833C85.7978 98.5833 99.0807 85.3004 99.0807 69C99.0807 52.6996 85.7978 39.4167 69.4974 39.4167ZM67.2786 57.1667C67.2786 55.9538 68.2845 54.9479 69.4974 54.9479C70.7103 54.9479 71.7161 55.9538 71.7161 57.1667V71.9583C71.7161 73.1713 70.7103 74.1771 69.4974 74.1771C68.2845 74.1771 67.2786 73.1713 67.2786 71.9583V57.1667ZM72.2191 81.9575C72.0711 82.3421 71.8641 82.6379 71.5978 82.9338C71.302 83.2 70.9766 83.4071 70.6216 83.555C70.2666 83.7029 69.882 83.7917 69.4974 83.7917C69.1128 83.7917 68.7282 83.7029 68.3732 83.555C68.0182 83.4071 67.6928 83.2 67.397 82.9338C67.1307 82.6379 66.9236 82.3421 66.7757 81.9575C66.6278 81.6025 66.5391 81.2179 66.5391 80.8333C66.5391 80.4488 66.6278 80.0642 66.7757 79.7092C66.9236 79.3542 67.1307 79.0288 67.397 78.7329C67.6928 78.4667 68.0182 78.2596 68.3732 78.1117C69.0832 77.8158 69.9116 77.8158 70.6216 78.1117C70.9766 78.2596 71.302 78.4667 71.5978 78.7329C71.8641 79.0288 72.0711 79.3542 72.2191 79.7092C72.367 80.0642 72.4557 80.4488 72.4557 80.8333C72.4557 81.2179 72.367 81.6025 72.2191 81.9575Z"
                fill="white"
              />
            </svg>
            <h1>{t("Dashboard.tradingViewBot.Pop1Title")}</h1>
            <p>{t("Dashboard.tradingViewBot.Pop1Text")}</p>
            <div className="btns">
              <Link to="/subscription">{t("Dashboard.popup.Upgrade")}</Link>
              <button type="button" onClick={() => setBasicPlan(false)}>
                {t("Dashboard.popup.Cancel")}
              </button>
            </div>
            <div onClick={() => setBasicPlan(false)} className="close">
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.0009 26.9999L9.00098 9M27.001 9L9.00098 27.0001"
                  stroke="black"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default TradingViewBot;
