import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const CompleteConfiguration = ({ StepProgress, configuration, setConfiguration }) => {
  // Language
  const [t] = useTranslation();

  // Configuration
  const handleConfigurationChange = (key, value) => {
    if (value === "spot") {
      configuration.isIsolated = false;
    }
    if (value === "futures") {
      configuration.isIsolated = false;
    }
    setConfiguration(prevState => ({ ...prevState, [key]: value }));
  };
  const isEmptyValuesExist = () => {
    for (const key in configuration) {
      if (key !== 'isIsolated' && configuration[key] === "") {
        return true;
      }
    }
    return false;
  };

  const HandleConfig = () => {
    if (!isEmptyValuesExist()) {
      StepProgress(5);
    }
  }

  return (
    <div className="VerifySignals step4">
      <div className="pop-up-title">
        <h1>{t("Dashboard.telegramBot.stp4Title")}</h1>
        <p>{t("Dashboard.telegramBot.stp4Text")}</p>
      </div>
      <form>
        <div className="inpt">
          <label>
            {t("Dashboard.telegramBot.MaximumTradesPerDay")}
          </label>
          <input style={{ textIndent: "5px" }} type="number" value={configuration.maxTradesPerDay} placeholder="450" min={1}
            onChange={e => handleConfigurationChange("maxTradesPerDay", e.target.value)} />
          <p>{t("Dashboard.telegramBot.MaximumTradesPerDayText")}</p>
        </div>
        <div className="inpt">
          <label>{t("Dashboard.telegramBot.TakeProfitPerDay")}</label>
          <input type="number" value={configuration.maxProfitPerDay} placeholder="450" min={1}
            onChange={e => handleConfigurationChange("maxProfitPerDay", e.target.value)} />
          <span>$</span>
          <p>{t("Dashboard.telegramBot.TakeProfitPerDayText")}</p>
        </div>
        <div className="inpt">
          <label>{t("Dashboard.telegramBot.StopLossPerDay")}</label>
          <input type="number" placeholder="450" value={configuration.maxLossPerDay} min={1}
            onChange={e => handleConfigurationChange("maxLossPerDay", e.target.value)} />
          <span>$</span>
          <p>{t("Dashboard.telegramBot.StopLossPerDayText")}</p>
        </div>
        <div className="inpt">
          <div className="check_inpt">
            <p>
              <input
                type="radio"
                id="bot-spot"
                name="bot-configuration"
                checked={configuration.tradeSignalMarket === "spot"}
                onClick={e => handleConfigurationChange("tradeSignalMarket", "spot")}
              />
              <label for="bot-spot">
                {t("Dashboard.telegramBot.Spot")}
              </label>
            </p>
            <p>
              <input
                type="radio"
                id="bot-futures"
                name="bot-configuration"
                checked={configuration.tradeSignalMarket === "futures"}
                onClick={e => handleConfigurationChange("tradeSignalMarket", "futures")}
              />
              <label for="bot-futures">
                {t("Dashboard.telegramBot.Futures")}
              </label>
            </p>
          </div>
        </div>
        {/* <div className="inpt">
          <label>{t("Dashboard.telegramBot.PositionSize")}</label>
          <div className="check_btns">
            <p>
              <input
                type="radio"
                id="PositionSize"
                name="ChoosePostionSize"
                checked={configuration.tradeAmountMode === "percentage"}
                onClick={e => handleConfigurationChange("tradeAmountMode", "percentage")}
              />
              <label for="PositionSize">
                {t("Dashboard.telegramBot.PositionSize")}
              </label>
            </p>
            <p>
              <input
                type="radio"
                id="FixedAmount"
                name="ChoosePostionSize"
                checked={configuration.tradeAmountMode === "fixed"}
                onClick={e => handleConfigurationChange("tradeAmountMode", "fixed")}
              />
              <label for="FixedAmount">
                {t("Dashboard.telegramBot.FixedAmount")}
              </label>
            </p>
          </div>
        </div> 
        {configuration.tradeAmountMode === "percentage" && (
          <div className="inpt">
            <label>{t("Dashboard.telegramBot.Percentage")}</label>
            <input type="number" placeholder="10" value={configuration.tradeAmount} onChange={e => handleConfigurationChange("tradeAmount", e.target.value)} />
            <span>%</span>
          </div>
        )}
        */}

        <div className="inpt">
          <label>{t("Dashboard.telegramBot.FixedAmount")}</label>
          <input type="number" placeholder="10" value={configuration.tradeAmount} onChange={e => handleConfigurationChange("tradeAmount", e.target.value)} />
          <span>$</span>
        </div>

        {configuration.tradeSignalMarket === "futures" && (
          <div className="inpt">
            <div className="check_inpt">
              <p>
                <input
                  type="radio"
                  id="Cross"
                  name="bot-Futures"
                  checked={!configuration.isIsolated}
                  onClick={e => handleConfigurationChange("isIsolated", false)}
                />
                <label for="Cross">
                  {t("Dashboard.telegramBot.Cross")}
                </label>
              </p>
              <p>
                <input
                  type="radio"
                  id="Isolated"
                  name="bot-Futures"
                  checked={configuration.isIsolated}
                  onClick={e => handleConfigurationChange("isIsolated", true)}
                />
                <label for="Isolated">
                  {t("Isolated")}
                </label>
              </p>
            </div>
          </div>
        )}
        <button
          className={isEmptyValuesExist() ? "confirm" : "confirm active"}
          type="button"
          disabled={isEmptyValuesExist()}
          onClick={HandleConfig}
        >
          {t("Dashboard.telegramBot.st4Btn")}
        </button>
      </form>
    </div>
  );
};

export default CompleteConfiguration;