import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import exchangeService from "../../services/exchange";

// Assets
// import Binance from "../../assets/Binance.png";
// import Gateio from "../../assets/Gateio.png";
// import Kraken from "../../assets/Kraken.png";
// import BitGet from "../../assets/BitGet.png";
// import MexcGlobal from "../../assets/MexcGlobal.png";
// import CoinBase from "../../assets/CoinBase.png";

const ChooseExchange = ({onSubmit,setSelectedExchange}) => {
  const [t] = useTranslation();
  const [checkAdd, setCheckAdd] = useState(false);
  const [supportedExchanges, setSupportedExchanges] = useState([]);

  const fetchSupportedExchanges = async () => {
    exchangeService.getExchanges(
      (data) => {
        setSupportedExchanges(data.exchanges);
      },
      (err) => console.log(err)
    );
  };

  useEffect(() => {
    fetchSupportedExchanges();
  }, []);

  const onSelectExchange = (id) => {
    setSelectedExchange(id);
    setCheckAdd(true);
  };
  return (
    <div className="ChooseExchange step5">
      <div className="pop-up-title">
        <h1>{t("Dashboard.telegramBot.stp5Title")}</h1>
        <p>{t("Dashboard.telegramBot.stp5Text")}</p>
      </div>
      <div className="broker">
        {supportedExchanges.map((item, index) => {
          return (
            <div className="item" key={index}>
              <input
                onClick={() => onSelectExchange(item._id)}
                type="radio"
                name="spot"
              />
              <div className="spot_item">
                <img src={item.logo} alt={item.provider} />
                {/* <p>{item.provider}</p> */}
                <p>{`${item.provider}-${item.isSpot ? "spot" : "future"}`}</p>
              </div>
            </div>
          );
        })}
      </div>
      <button
        className={`confirm ${checkAdd ? "active" : ""}`}
        disabled={!checkAdd}
        onClick={() => onSubmit()}
      >
        {t("Dashboard.telegramBot.stp5Btn")}
      </button>
    </div>
  );
};

export default ChooseExchange;
