import React, { useEffect, useRef, useState } from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function SubscriptionBilling() {
  const [choosePay, setChoosePay] = useState("stripe");
  const [editPayment, setEditPayment] = useState(false);
  const [PaymentPop, setPaymentPop] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    let handle = (e) => {
      // if (!PayPop.current.contains(e.target)) {
      //   setPaymentPop(false);
      // }
      // if (!EditPayPop.current.contains(e.target)) {
      //   setEditPayment(false);
      // }
    };
    document.addEventListener("mousedown", handle);
    return () => {
      document.removeEventListener("mousedown", handle);
    };
  });
  function ExpiryDate(e) {
    var code = e.keyCode;
    var allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }
    e.target.value = e.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        "0$1/" // 3 > 03/
      )
      .replace(
        /^(0[1-9]|1[0-2])$/g,
        "$1/" // 11 > 11/
      )
      .replace(
        /^([0-1])([3-9])$/g,
        "0$1/$2" // 13 > 01/3
      )
      .replace(
        /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
        "$1/$2" // 141 > 01/41
      )
      .replace(
        /^([0]+)\/|[0]+$/g,
        "0" // 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d\/]|^[\/]*$/g,
        "" // To allow only digits and `/`
      )
      .replace(
        /\/\//g,
        "/" // Prevent entering more than 1 `/`
      );
  }
  return (
    <div className="subscription-billing">
      <div className="billings_table table-bor">
        <Table>
          <thead>
            <tr>
              <th>{t("Dashboard.Subscription.billing.Subscription")}</th>
              <th>{t("Dashboard.Subscription.billing.Start")}</th>
              <th>{t("Dashboard.Subscription.billing.End")}</th>
              <th>{t("Dashboard.Subscription.billing.Price")}</th>
              <th>{t("Dashboard.Subscription.billing.Payment")}</th>
              <th>{t("Dashboard.Subscription.billing.Invoice")}</th>
              <th>{t("Dashboard.Subscription.billing.Status")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="7" className="empty-table">
                <p>{t("Dashboard.Subscription.billing.Please")}</p>
                <Link to="/subscription/pricing">{t("Dashboard.Subscription.billing.Subscribe")}</Link>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="billings_table table-bor">
        <Table>
          <thead>
            <tr>
              <th>{t("Dashboard.Subscription.billing.Subscription")}</th>
              <th>{t("Dashboard.Subscription.billing.Start")}</th>
              <th>{t("Dashboard.Subscription.billing.End")}</th>
              <th>{t("Dashboard.Subscription.billing.Price")}</th>
              <th>{t("Dashboard.Subscription.billing.Payment")}</th>
              <th>{t("Dashboard.Subscription.billing.Invoice")}</th>
              <th>{t("Dashboard.Subscription.billing.Status")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t("Dashboard.Subscription.billing.VIPPlan")}</td>
              <td>22/09/2023</td>
              <td>22/10/2023</td>
              <td>24.99$</td>
              <td>Paypal</td>
              <td>
                <div className="download">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19ZM12.28 15.78L10.28 17.78C10.21 17.85 10.12 17.91 10.03 17.94C9.94 17.98 9.85 18 9.75 18C9.65 18 9.56 17.98 9.47 17.94C9.39 17.91 9.31 17.85 9.25 17.79C9.24 17.78 9.23 17.78 9.23 17.77L7.23 15.77C6.94 15.48 6.94 15 7.23 14.71C7.52 14.42 8 14.42 8.29 14.71L9 15.44V11.25C9 10.84 9.34 10.5 9.75 10.5C10.16 10.5 10.5 10.84 10.5 11.25V15.44L11.22 14.72C11.51 14.43 11.99 14.43 12.28 14.72C12.57 15.01 12.57 15.49 12.28 15.78Z"
                      fill="#5CC159"
                    />
                    <path
                      d="M17.4297 8.80999C18.3797 8.81999 19.6997 8.81999 20.8297 8.81999C21.3997 8.81999 21.6997 8.14999 21.2997 7.74999C19.8597 6.29999 17.2797 3.68999 15.7997 2.20999C15.3897 1.79999 14.6797 2.07999 14.6797 2.64999V6.13999C14.6797 7.59999 15.9197 8.80999 17.4297 8.80999Z"
                      fill="#5CC159"
                    />
                  </svg>
                </div>
              </td>
              <td>
                <p className="Status active">{t("Dashboard.Subscription.billing.Active")}</p>
              </td>
            </tr>
            <tr>
              <td>{t("Dashboard.Subscription.billing.FreeTrial")}</td>
              <td>22/09/2023</td>
              <td>22/10/2023</td>
              <td>24.99$</td>
              <td>Paypal</td>
              <td>
                <div className="download">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19ZM12.28 15.78L10.28 17.78C10.21 17.85 10.12 17.91 10.03 17.94C9.94 17.98 9.85 18 9.75 18C9.65 18 9.56 17.98 9.47 17.94C9.39 17.91 9.31 17.85 9.25 17.79C9.24 17.78 9.23 17.78 9.23 17.77L7.23 15.77C6.94 15.48 6.94 15 7.23 14.71C7.52 14.42 8 14.42 8.29 14.71L9 15.44V11.25C9 10.84 9.34 10.5 9.75 10.5C10.16 10.5 10.5 10.84 10.5 11.25V15.44L11.22 14.72C11.51 14.43 11.99 14.43 12.28 14.72C12.57 15.01 12.57 15.49 12.28 15.78Z"
                      fill="#5CC159"
                    />
                    <path
                      d="M17.4297 8.80999C18.3797 8.81999 19.6997 8.81999 20.8297 8.81999C21.3997 8.81999 21.6997 8.14999 21.2997 7.74999C19.8597 6.29999 17.2797 3.68999 15.7997 2.20999C15.3897 1.79999 14.6797 2.07999 14.6797 2.64999V6.13999C14.6797 7.59999 15.9197 8.80999 17.4297 8.80999Z"
                      fill="#5CC159"
                    />
                  </svg>
                </div>
              </td>
              <td>
                <p className="Status not-active">{t("Dashboard.Subscription.billing.Inactive")}</p>
              </td>
            </tr>
            <tr>
              <td>{t("Dashboard.Subscription.billing.VIPPlan")}</td>
              <td>22/09/2023</td>
              <td>22/10/2023</td>
              <td>24.99$</td>
              <td>Paypal</td>
              <td>
                <div className="download">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19ZM12.28 15.78L10.28 17.78C10.21 17.85 10.12 17.91 10.03 17.94C9.94 17.98 9.85 18 9.75 18C9.65 18 9.56 17.98 9.47 17.94C9.39 17.91 9.31 17.85 9.25 17.79C9.24 17.78 9.23 17.78 9.23 17.77L7.23 15.77C6.94 15.48 6.94 15 7.23 14.71C7.52 14.42 8 14.42 8.29 14.71L9 15.44V11.25C9 10.84 9.34 10.5 9.75 10.5C10.16 10.5 10.5 10.84 10.5 11.25V15.44L11.22 14.72C11.51 14.43 11.99 14.43 12.28 14.72C12.57 15.01 12.57 15.49 12.28 15.78Z"
                      fill="#5CC159"
                    />
                    <path
                      d="M17.4297 8.80999C18.3797 8.81999 19.6997 8.81999 20.8297 8.81999C21.3997 8.81999 21.6997 8.14999 21.2997 7.74999C19.8597 6.29999 17.2797 3.68999 15.7997 2.20999C15.3897 1.79999 14.6797 2.07999 14.6797 2.64999V6.13999C14.6797 7.59999 15.9197 8.80999 17.4297 8.80999Z"
                      fill="#5CC159"
                    />
                  </svg>
                </div>
              </td>
              <td>
                <p className="Status active">{t("Dashboard.Subscription.billing.Active")}</p>
              </td>
            </tr>
            <tr>
              <td>{t("Dashboard.Subscription.billing.FreeTrial")}</td>
              <td>22/09/2023</td>
              <td>22/10/2023</td>
              <td>24.99$</td>
              <td>Paypal</td>
              <td>
                <div className="download">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19ZM12.28 15.78L10.28 17.78C10.21 17.85 10.12 17.91 10.03 17.94C9.94 17.98 9.85 18 9.75 18C9.65 18 9.56 17.98 9.47 17.94C9.39 17.91 9.31 17.85 9.25 17.79C9.24 17.78 9.23 17.78 9.23 17.77L7.23 15.77C6.94 15.48 6.94 15 7.23 14.71C7.52 14.42 8 14.42 8.29 14.71L9 15.44V11.25C9 10.84 9.34 10.5 9.75 10.5C10.16 10.5 10.5 10.84 10.5 11.25V15.44L11.22 14.72C11.51 14.43 11.99 14.43 12.28 14.72C12.57 15.01 12.57 15.49 12.28 15.78Z"
                      fill="#5CC159"
                    />
                    <path
                      d="M17.4297 8.80999C18.3797 8.81999 19.6997 8.81999 20.8297 8.81999C21.3997 8.81999 21.6997 8.14999 21.2997 7.74999C19.8597 6.29999 17.2797 3.68999 15.7997 2.20999C15.3897 1.79999 14.6797 2.07999 14.6797 2.64999V6.13999C14.6797 7.59999 15.9197 8.80999 17.4297 8.80999Z"
                      fill="#5CC159"
                    />
                  </svg>
                </div>
              </td>
              <td>
                <p className="Status not-active">{t("Dashboard.Subscription.billing.Inactive")}</p>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="payment_methods">
        <h2>{t("Dashboard.Subscription.billing.Payment")}</h2>
        <div className="add_box">
          {/* <div className="item">
            <div className="icon">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35.9111 12.3587C35.9111 13.4312 35.0336 14.3087 33.9611 14.3087H5.36113C4.28863 14.3087 3.41113 13.4312 3.41113 12.3587V12.3425C3.41113 8.62124 6.41738 5.61499 10.1386 5.61499H29.1674C32.8886 5.61499 35.9111 8.63749 35.9111 12.3587Z"
                  fill="black"
                />
                <path
                  d="M3.41113 18.6962V26.8375C3.41113 30.5587 6.41738 33.565 10.1386 33.565H29.1674C32.8886 33.565 35.9111 30.5425 35.9111 26.8212V18.6962C35.9111 17.6237 35.0336 16.7462 33.9611 16.7462H5.36113C4.28863 16.7462 3.41113 17.6237 3.41113 18.6962ZM13.1611 28.1212H9.91113C9.24488 28.1212 8.69238 27.5687 8.69238 26.9025C8.69238 26.2362 9.24488 25.6837 9.91113 25.6837H13.1611C13.8274 25.6837 14.3799 26.2362 14.3799 26.9025C14.3799 27.5687 13.8274 28.1212 13.1611 28.1212ZM23.7236 28.1212H17.2236C16.5574 28.1212 16.0049 27.5687 16.0049 26.9025C16.0049 26.2362 16.5574 25.6837 17.2236 25.6837H23.7236C24.3899 25.6837 24.9424 26.2362 24.9424 26.9025C24.9424 27.5687 24.3899 28.1212 23.7236 28.1212Z"
                  fill="black"
                />
              </svg>
            </div>
            <div className="info">
              <span>Stripe</span>
              <p>Pay via credit or debit card</p>
            </div>
            <button className="add">Add Method</button>
          </div>
          <div className="item">
            <div className="icon">
              <svg
                width="39"
                height="40"
                viewBox="0 0 39 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30.8733 11.875C30.8733 12.1675 30.857 12.4437 30.8245 12.72C30.6295 15.0762 29.5895 17.205 28.0133 18.765C26.2583 20.5362 23.8208 21.625 21.1233 21.625H16.037C15.2245 21.625 14.542 22.2262 14.4283 23.0225L13.1933 31.6025C13.0958 32.2363 12.6408 32.7563 12.0395 32.9188C11.8933 32.9838 11.747 33 11.5845 33H8.41576C7.40826 33 6.64452 32.1062 6.80702 31.115L10.6907 7.82877C11.0807 5.47252 13.112 3.75 15.5008 3.75H22.7483C27.2333 3.75 30.8733 7.39 30.8733 11.875Z"
                  fill="#99A399"
                />
                <path
                  d="M34.1264 18.375C34.1264 20.6175 33.2164 22.6488 31.7539 24.1275C30.2752 25.59 28.2439 26.5 26.0014 26.5H22.5077C21.7114 26.5 21.0289 27.0687 20.8989 27.865L19.7289 34.885C19.5989 35.6812 18.9164 36.25 18.1201 36.25H13.3589C13.0664 36.25 12.8064 36.185 12.5789 36.055C12.2214 35.86 12.4489 35.3887 12.8389 35.2262C14.3014 34.755 15.3739 33.5038 15.6014 31.9438L16.6414 24.7612C16.7064 24.355 17.0477 24.0625 17.4377 24.0625H21.1264C24.3927 24.0625 27.4639 22.795 29.7389 20.5037C31.0876 19.1712 32.0951 17.53 32.6801 15.7588C32.8101 15.3525 33.3464 15.2713 33.5252 15.645C33.9152 16.49 34.1264 17.4 34.1264 18.375Z"
                  fill="#99A399"
                />
              </svg>
            </div>
            <div className="info">
              <span>Paypal </span>
              <p>Pay via Paypal</p>
            </div>
            <button className="add">Add Method</button>
          </div> */}
          <div className="item">
            <div className="icon">
              <svg
                width="39"
                height="40"
                viewBox="0 0 39 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35.75 31.375V36.25H3.25V31.375C3.25 30.4812 3.98125 29.75 4.875 29.75H34.125C35.0188 29.75 35.75 30.4812 35.75 31.375Z"
                  fill="#99A399"
                  stroke="#99A399"
                  strokeWidth="2.4375"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.375 18.375H8.125V29.75H11.375V18.375Z"
                  fill="#99A399"
                />
                <path
                  d="M17.875 18.375H14.625V29.75H17.875V18.375Z"
                  fill="#99A399"
                />
                <path
                  d="M24.375 18.375H21.125V29.75H24.375V18.375Z"
                  fill="#99A399"
                />
                <path
                  d="M30.875 18.375H27.625V29.75H30.875V18.375Z"
                  fill="#99A399"
                />
                <path
                  d="M37.375 37.4688H1.625C0.95875 37.4688 0.40625 36.9162 0.40625 36.25C0.40625 35.5838 0.95875 35.0312 1.625 35.0312H37.375C38.0412 35.0312 38.5938 35.5838 38.5938 36.25C38.5938 36.9162 38.0412 37.4688 37.375 37.4688Z"
                  fill="#99A399"
                />
                <path
                  d="M34.7263 9.84374L20.1012 3.99374C19.7762 3.86374 19.2238 3.86374 18.8988 3.99374L4.27375 9.84374C3.705 10.0712 3.25 10.7375 3.25 11.355V16.75C3.25 17.6437 3.98125 18.375 4.875 18.375H34.125C35.0188 18.375 35.75 17.6437 35.75 16.75V11.355C35.75 10.7375 35.295 10.0712 34.7263 9.84374ZM19.5 14.3125C18.1513 14.3125 17.0625 13.2237 17.0625 11.875C17.0625 10.5262 18.1513 9.43749 19.5 9.43749C20.8487 9.43749 21.9375 10.5262 21.9375 11.875C21.9375 13.2237 20.8487 14.3125 19.5 14.3125Z"
                  fill="#99A399"
                />
              </svg>
            </div>
            <div className="info">
              <span>{t("Dashboard.Subscription.billing.StripeOrPaypal")}</span>
              <p>{t("Dashboard.Subscription.billing.Pay")}</p>
            </div>
            <button className="add" onClick={() => setPaymentPop(true)}>
              {t("Dashboard.Subscription.billing.Add")}
            </button>
          </div>
        </div>
        <div className="methods_added">
          <div className="item">
            <div className="icon">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35.9111 12.3587C35.9111 13.4312 35.0336 14.3087 33.9611 14.3087H5.36113C4.28863 14.3087 3.41113 13.4312 3.41113 12.3587V12.3425C3.41113 8.62124 6.41738 5.61499 10.1386 5.61499H29.1674C32.8886 5.61499 35.9111 8.63749 35.9111 12.3587Z"
                  fill="black"
                />
                <path
                  d="M3.41113 18.6962V26.8375C3.41113 30.5587 6.41738 33.565 10.1386 33.565H29.1674C32.8886 33.565 35.9111 30.5425 35.9111 26.8212V18.6962C35.9111 17.6237 35.0336 16.7462 33.9611 16.7462H5.36113C4.28863 16.7462 3.41113 17.6237 3.41113 18.6962ZM13.1611 28.1212H9.91113C9.24488 28.1212 8.69238 27.5687 8.69238 26.9025C8.69238 26.2362 9.24488 25.6837 9.91113 25.6837H13.1611C13.8274 25.6837 14.3799 26.2362 14.3799 26.9025C14.3799 27.5687 13.8274 28.1212 13.1611 28.1212ZM23.7236 28.1212H17.2236C16.5574 28.1212 16.0049 27.5687 16.0049 26.9025C16.0049 26.2362 16.5574 25.6837 17.2236 25.6837H23.7236C24.3899 25.6837 24.9424 26.2362 24.9424 26.9025C24.9424 27.5687 24.3899 28.1212 23.7236 28.1212Z"
                  fill="black"
                />
              </svg>
            </div>
            <div className="info">
              <span>{t("Dashboard.Subscription.billing.Stripe")}</span>
              <p>{t("Dashboard.Subscription.billing.Credit")} (XXX4575X..)</p>
            </div>
            <div className="options">
              <button className="edit" onClick={(e) => setEditPayment(true)}>
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.8676 27.521H3.83314C3.33125 27.521 2.91504 27.1048 2.91504 26.6029C2.91504 26.101 3.33125 25.6848 3.83314 25.6848H25.8676C26.3695 25.6848 26.7857 26.101 26.7857 26.6029C26.7857 27.1048 26.3695 27.521 25.8676 27.521Z"
                    fill="#99A399"
                  />
                  <path
                    d="M23.4437 4.84992C21.0689 2.47509 18.743 2.41389 16.307 4.84992L14.8257 6.33113C14.7033 6.45354 14.6544 6.64941 14.7033 6.82078C15.6337 10.0648 18.2289 12.6599 21.4728 13.5903C21.5218 13.6025 21.5707 13.6148 21.6197 13.6148C21.7544 13.6148 21.8768 13.5658 21.9747 13.4679L23.4437 11.9866C24.6556 10.787 25.2432 9.62406 25.2432 8.44889C25.2554 7.23699 24.6678 6.06182 23.4437 4.84992Z"
                    fill="#99A399"
                  />
                  <path
                    d="M19.2703 14.7042C18.9153 14.5329 18.5726 14.3615 18.2421 14.1656C17.9728 14.0065 17.7157 13.8351 17.4586 13.6515C17.2505 13.5168 17.0057 13.321 16.7731 13.1251C16.7486 13.1129 16.6629 13.0394 16.565 12.9415C16.161 12.5987 15.7081 12.158 15.3041 11.6684C15.2674 11.6439 15.2062 11.5582 15.1205 11.448C14.9981 11.3011 14.79 11.0563 14.6064 10.7748C14.4595 10.5911 14.2881 10.3218 14.129 10.0525C13.9331 9.72201 13.7617 9.39149 13.5903 9.04873C13.5644 8.99316 13.5393 8.93787 13.515 8.8829C13.3343 8.47488 12.8023 8.35559 12.4868 8.67112L5.47432 15.6836C5.31518 15.8427 5.16828 16.1487 5.13156 16.3568L4.47052 21.0453C4.34811 21.8777 4.58069 22.6611 5.09483 23.1875C5.53552 23.616 6.14759 23.8486 6.80863 23.8486C6.95552 23.8486 7.10242 23.8363 7.24932 23.8118L11.95 23.1508C12.1703 23.1141 12.4764 22.9672 12.6233 22.808L19.627 15.8044C19.9447 15.4866 19.8248 14.9413 19.4115 14.765C19.365 14.7451 19.318 14.7249 19.2703 14.7042Z"
                    fill="#99A399"
                  />
                </svg>
              </button>
              <button className="remove">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.3327 6.99219C24.3618 6.79632 22.391 6.64943 20.4079 6.53926V6.52701L20.1386 4.93563C19.9549 3.80943 19.6856 2.12012 16.8211 2.12012H13.6139C10.7617 2.12012 10.4923 3.73598 10.2965 4.92339L10.0394 6.49029C8.90097 6.56374 7.76252 6.63719 6.62407 6.74736L4.12683 6.99219C3.61269 7.04115 3.24545 7.49408 3.29442 7.99598C3.34338 8.49788 3.78407 8.86512 4.29821 8.81615L6.79545 8.57132C13.2099 7.93477 19.6734 8.1796 26.1613 8.82839C26.198 8.82839 26.2225 8.82839 26.2592 8.82839C26.7244 8.82839 27.1284 8.47339 27.1773 7.99598C27.2141 7.49408 26.8468 7.04115 26.3327 6.99219Z"
                    fill="#E72E4F"
                  />
                  <path
                    d="M24.0797 10.5544C23.7859 10.2484 23.3819 10.077 22.9657 10.077H7.4926C7.07639 10.077 6.66019 10.2484 6.37863 10.5544C6.09708 10.8605 5.93794 11.2767 5.96243 11.7051L6.72139 24.2648C6.85605 26.1255 7.02743 28.4513 11.2997 28.4513H19.1586C23.4309 28.4513 23.6023 26.1377 23.7369 24.2648L24.4959 11.7174C24.5204 11.2767 24.3612 10.8605 24.0797 10.5544ZM17.2612 22.3184H13.1848C12.6829 22.3184 12.2667 21.9022 12.2667 21.4003C12.2667 20.8984 12.6829 20.4822 13.1848 20.4822H17.2612C17.7631 20.4822 18.1793 20.8984 18.1793 21.4003C18.1793 21.9022 17.7631 22.3184 17.2612 22.3184ZM18.2895 17.4219H12.1688C11.6669 17.4219 11.2507 17.0056 11.2507 16.5038C11.2507 16.0019 11.6669 15.5856 12.1688 15.5856H18.2895C18.7914 15.5856 19.2076 16.0019 19.2076 16.5038C19.2076 17.0056 18.7914 17.4219 18.2895 17.4219Z"
                    fill="#E72E4F"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <svg
                width="39"
                height="40"
                viewBox="0 0 39 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30.8733 11.875C30.8733 12.1675 30.857 12.4437 30.8245 12.72C30.6295 15.0762 29.5895 17.205 28.0133 18.765C26.2583 20.5362 23.8208 21.625 21.1233 21.625H16.037C15.2245 21.625 14.542 22.2262 14.4283 23.0225L13.1933 31.6025C13.0958 32.2363 12.6408 32.7563 12.0395 32.9188C11.8933 32.9838 11.747 33 11.5845 33H8.41576C7.40826 33 6.64452 32.1062 6.80702 31.115L10.6907 7.82877C11.0807 5.47252 13.112 3.75 15.5008 3.75H22.7483C27.2333 3.75 30.8733 7.39 30.8733 11.875Z"
                  fill="#0C7FCC"
                />
                <path
                  d="M34.1264 18.375C34.1264 20.6175 33.2164 22.6488 31.7539 24.1275C30.2752 25.59 28.2439 26.5 26.0014 26.5H22.5077C21.7114 26.5 21.0289 27.0687 20.8989 27.865L19.7289 34.885C19.5989 35.6812 18.9164 36.25 18.1201 36.25H13.3589C13.0664 36.25 12.8064 36.185 12.5789 36.055C12.2214 35.86 12.4489 35.3887 12.8389 35.2262C14.3014 34.755 15.3739 33.5038 15.6014 31.9438L16.6414 24.7612C16.7064 24.355 17.0477 24.0625 17.4377 24.0625H21.1264C24.3927 24.0625 27.4639 22.795 29.7389 20.5037C31.0876 19.1712 32.0951 17.53 32.6801 15.7588C32.8101 15.3525 33.3464 15.2713 33.5252 15.645C33.9152 16.49 34.1264 17.4 34.1264 18.375Z"
                  fill="#0C7FCC"
                />
              </svg>
            </div>
            <div className="info">
              <span>{t("Dashboard.Subscription.billing.Paypal")} </span>
              <p>Achraf Hamdani</p>
            </div>
            <div className="options">
              <button className="edit">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.8676 27.521H3.83314C3.33125 27.521 2.91504 27.1048 2.91504 26.6029C2.91504 26.101 3.33125 25.6848 3.83314 25.6848H25.8676C26.3695 25.6848 26.7857 26.101 26.7857 26.6029C26.7857 27.1048 26.3695 27.521 25.8676 27.521Z"
                    fill="#99A399"
                  />
                  <path
                    d="M23.4437 4.84992C21.0689 2.47509 18.743 2.41389 16.307 4.84992L14.8257 6.33113C14.7033 6.45354 14.6544 6.64941 14.7033 6.82078C15.6337 10.0648 18.2289 12.6599 21.4728 13.5903C21.5218 13.6025 21.5707 13.6148 21.6197 13.6148C21.7544 13.6148 21.8768 13.5658 21.9747 13.4679L23.4437 11.9866C24.6556 10.787 25.2432 9.62406 25.2432 8.44889C25.2554 7.23699 24.6678 6.06182 23.4437 4.84992Z"
                    fill="#99A399"
                  />
                  <path
                    d="M19.2703 14.7042C18.9153 14.5329 18.5726 14.3615 18.2421 14.1656C17.9728 14.0065 17.7157 13.8351 17.4586 13.6515C17.2505 13.5168 17.0057 13.321 16.7731 13.1251C16.7486 13.1129 16.6629 13.0394 16.565 12.9415C16.161 12.5987 15.7081 12.158 15.3041 11.6684C15.2674 11.6439 15.2062 11.5582 15.1205 11.448C14.9981 11.3011 14.79 11.0563 14.6064 10.7748C14.4595 10.5911 14.2881 10.3218 14.129 10.0525C13.9331 9.72201 13.7617 9.39149 13.5903 9.04873C13.5644 8.99316 13.5393 8.93787 13.515 8.8829C13.3343 8.47488 12.8023 8.35559 12.4868 8.67112L5.47432 15.6836C5.31518 15.8427 5.16828 16.1487 5.13156 16.3568L4.47052 21.0453C4.34811 21.8777 4.58069 22.6611 5.09483 23.1875C5.53552 23.616 6.14759 23.8486 6.80863 23.8486C6.95552 23.8486 7.10242 23.8363 7.24932 23.8118L11.95 23.1508C12.1703 23.1141 12.4764 22.9672 12.6233 22.808L19.627 15.8044C19.9447 15.4866 19.8248 14.9413 19.4115 14.765C19.365 14.7451 19.318 14.7249 19.2703 14.7042Z"
                    fill="#99A399"
                  />
                </svg>
              </button>
              <button className="remove">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.3327 6.99219C24.3618 6.79632 22.391 6.64943 20.4079 6.53926V6.52701L20.1386 4.93563C19.9549 3.80943 19.6856 2.12012 16.8211 2.12012H13.6139C10.7617 2.12012 10.4923 3.73598 10.2965 4.92339L10.0394 6.49029C8.90097 6.56374 7.76252 6.63719 6.62407 6.74736L4.12683 6.99219C3.61269 7.04115 3.24545 7.49408 3.29442 7.99598C3.34338 8.49788 3.78407 8.86512 4.29821 8.81615L6.79545 8.57132C13.2099 7.93477 19.6734 8.1796 26.1613 8.82839C26.198 8.82839 26.2225 8.82839 26.2592 8.82839C26.7244 8.82839 27.1284 8.47339 27.1773 7.99598C27.2141 7.49408 26.8468 7.04115 26.3327 6.99219Z"
                    fill="#E72E4F"
                  />
                  <path
                    d="M24.0797 10.5544C23.7859 10.2484 23.3819 10.077 22.9657 10.077H7.4926C7.07639 10.077 6.66019 10.2484 6.37863 10.5544C6.09708 10.8605 5.93794 11.2767 5.96243 11.7051L6.72139 24.2648C6.85605 26.1255 7.02743 28.4513 11.2997 28.4513H19.1586C23.4309 28.4513 23.6023 26.1377 23.7369 24.2648L24.4959 11.7174C24.5204 11.2767 24.3612 10.8605 24.0797 10.5544ZM17.2612 22.3184H13.1848C12.6829 22.3184 12.2667 21.9022 12.2667 21.4003C12.2667 20.8984 12.6829 20.4822 13.1848 20.4822H17.2612C17.7631 20.4822 18.1793 20.8984 18.1793 21.4003C18.1793 21.9022 17.7631 22.3184 17.2612 22.3184ZM18.2895 17.4219H12.1688C11.6669 17.4219 11.2507 17.0056 11.2507 16.5038C11.2507 16.0019 11.6669 15.5856 12.1688 15.5856H18.2895C18.7914 15.5856 19.2076 16.0019 19.2076 16.5038C19.2076 17.0056 18.7914 17.4219 18.2895 17.4219Z"
                    fill="#E72E4F"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Payment Pop-up */}
      {PaymentPop && (
        <div className="pop-up payment-pop-up">
          <div
            // ref={PayPop}
            className="pop-up-content"
          >
            <div className="pop-up-title">
              <h1>{t("Dashboard.Subscription.AddPayment")}</h1>
              <p>{t("Dashboard.Subscription.WeWill")}</p>
              <div className="close" onClick={(e) => setPaymentPop(false)}>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.0009 26.9999L9.00098 9M27.001 9L9.00098 27.0001"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <form>
              <div className="inpt">
                <label>{t("Dashboard.Subscription.PaymentMethod")}</label>
                <div className="check_inpt">
                  <p>
                    <input
                      type="radio"
                      id="Stripe"
                      name="Payment-method"
                      defaultChecked
                      onClick={() => setChoosePay("stripe")}
                    />
                    <label for="Stripe">
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.5 7.64005C22.5 8.30005 21.96 8.84005 21.3 8.84005H3.7C3.04 8.84005 2.5 8.30005 2.5 7.64005V7.63005C2.5 5.34005 4.35 3.49005 6.64 3.49005H18.35C20.64 3.49005 22.5 5.35005 22.5 7.64005Z"
                          fill="black"
                        />
                        <path
                          d="M2.5 11.54V16.55C2.5 18.84 4.35 20.69 6.64 20.69H18.35C20.64 20.69 22.5 18.83 22.5 16.54V11.54C22.5 10.88 21.96 10.34 21.3 10.34H3.7C3.04 10.34 2.5 10.88 2.5 11.54ZM8.5 17.34H6.5C6.09 17.34 5.75 17 5.75 16.59C5.75 16.18 6.09 15.84 6.5 15.84H8.5C8.91 15.84 9.25 16.18 9.25 16.59C9.25 17 8.91 17.34 8.5 17.34ZM15 17.34H11C10.59 17.34 10.25 17 10.25 16.59C10.25 16.18 10.59 15.84 11 15.84H15C15.41 15.84 15.75 16.18 15.75 16.59C15.75 17 15.41 17.34 15 17.34Z"
                          fill="black"
                        />
                      </svg>
                      {t("Dashboard.Subscription.Stripe")}
                    </label>
                  </p>
                  <p>
                    <input
                      type="radio"
                      id="Paypal"
                      name="Payment-method"
                      onClick={() => setChoosePay("paypal")}
                    />
                    <label for="Paypal">
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.4992 7.09003C19.4992 7.27003 19.4892 7.44002 19.4692 7.61002C19.3492 9.06002 18.7092 10.37 17.7392 11.33C16.6592 12.42 15.1592 13.09 13.4992 13.09H10.3692C9.86923 13.09 9.44924 13.46 9.37924 13.95L8.61923 19.23C8.55923 19.62 8.27924 19.94 7.90924 20.04C7.81924 20.08 7.72924 20.09 7.62924 20.09H5.67923C5.05923 20.09 4.58924 19.54 4.68924 18.93L7.07922 4.60004C7.31922 3.15004 8.56924 2.09003 10.0392 2.09003H14.4992C17.2592 2.09003 19.4992 4.33003 19.4992 7.09003Z"
                          fill="#0C7FCC"
                        />
                        <path
                          d="M21.4988 11.09C21.4988 12.47 20.9388 13.72 20.0388 14.63C19.1288 15.53 17.8788 16.09 16.4988 16.09H14.3488C13.8588 16.09 13.4387 16.44 13.3587 16.93L12.6388 21.25C12.5588 21.74 12.1388 22.09 11.6488 22.09H8.71876C8.53876 22.09 8.37875 22.05 8.23875 21.97C8.01875 21.85 8.15876 21.56 8.39876 21.46C9.29876 21.17 9.95877 20.4 10.0988 19.44L10.7388 15.02C10.7788 14.77 10.9888 14.59 11.2288 14.59H13.4988C15.5088 14.59 17.3988 13.81 18.7987 12.4C19.6287 11.58 20.2487 10.57 20.6087 9.48C20.6887 9.23 21.0188 9.18 21.1288 9.41C21.3688 9.93 21.4988 10.49 21.4988 11.09Z"
                          fill="#0C7FCC"
                        />
                      </svg>
                      {t("Dashboard.Subscription.Paypal")}
                    </label>
                  </p>
                </div>
              </div>
              {choosePay === "stripe" && (
                <>
                  <div className="inpt">
                    <label>{t("Dashboard.Subscription.PaymentMethod")}</label>
                    <input type="text" placeholder="Achraf Hamdani" />
                  </div>
                  <div className="inpt">
                    <label>{t("Dashboard.Subscription.CardNumber")}</label>
                    <input type="text" placeholder="XXX 545 4578 XXX" />
                  </div>
                  <div className="inpts">
                    <div className="inpt">
                      <label>{t("Dashboard.Subscription.ExpiryDate")}</label>
                      <input
                        maxlength="5"
                        placeholder="MM/YY"
                        type="text"
                        onKeyUp={(e) => ExpiryDate(e)}
                      />
                    </div>
                    <div className="inpt">
                      <label>{t("Dashboard.Subscription.CVC")}</label>
                      <input type="number" max={3} placeholder="587" />
                    </div>
                  </div>
                </>
              )}
              {choosePay === "paypal" && (
                <>
                  <div className="item">
                    <div className="icon">
                      <svg
                        width="39"
                        height="40"
                        viewBox="0 0 39 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M30.8733 11.875C30.8733 12.1675 30.857 12.4437 30.8245 12.72C30.6295 15.0762 29.5895 17.205 28.0133 18.765C26.2583 20.5362 23.8208 21.625 21.1233 21.625H16.037C15.2245 21.625 14.542 22.2262 14.4283 23.0225L13.1933 31.6025C13.0958 32.2363 12.6408 32.7563 12.0395 32.9188C11.8933 32.9838 11.747 33 11.5845 33H8.41576C7.40826 33 6.64452 32.1062 6.80702 31.115L10.6907 7.82877C11.0807 5.47252 13.112 3.75 15.5008 3.75H22.7483C27.2333 3.75 30.8733 7.39 30.8733 11.875Z"
                          fill="#99A399"
                        />
                        <path
                          d="M34.1264 18.375C34.1264 20.6175 33.2164 22.6488 31.7539 24.1275C30.2752 25.59 28.2439 26.5 26.0014 26.5H22.5077C21.7114 26.5 21.0289 27.0687 20.8989 27.865L19.7289 34.885C19.5989 35.6812 18.9164 36.25 18.1201 36.25H13.3589C13.0664 36.25 12.8064 36.185 12.5789 36.055C12.2214 35.86 12.4489 35.3887 12.8389 35.2262C14.3014 34.755 15.3739 33.5038 15.6014 31.9438L16.6414 24.7612C16.7064 24.355 17.0477 24.0625 17.4377 24.0625H21.1264C24.3927 24.0625 27.4639 22.795 29.7389 20.5037C31.0876 19.1712 32.0951 17.53 32.6801 15.7588C32.8101 15.3525 33.3464 15.2713 33.5252 15.645C33.9152 16.49 34.1264 17.4 34.1264 18.375Z"
                          fill="#99A399"
                        />
                      </svg>
                    </div>
                    <div className="info">
                      <span>{t("Dashboard.Subscription.Paypal")} </span>
                      <p>{t("Dashboard.Subscription.PayViaPaypal")}</p>
                    </div>
                    <Link to="/subscription/billing" className="add">
                      {t("Dashboard.Subscription.AddMethod")}
                    </Link>
                  </div>
                </>
              )}
              <button
                type="button"
                className="submit"
                onClick={(e) => setPaymentPop(false)}
              >
                {t("Dashboard.Subscription.Pay")}
              </button>
            </form>
          </div>
        </div>
      )}
      {/* Edit Payment Pop-up */}
      {editPayment && (
        <div className="pop-up payment-pop-up">
          <div
            // ref={EditPayPop}
            className="pop-up-content"
          >
            <div className="pop-up-title">
              <h1>{t("Dashboard.Subscription.billing.Edit")}</h1>
              <div className="close" onClick={(e) => setEditPayment(false)}>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.0009 26.9999L9.00098 9M27.001 9L9.00098 27.0001"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <form>
              <div className="inpt">
                <label>{t("Dashboard.Subscription.billing.Owner")}</label>
                <input type="text" value="Achraf Hamdani" />
              </div>
              <div className="inpt">
                <label>{t("Dashboard.Subscription.billing.Card")}</label>
                <input type="text" value="358 545 4578 445" />
              </div>
              <div className="inpts">
                <div className="inpt">
                  <label>{t("Dashboard.Subscription.billing.Expiry")}</label>
                  <input
                    maxlength="5"
                    value="11/02"
                    type="text"
                    onKeyUp={(e) => ExpiryDate(e)}
                  />
                </div>
                <div className="inpt">
                  <label>{t("Dashboard.Subscription.billing.CVC")}</label>
                  <input type="number" value="587" />
                </div>
              </div>
              <div className="btns">
                <button type="button" onClick={() => setEditPayment(false)}>{t("Dashboard.popup.Confirm")}</button>
                <button type="button" onClick={() => setEditPayment(false)}>
                  {t("Dashboard.popup.Cancel")}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default SubscriptionBilling;
