function Error({ msg, close }) {
  return (
    <div className="error">
      <svg
        width="43"
        height="44"
        viewBox="0 0 43 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.75"
          width="42.5001"
          height="42.5"
          rx="6.25001"
          fill="#E72E4F"
        ></rect>
        <path
          d="M21.75 9.49998C14.8625 9.49998 9.25 15.1125 9.25 22C9.25 28.8875 14.8625 34.5 21.75 34.5C28.6375 34.5 34.25 28.8875 34.25 22C34.25 15.1125 28.6375 9.49998 21.75 9.49998ZM27.725 19.125L20.6375 26.2125C20.4625 26.3875 20.225 26.4875 19.975 26.4875C19.725 26.4875 19.4875 26.3875 19.3125 26.2125L15.775 22.675C15.4125 22.3125 15.4125 21.7125 15.775 21.35C16.1375 20.9875 16.7375 20.9875 17.1 21.35L19.975 24.225L26.4 17.8C26.7625 17.4375 27.3625 17.4375 27.725 17.8C28.0875 18.1625 28.0875 18.75 27.725 19.125Z"
          fill="white"
        ></path>
      </svg>
      <p>{msg}</p>
      <div className="close_alert" onClick={close}>
        <svg
          width="23"
          height="24"
          viewBox="0 0 23 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.875 17.764L5.625 6.51404M16.875 6.51404L5.625 17.7641"
            stroke="#E72E4F"
            strokeWidth="1.875"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
}

export default Error;
