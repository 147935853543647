import executeRequest from "../utils/apiService.js";

const exchangeService = {
  async createExchange(
    type,
    provider,
    key,
    secret,
    onSuccess,
    onFailure
  ) {
    return executeRequest({
      method: "POST",
      url: `/api/Exchange/${type === "spot" ? "spot" : "future"}`,
      data: { provider, key, token: secret },
    })
      .then((response) => {
        if (response.success) {
          onSuccess(response);
        } else {
          onFailure("Something went wrong. Please try again.");
        }
      })
      .catch(onFailure);
  },

  async getExchanges(onSuccess, onFailure) {
    return executeRequest({
      method: "GET",
      url: "/api/Exchange",
    })
      .then(onSuccess)
      .catch(onFailure);
  },

  async deleteExchange(id, onSuccess, onFailure) {
    return executeRequest({
      method: "DELETE",
      url: `/api/Exchange/${id}`,
    })
      .then(onSuccess)
      .catch(onFailure);
  },
  async toggleExchange(id, onSuccess, onFailure) {
    return executeRequest({
      method: "PUT",
      url: `/api/Exchange`,
      data: { id },
    })
      .then(onSuccess)
      .catch(onFailure);
  },

  async getSupportedExchanges(onSuccess, onFailure) {
    return executeRequest({
      method: "GET",
      url: "/api/Exchange/supported",
    })
      .then(onSuccess)
      .catch(onFailure);
  },

  async getSupportedExchangesWithUserConnection(onSuccess, onFailure) {
    return executeRequest({
      method: "GET",
      url: "/api/Exchange/supportedWithUserConnection",
    })
      .then(onSuccess)
      .catch(onFailure);
  },
};

export default exchangeService;
