import { createSlice } from '@reduxjs/toolkit';
import profileServices from '../../services/profile';

export const fetchProfileData = () => async (dispatch) => {
  try {
    const response = await profileServices.getMyProfile();
    dispatch(setProfileValue(response.user));
  } catch (error) {
    console.log(error);
  }
};
export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    profileData: {},
  },
  reducers: {
    setProfileValue: (state, action) => {
      state.profileData = action.payload;
    },
  },
});

export const { setProfileValue} = profileSlice.actions;
export default profileSlice.reducer;