import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";

function Referral() {
  const [t] = useTranslation();
  function copy(e) {
    const tempInput = document.createElement("input");
    tempInput.value =
      "www.http/instsignal.referral.program.47858.alexholland.45788.948/5855";
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    //
    // e.target.innerHTML = "Copied";
    e.target.classList.add("copied");
    setTimeout(() => {
      // e.target.innerHTML = "Copy";
      e.target.classList.remove("copied");
    }, 3000);
  }
  return (
    <div className="dashboard-referral">
      <div className="top_ref">
        <div className="title">
          <h1>{t("Dashboard.Referral.title")}</h1>
          <p>{t("Dashboard.Referral.subtitle")}</p>
        </div>
        <div className="paid">
          <p>
            {t("Dashboard.Referral.PaidOut")} <span>$786</span>
          </p>
          <p>
            {t("Dashboard.Referral.ToBePaid")} <span>$786</span>
          </p>
        </div>
        <div className="referral-steps">
          <div className="ref-step">
            <div className="icon">
              <svg
                width="52"
                height="52"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M47.2432 13.3969V24.2323C47.2432 26.9306 46.3509 29.2039 44.7574 30.7761C43.1852 32.3696 40.9119 33.2619 38.2136 33.2619V37.1074C38.2136 38.5521 36.5989 39.4233 35.4092 38.6159L33.3483 37.2561C33.5395 36.5975 33.6245 35.8751 33.6245 35.1103V26.4633C33.6245 22.1291 30.7351 19.2396 26.4009 19.2396H11.9748C11.6774 19.2396 11.4012 19.2609 11.125 19.2821V13.3969C11.125 7.97919 14.7368 4.36737 20.1545 4.36737H38.2136C43.6314 4.36737 47.2432 7.97919 47.2432 13.3969Z"
                  stroke="#5CC159"
                  strokeWidth="3.1869"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M33.6233 26.4633V35.1103C33.6233 35.8751 33.5383 36.5975 33.3471 37.2561C32.561 40.3793 29.969 42.334 26.3997 42.334H20.6208L14.2045 46.6044C13.2484 47.263 11.9736 46.5619 11.9736 45.4146V42.334C9.80653 42.334 8.00064 41.6116 6.74713 40.3581C5.47237 39.0833 4.75 37.2774 4.75 35.1103V26.4633C4.75 22.4265 7.25703 19.6433 11.1238 19.2821C11.4 19.2609 11.6762 19.2396 11.9736 19.2396H26.3997C30.7339 19.2396 33.6233 22.1291 33.6233 26.4633Z"
                  stroke="#5CC159"
                  strokeWidth="3.1869"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="info">
              <h2>{t("Dashboard.Referral.Invite")}</h2>
              <p>{t("Dashboard.Referral.InviteText")}</p>
            </div>
          </div>
          <div className="img_space">
            <svg
              width="186"
              height="22"
              viewBox="0 0 186 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M184.778 20.0929C111.876 -5.77928 71.6435 -3.16154 1 15.8437"
                stroke="#5CC159"
                strokeWidth="2.1246"
                stroke-dasharray="10.62 10.62"
              />
            </svg>
          </div>
          <div className="ref-step">
            <div className="icon">
              <svg
                width="52"
                height="52"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M38.5252 40.1881H36.9105C35.2108 40.1881 33.5962 40.8467 32.4064 42.0365L28.7733 45.627C27.1161 47.263 24.4179 47.263 22.7608 45.627L19.1276 42.0365C17.9379 40.8467 16.3019 40.1881 14.6235 40.1881H13.03C9.50321 40.1881 6.65625 37.3624 6.65625 33.8781V10.6986C6.65625 7.21427 9.50321 4.38861 13.03 4.38861H38.5252C42.0521 4.38861 44.899 7.21427 44.899 10.6986V33.8781C44.899 37.3412 42.0521 40.1881 38.5252 40.1881Z"
                  stroke="#5CC159"
                  strokeWidth="3.1869"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M25.7785 21.3641C28.5125 21.3641 30.7288 19.1478 30.7288 16.4138C30.7288 13.6798 28.5125 11.4636 25.7785 11.4636C23.0445 11.4636 20.8281 13.6798 20.8281 16.4138C20.8281 19.1478 23.0445 21.3641 25.7785 21.3641Z"
                  stroke="#5CC159"
                  strokeWidth="3.1869"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M34.2702 33.3893C34.2702 29.5651 30.4672 26.4633 25.7718 26.4633C21.0765 26.4633 17.2734 29.5651 17.2734 33.3893"
                  stroke="#5CC159"
                  strokeWidth="3.1869"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="info">
              <h2>{t("Dashboard.Referral.Register")}</h2>
              <p>{t("Dashboard.Referral.RegisterText")}</p>
            </div>
          </div>
          <div className="img_space lst">
            <svg
              width="186"
              height="22"
              viewBox="0 0 186 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M184.778 20.0929C111.876 -5.77928 71.6435 -3.16154 1 15.8437"
                stroke="#5CC159"
                strokeWidth="2.1246"
                stroke-dasharray="10.62 10.62"
              />
            </svg>
          </div>
          <div className="ref-step">
            <div className="icon">
              <svg
                width="52"
                height="52"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.2344 29.3314C20.2344 31.3923 21.8279 33.0495 23.7825 33.0495H27.7767C29.4764 33.0495 30.8574 31.6048 30.8574 29.7989C30.8574 27.8655 30.0076 27.1644 28.754 26.7182L22.359 24.4874C21.1055 24.0412 20.2556 23.3613 20.2556 21.4067C20.2556 19.622 21.6366 18.1561 23.3363 18.1561H27.3305C29.2852 18.1561 30.8786 19.8133 30.8786 21.8741"
                  stroke="#5CC159"
                  strokeWidth="3.1869"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M25.5469 16.0527V35.1741"
                  stroke="#5CC159"
                  strokeWidth="3.1869"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M46.7967 25.6134C46.7967 37.3412 37.2785 46.8594 25.5507 46.8594C13.8229 46.8594 4.30469 37.3412 4.30469 25.6134C4.30469 13.8856 13.8229 4.36737 25.5507 4.36737"
                  stroke="#5CC159"
                  strokeWidth="3.1869"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M36.1719 6.49194V14.9903H44.6703"
                  stroke="#5CC159"
                  strokeWidth="3.1869"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M46.7949 4.36737L36.1719 14.9904"
                  stroke="#5CC159"
                  strokeWidth="3.1869"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="info">
              <h2>{t("Dashboard.Referral.GetRewarded")}</h2>
              <p>{t("Dashboard.Referral.GetRewardedText")}</p>
            </div>
          </div>
        </div>
        <div className="field-copy">
          <p>
            www.http/instsignal.referral.program.47858.alexholland.45788.948/5855...
          </p>
          <button onClick={(e) => copy(e)}>{t("Dashboard.popup.Copy")}</button>
        </div>
        <div className="referral-table table-bor">
          <Table>
            <thead>
              <tr>
                <th>{t("Dashboard.Referral.ReferralUsername")}</th>
                <th>{t("Dashboard.Referral.SubscriptionDate")}</th>
                <th>{t("Dashboard.Referral.Profit")}</th>
                <th>{t("Dashboard.Referral.Status")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>achrafct0</td>
                <td>22/09/2023</td>
                <td>30$</td>
                <td>{t("Dashboard.Referral.ToBePaid")}</td>
              </tr>
              <tr>
                <td>achrafct0</td>
                <td>22/09/2023</td>
                <td>30$</td>
                <td>{t("Dashboard.Referral.Paid")}</td>
              </tr>
              <tr>
                <td>achrafct0</td>
                <td>22/09/2023</td>
                <td>30$</td>
                <td>{t("Dashboard.Referral.Paid")}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div className="referral-rules">
        <h2>{t("Dashboard.Referral.ReferralRewards")}</h2>
        <div className="rules">
          <p>{t("Dashboard.Referral.rules1")}</p>
          <p>{t("Dashboard.Referral.rules2")}</p>
          <p>{t("Dashboard.Referral.rules3")}</p>
          <p>{t("Dashboard.Referral.rules4")}</p>
          <p>{t("Dashboard.Referral.rules5")}</p>
          <p>{t("Dashboard.Referral.rules6")}</p>
          <p>{t("Dashboard.Referral.rules7")}</p>
        </div>
      </div>
    </div>
  );
}

export default Referral;
