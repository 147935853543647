import { useState } from "react";
import { useTranslation } from "react-i18next";

// Components
import AddTelegramChannel from "./TelegramBot/AddChannel";
import ChannelAdded from "./TelegramBot/ChannelAdded";


function TelegramBot() {
  // Language
  const [t] = useTranslation();
  // Switch
  const [switchPage, SetSwitchPage] = useState(true);

  return (
    <div className="dashboard-telegram-bot">
      <div className="title">
        <h1>{t("Dashboard.telegramBot.title")}</h1>
      </div>
      <ul className="list-unstyled links">
        <li className={switchPage ? "active" : ""} onClick={e => SetSwitchPage(true)}>Channel Added</li>
        <li className={!switchPage ? "active" : ""} onClick={e => SetSwitchPage(false)}>Add Channel</li>
      </ul>
      {switchPage && (
        <ChannelAdded SetSwitchPage={SetSwitchPage}
        />
      )}
      {!switchPage && (
        <AddTelegramChannel
        />
      )}
    </div>
  );
}

export default TelegramBot;
