import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Link, useNavigate } from "react-router-dom";
import TimezoneSelect from 'react-timezone-select';

// Components
// import CongratulationsPopup from "../popup/congratulations";
import profileServices from "../../services/profile";
import { ToastAlert, toast } from "../Alert/ToastAlert";
import ProblemPopup from "../popup/problem";

// Assets
import defaultProfilePhoto from "../../assets/Rectangle 6300.png";
// import covProfile2 from "../../assets/Rectangle 6300 (1).png";


function ProfileSettings() {
  const navigate = useNavigate();
  const [t] = useTranslation();

  // const [isPorfileUpdated, setIsProfileUpdated] = useState(false);
  const [checkDelete, setCheckDelete] = useState(false);

  // const [selectedTimezone, setSelectedTimezone] = useState(
  //   Intl.DateTimeFormat().resolvedOptions().timeZone
  // )
  const [selectTimezone, seSelectTimezone] = useState("");
  const [copyingTrades, setCopyingTrades] = useState(false);
  const [countryVerify, setCountryVerify] = useState("");
  const [otp, setOtp] = useState("");
  const [Username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [about, setAbout] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(defaultProfilePhoto);


  function handleChange(e) {
    setProfilePhoto(URL.createObjectURL(e.target.files[0]));
    // console.log(e.target.files);
  }

  useEffect(() => {
    profileServices.getMyProfile(
      (d) => {
        const { user } = d;
        setUsername(user?.username);
        setName(user?.name);
        setAbout(user?.about);
        setNumber(user?.phone);
        if (user?.pictureUrl) {
          setProfilePhoto(user?.pictureUrl);
        }
        setCopyingTrades(user?.allowCopyTrades);
        seSelectTimezone(user?.timeZone);
        // console.log(user);
      },
      (e) => console.log(e)
    );
  }, []);

  const updateProfile = () => {
    // console.log(selectTimezone);
    return profileServices.updateMyProfile(
      {
        name: name,
        phone: number,
        allowCopyTrades: copyingTrades,
        about,
        timeZone: selectTimezone,
        username: Username,
      },
      (d) => {
        // setIsProfileUpdated(true);
        toast.success("Profile has been Updated");
      },
      (e) => {
        toast.error(e.response.data.message);
        // console.log(e.response.data.message)
      }
    );
  };

  const deleteProfile = () => {
    return profileServices.deleteProfile(
      (d) => {
        // setCheckDelete(false);
        localStorage.removeItem("token");
        navigate("/sign-in");
      },
      (e) => console.log(e)
    );
  };


  return (
    <div className="profile-settings">
      <form className="settings_form">
        <div className="profilePhoto">
          <label>Profile photo</label>
          <div className="photoBox">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" /></svg>
              <input type="file" onChange={handleChange} />
            </span>
            <img src={profilePhoto} alt="" />
          </div>
        </div>
        <div className="inpt">
          <label>
            {/* {t("Dashboard.settings.Username")} */}
            Name
          </label>
          <input
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
          ></input>
        </div>
        <div className="inpt">
          <label>{t("Dashboard.settings.Phone")}</label>
          <div className="phone">
            {/**  <div className="code">
              <Select
                value={Code}
                onChange={(e) => setCode(e.target.value)}
                displayEmpty
                className="code_country"
              >
                <MenuItem value="">
                  <img src={country} alt="" />
                  +212
                </MenuItem>
                <MenuItem value="DUBAI GMT+5">
                  <img src={country} alt="" /> +113
                </MenuItem>
                <MenuItem value="DUBAI GMT+6">
                  <img src={country} alt="" />
                  +001
                </MenuItem>
                <MenuItem value="DUBAI GMT+7">
                  <img src={country} alt="" />
                  +458
                </MenuItem>
              </Select>
            </div> */}
            <div className="field phone-field">
              <PhoneInput
                enableSearch={true}
                value={number}
                onChange={(number) => setNumber(number)}
              />
              {/* <button type="button" onClick={() => setCountryVerify(true)}>
              {t("Dashboard.settings.Verify")}
              </button> */}
            </div>
          </div>
        </div>
        {/*<div className="profile_img inpt">
          <label>{t("Dashboard.settings.Profile")}</label>
          <div className="up_img">
            {/* <div className="img">
              <img src={covProfile} alt="" />
            </div> 
            <div className="img">
              <img src={covProfile2} alt="" />
              <div className="remove">
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5683 2.80475C8.96301 2.80475 2.76562 9.00213 2.76562 16.6074C2.76562 24.2126 8.96301 30.41 16.5683 30.41C24.1735 30.41 30.3709 24.2126 30.3709 16.6074C30.3709 9.00213 24.1735 2.80475 16.5683 2.80475ZM21.206 19.782C21.6062 20.1823 21.6062 20.8448 21.206 21.2451C20.9989 21.4521 20.7367 21.5487 20.4744 21.5487C20.2122 21.5487 19.9499 21.4521 19.7429 21.2451L16.5683 18.0705L13.3937 21.2451C13.1866 21.4521 12.9244 21.5487 12.6621 21.5487C12.3999 21.5487 12.1376 21.4521 11.9306 21.2451C11.5303 20.8448 11.5303 20.1823 11.9306 19.782L15.1052 16.6074L11.9306 13.4328C11.5303 13.0325 11.5303 12.37 11.9306 11.9697C12.3309 11.5694 12.9934 11.5694 13.3937 11.9697L16.5683 15.1443L19.7429 11.9697C20.1432 11.5694 20.8057 11.5694 21.206 11.9697C21.6062 12.37 21.6062 13.0325 21.206 13.4328L18.0313 16.6074L21.206 19.782Z"
                    fill="#E72E4F"
                  />
                </svg>
              </div>
            </div>
            <div className="up_box">
              <svg
                width="67"
                height="64"
                viewBox="0 0 67 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M49.1889 0H26.9383C18.2336 0 11.0411 6.60444 10.0892 15.0733C4.60944 17.8917 0.851562 23.612 0.851562 30.195V44.7229C0.851562 55.1101 9.28744 63.5608 19.6566 63.5608H38.0635C39.6129 63.5608 40.8691 62.3046 40.8691 60.7552C40.8691 59.2057 39.6129 57.9496 38.0635 57.9496H19.6566C12.3815 57.9496 6.46281 52.0162 6.46281 44.7229V30.195C6.46281 25.5077 9.30568 21.4739 13.3536 19.7344C13.6552 19.6725 13.9388 19.5616 14.1966 19.4112C15.3318 19.028 16.5465 18.8201 17.8088 18.8201H40.0594C46.3156 18.8201 51.4054 23.9229 51.4054 30.195V43.9773C51.4054 45.9857 50.9902 47.9229 50.1715 49.7349C49.7016 50.7748 49.1028 51.7573 48.392 52.6552C47.4302 53.8699 47.6351 55.6345 48.8501 56.5963C50.0647 57.5583 51.8295 57.3531 52.7912 56.1383C53.7871 54.8806 54.6261 53.5035 55.285 52.0452C56.0891 50.2656 56.6148 48.4018 56.8585 46.4802C56.9945 46.4604 57.1307 46.4307 57.2663 46.39C61.026 45.2615 63.3586 41.1731 64.4145 38.8365C65.5636 36.2936 66.1461 33.579 66.1461 30.7684V16.9861C66.1461 7.61995 58.5392 0 49.1889 0ZM60.5349 30.7685C60.5349 32.7771 60.1198 34.7141 59.301 36.5262C58.6366 37.9963 57.8139 39.2531 57.0166 40.0702V30.1952C57.0166 20.829 49.4097 13.209 40.0594 13.209H17.8088C17.2693 13.209 16.736 13.2355 16.2093 13.2851C17.7446 8.82458 21.974 5.61138 26.9381 5.61138H49.1889C55.4451 5.61138 60.5349 10.7142 60.5349 16.9863V30.7685ZM26.1285 44.8766V40.9316H22.1973C20.6478 40.9316 19.3916 39.6755 19.3916 38.126C19.3916 36.5766 20.6478 35.3204 22.1973 35.3204H26.1285V31.3753C26.1285 29.8258 27.3846 28.5697 28.9341 28.5697C30.4836 28.5697 31.7397 29.8258 31.7397 31.3753V35.3204H35.6709C37.2204 35.3204 38.4765 36.5766 38.4765 38.126C38.4765 39.6755 37.2204 40.9316 35.6709 40.9316H31.7397V44.8766C31.7397 46.4261 30.4836 47.6822 28.9341 47.6822C27.3846 47.6822 26.1285 46.4262 26.1285 44.8766Z"
                  fill="#5CC159"
                />
              </svg>
              <p>{t("Dashboard.settings.Drag")}</p>
              <span>
              {t("Dashboard.settings.Accepted")}
              </span>
              <input type="file" />
            </div>
          </div>
        </div>*/}
        <div className="inpt">
          <label>{t("Dashboard.settings.Time")}</label>
          {/* <Select
            value={Timezone}
            onChange={(e) => setTimezone(e.target.value)}
            displayEmpty
            className="select_filter"
          >
            <MenuItem value="">DUBAI GMT+4</MenuItem>
            <MenuItem value="DUBAI GMT+5">DUBAI GMT+5</MenuItem>
            <MenuItem value="DUBAI GMT+6">DUBAI GMT+6</MenuItem>
            <MenuItem value="DUBAI GMT+7">DUBAI GMT+7</MenuItem>
          </Select> */}
          <TimezoneSelect
            value={selectTimezone}
            onChange={e => seSelectTimezone(e.value)}
          />
        </div>
        <div className="inpt">
          <label>{t("Dashboard.settings.About")}</label>
          <textarea
            placeholder={t("Dashboard.settings.Write")}
            onChange={(e) => setAbout(e.target.value)}
            value={about}
          ></textarea>
        </div>
        {/** <div className="inpt switch">
          <label>{t("Dashboard.settings.AllowCopying")}</label>
          <div className="check">
            <input
              type="checkbox"
              checked={copyingTrades && 'checked'}
              onClick={(e) => {
                SetPaidTrades(true);
                setCopyingTrades(!copyingTrades);
              }}
            />
            <p>{t("Dashboard.settings.Allow")}</p>
          </div>
            {copyingTrades && (
            <div className="hide_box">
              <div className="check_inpt">
                <p>
                  <input
                    onClick={() => SetPaidTrades(true)}
                    type="radio"
                    id="Paid"
                    name="copying-trades"
                    defaultChecked
                  />
                  <label for="Paid">{t("Dashboard.settings.Paid")}</label>
                </p>
                *  <p>
                  <input
                    onClick={() => SetPaidTrades(false)}
                    type="radio"
                    id="Free"
                    name="copying-trades"
                  />
                  <label for="Free">{t("Dashboard.settings.Free")}</label>
                </p>
              </div>
              {PaidTrades && (
                <div className="inpt">
                  <input type="number" />
                  <span>$</span>
                  <p>
                  {t("Dashboard.settings.ThePlatform")}
                  </p>
                </div>
              )}
            </div>
          )} 
        </div>*/}
        <div className="btns">
          <button type="button" onClick={() => updateProfile()}>
            {t("Dashboard.settings.SaveChanges")}
          </button>
          <button type="button" onClick={() => setCheckDelete(true)}>
            {t("Dashboard.settings.DeleteAccount")}
          </button>
        </div>
      </form>

      {/* Verify Number Popup */}
      {countryVerify && (
        <div className="pop-up verify-number-popup">
          <div className="pop-up-content">
            <div className="pop-up-title">
              <div className="close" onClick={(e) => setCountryVerify(false)}>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.0009 26.9999L9.00098 9M27.001 9L9.00098 27.0001"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <form>
              <div className="top_part">
                <h1>{t("Dashboard.settings.OTP")}</h1>
                <p>{t("Dashboard.settings.Enter")}</p>
              </div>
              {/* Add Class "error" for red border in input */}
              <div className="otp_section">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  placeholder={"000000"}
                  renderSeparator={""}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
              <Link to="/sign-up/otp-check" className="btn_sign">
                {t("Dashboard.settings.VerifyCode")}
              </Link>
              <div className="qs_sign">
                {t("Dashboard.settings.receieve")}{" "}
                <Link to="/sign-up/otp-check">
                  {t("Dashboard.settings.Resend")}
                </Link>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Delete Alert */}
      {checkDelete && (
        <ProblemPopup
          title={t("Dashboard.popup.AreYouSure")}
          msg={t("Dashboard.settings.deleteMsg")}
          btnText={t("Dashboard.popup.Cancel")}
          btnText2={t("Dashboard.popup.Confirm")}
          closePopup={() => setCheckDelete(false)}
          confirmPopup={deleteProfile}
        />
      )}
      {/* Porfile updated Alert */}
      {/* {isPorfileUpdated && (
        <CongratulationsPopup
          title="Profile updated"
          msg="congrats your profile is updated"
          btnText="Done"
          closePopup={backToHome}
        />
      )} */}

      {/* Alert */}

    </div>
  );
}

export default ProfileSettings;
