import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import telegramService from "../../services/telegram";
import { ToastAlert, toast } from "../Alert/ToastAlert";

// Assets
import help from "../../assets/Group 1707479425.png";
import help2 from "../../assets/Ellipse 7343.png";

// Telegram Components
import ConnectTelegram from "./ConnectTelegram";
import ChooseChannel from "./ChooseChannel";
import VerifySignals from "./VerifySignals";
import CompleteConfiguration from "./CompleteConfiguration";
import ChooseExchange from "./ChooseExchange";


export default function AddTelegramChannel({ channelIsAdded }) {
  // Language
  const [t] = useTranslation();
  // Home
  const [BasicPlan, SetBasicPlan] = useState(false);
  const [PopupStatus, setPopupStatus] = useState(false);
  const [stepProgress, setStepProgress] = useState(1);
  // Steps
  const [connectStatus, setConnectStatus] = useState(); // Step 1
  // const [channelsList, setChannelsList] = useState([]); // Step 2
  const [selectedChannelId, setSelectedChannelId] = useState("");
  const [signalText, setSignalText] = useState(""); // Step 3
  const [configurationChannel, setConfigurationChannel] = useState({
    maxTradesPerDay: "",
    maxProfitPerDay: "",
    maxLossPerDay: "",
    tradeSignalMarket: "spot",
    tradeAmountMode: "fixed",
    tradeAmount: "",
    isIsolated: false
  }); // Step 4
  const [selectedExchange, setSelectedExchange] = useState(""); // Step 5
  // Step 5 submit
  const setupSetelgram = () => {
    // console.log("--------- Steps -----------");
    // console.log("Step 1- connectStatus:",connectStatus);
    // console.log("Step 2- selected Channel Id:",selectedChannelId);
    // console.log("Step 4- configuration Channel:",configurationChannel);
    // console.log("Step 5- selected Exchange id:",selectedExchange);
    // console.log("--------------------");
    telegramService.configureTelegramChannel(
      {
        exchangeId: selectedExchange,
        channelId: selectedChannelId,
        maxTradesPerDay: configurationChannel.maxTradesPerDay,
        maxProfitPerDay: configurationChannel.maxProfitPerDay,
        maxLossPerDay: configurationChannel.maxLossPerDay,
        tradeAmount: configurationChannel.tradeAmount,
        tradeAmountMode: configurationChannel.tradeAmountMode, //"fixed" || "percentage"
        tradeSignalMarket: configurationChannel.tradeSignalMarket, // "spot" || "futures"
        isIsolated: configurationChannel.isIsolated,
      },
      (res) => {

        toast.success("The Channel has been Added");
        setPopupStatus(false);
        setStepProgress(1);

        // setChannelsList([]);
        setSelectedChannelId([]);
        setSignalText("");
        setSelectedExchange("");
        setConfigurationChannel({
          maxTradesPerDay: "",
          maxProfitPerDay: "",
          maxLossPerDay: "",
          tradeSignalMarket: "spot",
          tradeAmountMode: "fixed",
          tradeAmount: "",
          isIsolated: false
        });
        // console.log("setup:",res);
      },
      (err) => {
        console.log("error", err);
        toast.error("Something went wrong, Please try again.");
      }
    );
  };

  // Check telegram connect status
  const checkTelegramConnectState = () => {
    telegramService.getTelegramConnectStatus(
      (res) => {
        setConnectStatus(res.data.isLoggedIn);
        setStepProgress(res.data.isLoggedIn ? 2 : 1);
        if (!res.data.isLoggedIn) {
          setPopupStatus(true);
        }
      },
      (error) => {
        // console.log("check telegram connect status:", error);
      }
    );
  }

  useEffect(() => {
    checkTelegramConnectState();
  }, [])

  // Steps
  const steps = [
    { number: 1, text: t("Dashboard.telegramBot.step1") },
    { number: 2, text: t("Dashboard.telegramBot.step2") },
    { number: 3, text: t("Dashboard.telegramBot.step3") },
    { number: 4, text: t("Dashboard.telegramBot.step4") },
    { number: 5, text: t("Dashboard.telegramBot.step5") }
  ];
  const handleStepClick = (stepNumber) => {
    if (stepProgress >= stepNumber) {
      setPopupStatus(true);
      setStepProgress(stepNumber);
    }
  };

  return (
    <div className="add_new-channel">
      <ul className="list-unstyled progress_bot">
        <li
          className={`
          ${stepProgress === 1 ? "prt_now" : ""} 
          ${stepProgress > 1 ? "prt_done" : ""}`}
        >
          <p>
            <svg
              width="12"
              height="9"
              viewBox="0 0 12 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.90625 4.75341L4.9435 7.80327L11.0306 1.70355"
                stroke="white"
                strokeWidth="1.89041"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            1
          </p>
          <span>{t("Dashboard.telegramBot.LinkTelegram")}</span>
        </li>
        <li
          className={`
          ${stepProgress === 2 ? "prt_now" : ""} 
          ${stepProgress > 2 ? "prt_done" : ""}`}
        >
          <p>
            <svg
              width="12"
              height="9"
              viewBox="0 0 12 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.90625 4.75341L4.9435 7.80327L11.0306 1.70355"
                stroke="white"
                strokeWidth="1.89041"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            2
          </p>
          <span>{t("Dashboard.telegramBot.ChooseChannel")}</span>
        </li>
        <li
          className={`
          ${stepProgress === 3 ? "prt_now" : ""} 
          ${stepProgress > 3 ? "prt_done" : ""}`}
        >
          <p>
            <svg
              width="12"
              height="9"
              viewBox="0 0 12 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.90625 4.75341L4.9435 7.80327L11.0306 1.70355"
                stroke="white"
                strokeWidth="1.89041"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            3
          </p>
          <span>{t("Dashboard.telegramBot.VerifySignals")}</span>
        </li>
        <li
          className={`
          ${stepProgress === 4 ? "prt_now" : ""} 
          ${stepProgress > 4 ? "prt_done" : ""}`}
        >
          <p>
            <svg
              width="12"
              height="9"
              viewBox="0 0 12 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.90625 4.75341L4.9435 7.80327L11.0306 1.70355"
                stroke="white"
                strokeWidth="1.89041"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            4
          </p>
          <span>{t("Dashboard.telegramBot.Settings")}</span>
        </li>
        <li
          className={`
          ${stepProgress === 5 ? "prt_now" : ""} 
          ${stepProgress > 5 ? "prt_done" : ""}`}
        >
          <p>
            <svg
              width="12"
              height="9"
              viewBox="0 0 12 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.90625 4.75341L4.9435 7.80327L11.0306 1.70355"
                stroke="white"
                strokeWidth="1.89041"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            5
          </p>
          <span>{t("Dashboard.telegramBot.ChooseBroker")}</span>
        </li>
      </ul>
      <div className="help">
        <div className="txt">
          <h3>{t("Dashboard.telegramBot.NeedHelp")}</h3>
          <p>{t("Dashboard.telegramBot.crdText")}</p>
        </div>
        <div className="img">
          <img src={help} />
        </div>
        <div className="person">
          <img src={help2} />
        </div>
        <a
          className="play"
          href="http://youtube.com/"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.707031 13.3958L0.707031 2.47335C0.707031 1.5727 0.707031 1.12237 0.917436 0.85589C1.10107 0.62331 1.38413 0.470354 1.69836 0.433845C2.05807 0.392055 2.49825 0.603526 3.37882 1.02657L3.3813 1.02776L14.7489 6.48896C15.7259 6.95834 16.2144 7.19307 16.3745 7.50558C16.514 7.77781 16.514 8.09135 16.3745 8.36359C16.2144 8.67609 15.7259 8.91081 14.7489 9.38019L3.3813 14.8414L3.37818 14.8429C2.49803 15.2657 2.05798 15.4771 1.69836 15.4354C1.38413 15.3989 1.10107 15.2459 0.917436 15.0133C0.707031 14.7468 0.707031 14.2964 0.707031 13.3958Z"
              fill="white"
            />
          </svg>
        </a>
      </div>
      <div className="bot_steps">
        {steps.map(step => (
          <div
            key={step.number}
            className={`step ${stepProgress === step.number ? "step_now" : ""} ${stepProgress > step.number ? "step_complete" : ""}`}
            onClick={() => handleStepClick(step.number)}
          >
            <span>
              <svg
                width="12"
                height="9"
                viewBox="0 0 12 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.90625 4.75341L4.9435 7.80327L11.0306 1.70355"
                  stroke="white"
                  strokeWidth="1.89041"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {step.number}
            </span>
            <p>{step.text}</p>
            <svg
              className="down"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.18359 3.78149L14.1467 9.74458C14.8509 10.4488 14.8509 11.6012 14.1467 12.3054L8.18359 18.2685"
                stroke="black"
                strokeWidth="1.37188"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        ))}
      </div>
      {/* Steps */}
      {PopupStatus && (
        <div className="pop-up telegram-bot-pop-up">
          <div className="pop-up-content">
            <div className="close" onClick={() => setPopupStatus(false)}>
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.0009 26.9999L9.00098 9M27.001 9L9.00098 27.0001"
                  stroke="black"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <ul className="list-unstyled progress_popup">
              <li
                className={`
                  ${stepProgress === 1 ? "prt_now" : ""} 
                  ${stepProgress > 1 ? "prt_done" : ""}`}
              // onClick={() => setStepProgress(stepProgress + 1)}
              >
                <p>
                  <svg
                    width="12"
                    height="9"
                    viewBox="0 0 12 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.90625 4.75341L4.9435 7.80327L11.0306 1.70355"
                      stroke="white"
                      strokeWidth="1.89041"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  1
                </p>
              </li>
              <li
                className={`
                  ${stepProgress === 2 ? "prt_now" : ""} 
                  ${stepProgress > 2 ? "prt_done" : ""}`}
              // onClick={() => setStepProgress(stepProgress + 1)}
              >
                <p>
                  <svg
                    width="12"
                    height="9"
                    viewBox="0 0 12 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.90625 4.75341L4.9435 7.80327L11.0306 1.70355"
                      stroke="white"
                      strokeWidth="1.89041"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  2
                </p>
              </li>
              <li
                className={`
                  ${stepProgress === 3 ? "prt_now" : ""} 
                  ${stepProgress > 3 ? "prt_done" : ""}`}
              // onClick={() => setStepProgress(stepProgress + 1)}
              >
                <p>
                  <svg
                    width="12"
                    height="9"
                    viewBox="0 0 12 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.90625 4.75341L4.9435 7.80327L11.0306 1.70355"
                      stroke="white"
                      strokeWidth="1.89041"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  3
                </p>
              </li>
              <li
                className={`
                  ${stepProgress === 4 ? "prt_now" : ""} 
                  ${stepProgress > 4 ? "prt_done" : ""}`}
              // onClick={() => setStepProgress(stepProgress + 1)}
              >
                <p>
                  <svg
                    width="12"
                    height="9"
                    viewBox="0 0 12 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.90625 4.75341L4.9435 7.80327L11.0306 1.70355"
                      stroke="white"
                      strokeWidth="1.89041"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  4
                </p>
              </li>
              <li
                className={`
                  ${stepProgress === 5 ? "prt_now" : ""} 
                  ${stepProgress > 5 ? "prt_done" : ""}`}
              // onClick={() => setStepProgress(stepProgress + 1)}
              >
                <p>
                  <svg
                    width="12"
                    height="9"
                    viewBox="0 0 12 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.90625 4.75341L4.9435 7.80327L11.0306 1.70355"
                      stroke="white"
                      strokeWidth="1.89041"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  5
                </p>
              </li>
            </ul>
            {(stepProgress === 1) && (
              <ConnectTelegram
                StepProgress={setStepProgress}
                ConnectStatus={connectStatus}
              />
            )}
            {(stepProgress === 2) && (
              <ChooseChannel
                StepProgress={setStepProgress}
                setSelectedChannelId={setSelectedChannelId}
                selectedChannelId={selectedChannelId}
              />
            )}
            {(stepProgress === 3) && (
              <VerifySignals
                StepProgress={setStepProgress}
                signalText={signalText}
                setSignalText={setSignalText}
              />
            )}
            {(stepProgress === 4) && (
              <CompleteConfiguration
                StepProgress={setStepProgress}
                configuration={configurationChannel}
                setConfiguration={setConfigurationChannel}
              />
            )}
            {(stepProgress === 5) && (
              <ChooseExchange
                setSelectedExchange={setSelectedExchange}
                onSubmit={setupSetelgram}
              />
            )}
          </div>
        </div>
      )}
      {/* Wrong */}
      {BasicPlan && (
        <div className="problem-pop-up">
          <form className="content">
            <svg
              width="139"
              height="138"
              viewBox="0 0 139 138"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0.5" width="138" height="138" rx="69" fill="#E72E4F" />
              <path
                d="M69.4974 39.4167C53.197 39.4167 39.9141 52.6996 39.9141 69C39.9141 85.3004 53.197 98.5833 69.4974 98.5833C85.7978 98.5833 99.0807 85.3004 99.0807 69C99.0807 52.6996 85.7978 39.4167 69.4974 39.4167ZM67.2786 57.1667C67.2786 55.9538 68.2845 54.9479 69.4974 54.9479C70.7103 54.9479 71.7161 55.9538 71.7161 57.1667V71.9583C71.7161 73.1713 70.7103 74.1771 69.4974 74.1771C68.2845 74.1771 67.2786 73.1713 67.2786 71.9583V57.1667ZM72.2191 81.9575C72.0711 82.3421 71.8641 82.6379 71.5978 82.9338C71.302 83.2 70.9766 83.4071 70.6216 83.555C70.2666 83.7029 69.882 83.7917 69.4974 83.7917C69.1128 83.7917 68.7282 83.7029 68.3732 83.555C68.0182 83.4071 67.6928 83.2 67.397 82.9338C67.1307 82.6379 66.9236 82.3421 66.7757 81.9575C66.6278 81.6025 66.5391 81.2179 66.5391 80.8333C66.5391 80.4488 66.6278 80.0642 66.7757 79.7092C66.9236 79.3542 67.1307 79.0288 67.397 78.7329C67.6928 78.4667 68.0182 78.2596 68.3732 78.1117C69.0832 77.8158 69.9116 77.8158 70.6216 78.1117C70.9766 78.2596 71.302 78.4667 71.5978 78.7329C71.8641 79.0288 72.0711 79.3542 72.2191 79.7092C72.367 80.0642 72.4557 80.4488 72.4557 80.8333C72.4557 81.2179 72.367 81.6025 72.2191 81.9575Z"
                fill="white"
              />
            </svg>
            <h1>{t("Dashboard.telegramBot.popErr")}</h1>
            <p>{t("Dashboard.telegramBot.popErr1")}</p>
            <div className="btns">
              <Link to="/subscription">{t("Dashboard.popup.Upgrade")}</Link>
              <button type="button" onClick={() => SetBasicPlan(false)}>
                {t("Dashboard.popup.Cancel")}
              </button>
            </div>
            <div onClick={() => SetBasicPlan(false)} className="close">
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.0009 26.9999L9.00098 9M27.001 9L9.00098 27.0001"
                  stroke="black"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </form>
        </div>
      )}
      {/* Alert */}

    </div>
  );
}
