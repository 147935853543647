/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import subscription from "../../assets/Group 1707480058.png";

function SubscriptionPricing() {
  const [plan, SetPlan] = useState("annually");
  const [PaymentPop, setPaymentPop] = useState(false);
  const [t] = useTranslation();
  // const PayPop = useRef(null);
  const [choosePay, setChoosePay] = useState("stripe");
  useEffect(() => {
    let handle = (e) => {
      // if (!PayPop.current.contains(e.target)) {
      //   setPaymentPop(false);
      // }
    };
    document.addEventListener("mousedown", handle);
    return () => {
      document.removeEventListener("mousedown", handle);
    };
  });
  function ExpiryDate(e) {
    var code = e.keyCode;
    var allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }
    e.target.value = e.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        "0$1/" // 3 > 03/
      )
      .replace(
        /^(0[1-9]|1[0-2])$/g,
        "$1/" // 11 > 11/
      )
      .replace(
        /^([0-1])([3-9])$/g,
        "0$1/$2" // 13 > 01/3
      )
      .replace(
        /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
        "$1/$2" // 141 > 01/41
      )
      .replace(
        /^([0]+)\/|[0]+$/g,
        "0" // 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d\/]|^[\/]*$/g,
        "" // To allow only digits and `/`
      )
      .replace(
        /\/\//g,
        "/" // Prevent entering more than 1 `/`
      );
  }
  return (
    <div className="subscription-pricing">
      <h2>{t("Dashboard.Subscription.tit1")}</h2>
      <div className="date_btns">
        <button
          className={`${plan === "monthly" ? "active" : ""}`}
          onClick={(e) => SetPlan("monthly")}
        >
          {t("Dashboard.Subscription.tit2")}
        </button>
        <button
          className={`${plan === "annually" ? "active" : ""}`}
          onClick={(e) => SetPlan("annually")}
        >
          {t("Dashboard.Subscription.tit3")}
        </button>
      </div>
      <div className="plans">
        <div className="plan">
          <div className="top_sec-plan">
            <h4>{t("Dashboard.Subscription.plan1.plan")}</h4>
            {plan === "monthly" && (
              <h3>
                $19.99<span>/{t("Dashboard.Subscription.month")}</span>
              </h3>
            )}
            {plan === "annually" && (
              <h3>
                <p className="discount">$19.99</p>
                $199.99<span>/{t("Dashboard.Subscription.year")}</span>
              </h3>
            )}
          </div>
          <ul className="list-unstyled list">
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan1.l1")}</p>
            </li>
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan1.l2")}</p>
            </li>
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan1.l3")}</p>
            </li>
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan1.l4")}</p>
            </li>
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan1.l5")}</p>
            </li>
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan1.l6")}</p>
            </li>
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan1.l7")}</p>
            </li>
          </ul>
          <a onClick={() => setPaymentPop(true)}>
            {t("Dashboard.Subscription.btn1")} {plan === "monthly" ? "3" : "7"} {t("Dashboard.Subscription.btn2")}
          </a>
        </div>
        <div className="plan">
          <div className="top_sec-plan">
            <h4>{t("Dashboard.Subscription.plan2.plan")}</h4>
            <h5>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.2012 16.9533L17.7312 17.3008C17.4016 17.381 17.1432 17.6304 17.0719 17.96L16.7601 19.2696C16.5909 19.9824 15.6822 20.2051 15.21 19.6438L12.5462 16.5792C12.3324 16.3297 12.4482 15.9377 12.7689 15.8575C14.3458 15.4744 15.7623 14.5925 16.8047 13.3363C16.9739 13.1314 17.2768 13.1047 17.4639 13.2918L19.4417 15.2695C20.1188 15.9466 19.8782 16.793 19.2012 16.9533Z"
                  fill="white"
                />
                <path
                  d="M2.67503 16.9533L4.145 17.3008C4.47463 17.381 4.73298 17.6304 4.80426 17.96L5.11607 19.2696C5.28534 19.9824 6.19404 20.2051 6.66621 19.6438L9.32998 16.5792C9.54379 16.3297 9.42797 15.9377 9.10725 15.8575C7.53038 15.4744 6.11386 14.5925 5.07152 13.3363C4.90225 13.1314 4.59935 13.1047 4.41226 13.2918L2.43449 15.2695C1.75741 15.9466 1.99795 16.793 2.67503 16.9533Z"
                  fill="white"
                />
                <path
                  d="M10.9628 2.28036C7.51505 2.28036 4.72656 5.06885 4.72656 8.51659C4.72656 9.80838 5.10965 10.9933 5.7689 11.9822C6.73106 13.4076 8.25449 14.4143 10.0274 14.6726C10.3303 14.7261 10.6421 14.7528 10.9628 14.7528C11.2835 14.7528 11.5953 14.7261 11.8982 14.6726C13.6711 14.4143 15.1945 13.4076 16.1567 11.9822C16.8159 10.9933 17.199 9.80838 17.199 8.51659C17.199 5.06885 14.4105 2.28036 10.9628 2.28036ZM13.6889 8.3206L12.9495 9.06004C12.8248 9.18476 12.7535 9.4253 12.798 9.60348L13.0118 10.5211C13.1811 11.2427 12.798 11.5278 12.1566 11.1447L11.2657 10.6191C11.1053 10.5211 10.8381 10.5211 10.6777 10.6191L9.78682 11.1447C9.14538 11.5189 8.76229 11.2427 8.93156 10.5211L9.14538 9.60348C9.18101 9.43421 9.11865 9.18476 8.99392 9.06004L8.23667 8.3206C7.80013 7.88406 7.94267 7.44753 8.54848 7.34953L9.50173 7.18917C9.66209 7.16244 9.84918 7.0199 9.92045 6.87736L10.4461 5.82611C10.7312 5.25594 11.1944 5.25594 11.4795 5.82611L12.0051 6.87736C12.0764 7.0199 12.2635 7.16244 12.4328 7.18917L13.386 7.34953C13.9829 7.44753 14.1255 7.88406 13.6889 8.3206Z"
                  fill="white"
                />
              </svg>
              {t("Dashboard.Subscription.plan2.choice")}
            </h5>
            {plan === "monthly" && (
              <h3>
                $29.99<span>/{t("Dashboard.Subscription.month")}</span>
              </h3>
            )}
            {plan === "annually" && (
              <h3>
                <p className="discount">$19.99</p>
                $299.99<span>/{t("Dashboard.Subscription.year")}</span>
              </h3>
            )}
          </div>
          <ul className="list-unstyled list">
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan2.l1")}</p>
            </li>
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan2.l2")}</p>
            </li>
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan2.l3")}</p>
            </li>
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan2.l4")}</p>
            </li>
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan2.l5")}</p>
            </li>
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan2.l6")}</p>
            </li>
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan2.l7")}</p>
            </li>
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan2.l8")}</p>
            </li>
          </ul>
          <a onClick={() => setPaymentPop(true)}>
            {t("Dashboard.Subscription.btn1")} {plan === "monthly" ? "3" : "7"} {t("Dashboard.Subscription.btn2")}
          </a>
          <img src={subscription} alt="" />
        </div>
        <div className="plan">
          <div className="top_sec-plan">
            <h4>{t("Dashboard.Subscription.plan3.plan")}</h4>
            {plan === "monthly" && (
              <h3>
                $49.99<span>/{t("Dashboard.Subscription.month")}</span>
              </h3>
            )}
            {plan === "annually" && (
              <h3>
                <p className="discount">$19.99</p>
                $499.99<span>/{t("Dashboard.Subscription.year")}</span>
              </h3>
            )}
          </div>
          <ul className="list-unstyled list">
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan3.l1")}</p>
            </li>
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan3.l2")}</p>
            </li>
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan3.l3")}</p>
            </li>
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan3.l4")}</p>
            </li>
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan3.l5")}</p>
            </li>
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan3.l6")}</p>
            </li>
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan3.l7")}</p>
            </li>
            <li>
              <div className="icon">
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46192 6.36721C3.29065 6.36721 3.12795 6.29871 3.00807 6.17882L0.584687 3.75544C0.336354 3.50711 0.336354 3.09608 0.584687 2.84775C0.833019 2.59941 1.24405 2.59941 1.49238 2.84775L3.46192 4.81728L7.86339 0.415802C8.11172 0.16747 8.52276 0.16747 8.77109 0.415802C9.01942 0.664135 9.01942 1.07517 8.77109 1.3235L3.91576 6.17882C3.79588 6.29871 3.63318 6.36721 3.46192 6.36721Z"
                    fill="#5CC159"
                  />
                </svg>
              </div>
              <p>{t("Dashboard.Subscription.plan3.l8")}</p>
            </li>
          </ul>
          <a onClick={() => setPaymentPop(true)}>
            {t("Dashboard.Subscription.btn1")} {plan === "monthly" ? "3" : "7"} {t("Dashboard.Subscription.btn2")}
          </a>
        </div>
      </div>
      {/* Payment Pop-up */}
      {PaymentPop && (
        <div className="pop-up payment-pop-up">
          <div
            // ref={PayPop}
            className="pop-up-content"
          >
            <div className="pop-up-title">
              <h1>{t("Dashboard.Subscription.AddPayment")}</h1>
              <p>{t("Dashboard.Subscription.WeWill")}</p>
              <div className="close" onClick={(e) => setPaymentPop(false)}>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.0009 26.9999L9.00098 9M27.001 9L9.00098 27.0001"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <form>
              <div className="inpt">
                <label>{t("Dashboard.Subscription.PaymentMethod")}</label>
                <div className="check_inpt">
                  <p>
                    <input
                      type="radio"
                      id="Stripe"
                      name="Payment-method"
                      defaultChecked
                      onClick={() => setChoosePay("stripe")}
                    />
                    <label for="Stripe">
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.5 7.64005C22.5 8.30005 21.96 8.84005 21.3 8.84005H3.7C3.04 8.84005 2.5 8.30005 2.5 7.64005V7.63005C2.5 5.34005 4.35 3.49005 6.64 3.49005H18.35C20.64 3.49005 22.5 5.35005 22.5 7.64005Z"
                          fill="black"
                        />
                        <path
                          d="M2.5 11.54V16.55C2.5 18.84 4.35 20.69 6.64 20.69H18.35C20.64 20.69 22.5 18.83 22.5 16.54V11.54C22.5 10.88 21.96 10.34 21.3 10.34H3.7C3.04 10.34 2.5 10.88 2.5 11.54ZM8.5 17.34H6.5C6.09 17.34 5.75 17 5.75 16.59C5.75 16.18 6.09 15.84 6.5 15.84H8.5C8.91 15.84 9.25 16.18 9.25 16.59C9.25 17 8.91 17.34 8.5 17.34ZM15 17.34H11C10.59 17.34 10.25 17 10.25 16.59C10.25 16.18 10.59 15.84 11 15.84H15C15.41 15.84 15.75 16.18 15.75 16.59C15.75 17 15.41 17.34 15 17.34Z"
                          fill="black"
                        />
                      </svg>
                      {t("Dashboard.Subscription.Stripe")}
                    </label>
                  </p>
                  <p>
                    <input
                      type="radio"
                      id="Paypal"
                      name="Payment-method"
                      onClick={() => setChoosePay("paypal")}
                    />
                    <label for="Paypal">
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.4992 7.09003C19.4992 7.27003 19.4892 7.44002 19.4692 7.61002C19.3492 9.06002 18.7092 10.37 17.7392 11.33C16.6592 12.42 15.1592 13.09 13.4992 13.09H10.3692C9.86923 13.09 9.44924 13.46 9.37924 13.95L8.61923 19.23C8.55923 19.62 8.27924 19.94 7.90924 20.04C7.81924 20.08 7.72924 20.09 7.62924 20.09H5.67923C5.05923 20.09 4.58924 19.54 4.68924 18.93L7.07922 4.60004C7.31922 3.15004 8.56924 2.09003 10.0392 2.09003H14.4992C17.2592 2.09003 19.4992 4.33003 19.4992 7.09003Z"
                          fill="#0C7FCC"
                        />
                        <path
                          d="M21.4988 11.09C21.4988 12.47 20.9388 13.72 20.0388 14.63C19.1288 15.53 17.8788 16.09 16.4988 16.09H14.3488C13.8588 16.09 13.4387 16.44 13.3587 16.93L12.6388 21.25C12.5588 21.74 12.1388 22.09 11.6488 22.09H8.71876C8.53876 22.09 8.37875 22.05 8.23875 21.97C8.01875 21.85 8.15876 21.56 8.39876 21.46C9.29876 21.17 9.95877 20.4 10.0988 19.44L10.7388 15.02C10.7788 14.77 10.9888 14.59 11.2288 14.59H13.4988C15.5088 14.59 17.3988 13.81 18.7987 12.4C19.6287 11.58 20.2487 10.57 20.6087 9.48C20.6887 9.23 21.0188 9.18 21.1288 9.41C21.3688 9.93 21.4988 10.49 21.4988 11.09Z"
                          fill="#0C7FCC"
                        />
                      </svg>
                      {t("Dashboard.Subscription.Paypal")}
                    </label>
                  </p>
                </div>
              </div>
              {choosePay === "stripe" && (
                <>
                  <div className="inpt">
                    <label>{t("Dashboard.Subscription.PaymentMethod")}</label>
                    <input type="text" placeholder="Achraf Hamdani" />
                  </div>
                  <div className="inpt">
                    <label>{t("Dashboard.Subscription.CardNumber")}</label>
                    <input type="text" placeholder="XXX 545 4578 XXX" />
                  </div>
                  <div className="inpts">
                    <div className="inpt">
                      <label>{t("Dashboard.Subscription.ExpiryDate")}</label>
                      <input
                        maxlength="5"
                        placeholder="MM/YY"
                        type="text"
                        onKeyUp={(e) => ExpiryDate(e)}
                      />
                    </div>
                    <div className="inpt">
                      <label>{t("Dashboard.Subscription.CVC")}</label>
                      <input type="number" max={3} placeholder="587" />
                    </div>
                  </div>
                </>
              )}
              {choosePay === "paypal" && (
                <>
                  <div className="item">
                    <div className="icon">
                      <svg
                        width="39"
                        height="40"
                        viewBox="0 0 39 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M30.8733 11.875C30.8733 12.1675 30.857 12.4437 30.8245 12.72C30.6295 15.0762 29.5895 17.205 28.0133 18.765C26.2583 20.5362 23.8208 21.625 21.1233 21.625H16.037C15.2245 21.625 14.542 22.2262 14.4283 23.0225L13.1933 31.6025C13.0958 32.2363 12.6408 32.7563 12.0395 32.9188C11.8933 32.9838 11.747 33 11.5845 33H8.41576C7.40826 33 6.64452 32.1062 6.80702 31.115L10.6907 7.82877C11.0807 5.47252 13.112 3.75 15.5008 3.75H22.7483C27.2333 3.75 30.8733 7.39 30.8733 11.875Z"
                          fill="#99A399"
                        />
                        <path
                          d="M34.1264 18.375C34.1264 20.6175 33.2164 22.6488 31.7539 24.1275C30.2752 25.59 28.2439 26.5 26.0014 26.5H22.5077C21.7114 26.5 21.0289 27.0687 20.8989 27.865L19.7289 34.885C19.5989 35.6812 18.9164 36.25 18.1201 36.25H13.3589C13.0664 36.25 12.8064 36.185 12.5789 36.055C12.2214 35.86 12.4489 35.3887 12.8389 35.2262C14.3014 34.755 15.3739 33.5038 15.6014 31.9438L16.6414 24.7612C16.7064 24.355 17.0477 24.0625 17.4377 24.0625H21.1264C24.3927 24.0625 27.4639 22.795 29.7389 20.5037C31.0876 19.1712 32.0951 17.53 32.6801 15.7588C32.8101 15.3525 33.3464 15.2713 33.5252 15.645C33.9152 16.49 34.1264 17.4 34.1264 18.375Z"
                          fill="#99A399"
                        />
                      </svg>
                    </div>
                    <div className="info">
                      <span>{t("Dashboard.Subscription.Paypal")} </span>
                      <p>{t("Dashboard.Subscription.PayViaPaypal")}</p>
                    </div>
                    <Link to="/subscription/billing" className="add">
                      {t("Dashboard.Subscription.AddMethod")}
                    </Link>
                  </div>
                </>
              )}
              <button
                type="button"
                className="submit"
                onClick={(e) => setPaymentPop(false)}
              >
                {t("Dashboard.Subscription.Pay")}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default SubscriptionPricing;
