/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from "react-i18next";
import Home1 from "./Home/home1";

function DashboardHome() {
  const [t] = useTranslation();
  return (
    <div className="dashboard-home">
      <div className="title">
        <h1>{t("Dashboard.home.a")}</h1>
        <Home1 />
      </div>
    </div>
  );
}

export default DashboardHome;
