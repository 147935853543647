// store.js
import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./features/email/userSlice";
import profileReducer from './profile/profileSlice';

export const store = configureStore({
  reducer: {
    user: userSlice,
    profile: profileReducer,
  },
});
