import executeRequest from "../utils/apiService.js";

const dashboardService = {
  async getSignals(onSuccess, onFailure) {

    return executeRequest({
      method: "GET",
      url: "/api/Signal/open",
    })
      .then(onSuccess)
      .catch(onFailure);
  },
};

export default dashboardService;
