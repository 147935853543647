function Wrong({ msg }) {
  return (
    <div className="wrong">
      <p>{msg}</p>
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.3405 21.0774C16.3822 21.0774 20.5072 16.9524 20.5072 11.9107C20.5072 6.86908 16.3822 2.74408 11.3405 2.74408C6.29883 2.74408 2.17383 6.86908 2.17383 11.9107C2.17383 16.9524 6.29883 21.0774 11.3405 21.0774Z"
          stroke="#FFA338"
          strokeWidth="1.375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3398 8.24408V12.8274"
          stroke="#FFA338"
          strokeWidth="1.375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.334 15.5775H11.3422"
          stroke="#FFA338"
          strokeWidth="1.83333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default Wrong;
