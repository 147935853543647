import { useTranslation } from "react-i18next";
import profile1 from "../assets/Frame 1707480129.png";
import profile2 from "../assets/Frame 1707480129-1.png";
import profile3 from "../assets/Frame 1707480129-2.png";

function Testimonials() {
  const [t] = useTranslation();
  return (
    <div className="items">
      <div className="item">
        <div className="profile">
          <img src={profile2} alt="John Smith" />
          <div className="info">
            <h3>{t("Home.Testimonials.t1.name")}</h3>
            <div className="rating">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.526677 9.28187C0.186245 8.96704 0.371169 8.39791 0.831632 8.34331L7.35616 7.56974C7.54383 7.54749 7.70684 7.42925 7.78599 7.25765L10.5379 1.2914C10.7322 0.870349 11.3307 0.870295 11.525 1.29135L14.2769 7.25773C14.3561 7.42934 14.518 7.5475 14.7057 7.56976L21.2306 8.34331C21.691 8.39791 21.8759 8.96708 21.5354 9.2819L16.712 13.7428C16.5732 13.8711 16.5113 14.0624 16.5481 14.2477L17.8283 20.6921C17.9187 21.1469 17.4347 21.4986 17.0301 21.2721L11.297 18.063C11.1321 17.9707 10.9312 17.9706 10.7663 18.0629L5.03258 21.2722C4.62797 21.4986 4.14344 21.1469 4.23381 20.6922L5.51433 14.2477C5.55116 14.0623 5.4893 13.8712 5.35055 13.7429L0.526677 9.28187Z"
                  fill="#FFC83B"
                />
              </svg>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.526677 9.28187C0.186245 8.96704 0.371169 8.39791 0.831632 8.34331L7.35616 7.56974C7.54383 7.54749 7.70684 7.42925 7.78599 7.25765L10.5379 1.2914C10.7322 0.870349 11.3307 0.870295 11.525 1.29135L14.2769 7.25773C14.3561 7.42934 14.518 7.5475 14.7057 7.56976L21.2306 8.34331C21.691 8.39791 21.8759 8.96708 21.5354 9.2819L16.712 13.7428C16.5732 13.8711 16.5113 14.0624 16.5481 14.2477L17.8283 20.6921C17.9187 21.1469 17.4347 21.4986 17.0301 21.2721L11.297 18.063C11.1321 17.9707 10.9312 17.9706 10.7663 18.0629L5.03258 21.2722C4.62797 21.4986 4.14344 21.1469 4.23381 20.6922L5.51433 14.2477C5.55116 14.0623 5.4893 13.8712 5.35055 13.7429L0.526677 9.28187Z"
                  fill="#FFC83B"
                />
              </svg>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.526677 9.28187C0.186245 8.96704 0.371169 8.39791 0.831632 8.34331L7.35616 7.56974C7.54383 7.54749 7.70684 7.42925 7.78599 7.25765L10.5379 1.2914C10.7322 0.870349 11.3307 0.870295 11.525 1.29135L14.2769 7.25773C14.3561 7.42934 14.518 7.5475 14.7057 7.56976L21.2306 8.34331C21.691 8.39791 21.8759 8.96708 21.5354 9.2819L16.712 13.7428C16.5732 13.8711 16.5113 14.0624 16.5481 14.2477L17.8283 20.6921C17.9187 21.1469 17.4347 21.4986 17.0301 21.2721L11.297 18.063C11.1321 17.9707 10.9312 17.9706 10.7663 18.0629L5.03258 21.2722C4.62797 21.4986 4.14344 21.1469 4.23381 20.6922L5.51433 14.2477C5.55116 14.0623 5.4893 13.8712 5.35055 13.7429L0.526677 9.28187Z"
                  fill="#FFC83B"
                />
              </svg>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.526677 9.28187C0.186245 8.96704 0.371169 8.39791 0.831632 8.34331L7.35616 7.56974C7.54383 7.54749 7.70684 7.42925 7.78599 7.25765L10.5379 1.2914C10.7322 0.870349 11.3307 0.870295 11.525 1.29135L14.2769 7.25773C14.3561 7.42934 14.518 7.5475 14.7057 7.56976L21.2306 8.34331C21.691 8.39791 21.8759 8.96708 21.5354 9.2819L16.712 13.7428C16.5732 13.8711 16.5113 14.0624 16.5481 14.2477L17.8283 20.6921C17.9187 21.1469 17.4347 21.4986 17.0301 21.2721L11.297 18.063C11.1321 17.9707 10.9312 17.9706 10.7663 18.0629L5.03258 21.2722C4.62797 21.4986 4.14344 21.1469 4.23381 20.6922L5.51433 14.2477C5.55116 14.0623 5.4893 13.8712 5.35055 13.7429L0.526677 9.28187Z"
                  fill="#FFC83B"
                />
              </svg>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.526677 9.28187C0.186245 8.96704 0.371169 8.39791 0.831632 8.34331L7.35616 7.56974C7.54383 7.54749 7.70684 7.42925 7.78599 7.25765L10.5379 1.2914C10.7322 0.870349 11.3307 0.870295 11.525 1.29135L14.2769 7.25773C14.3561 7.42934 14.518 7.5475 14.7057 7.56976L21.2306 8.34331C21.691 8.39791 21.8759 8.96708 21.5354 9.2819L16.712 13.7428C16.5732 13.8711 16.5113 14.0624 16.5481 14.2477L17.8283 20.6921C17.9187 21.1469 17.4347 21.4986 17.0301 21.2721L11.297 18.063C11.1321 17.9707 10.9312 17.9706 10.7663 18.0629L5.03258 21.2722C4.62797 21.4986 4.14344 21.1469 4.23381 20.6922L5.51433 14.2477C5.55116 14.0623 5.4893 13.8712 5.35055 13.7429L0.526677 9.28187Z"
                  fill="#FFC83B"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="text">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2_21590)">
              <path
                d="M17.1317 7.85474V12.7664C15.5789 12.8521 14.4031 13.1355 13.6056 13.6172C12.8075 14.0989 12.2469 14.8798 11.9231 15.9607C11.5999 17.0416 11.4378 18.5876 11.4378 20.5995H17.1317V32.3172H5.45312V21.1772C5.45312 16.5758 6.43975 13.2053 8.413 11.0649C10.3869 8.9246 13.2929 7.85474 17.1317 7.85474ZM34.7624 7.85474V12.7664C33.2096 12.8521 32.0345 13.1251 31.2364 13.5854C30.4382 14.045 29.8611 14.8266 29.5055 15.9289C29.1499 17.0306 28.9718 18.5876 28.9718 20.5995H34.7624V32.3172H23.0196V21.1772C23.0196 16.5758 24.0007 13.2053 25.9629 11.0649C27.9258 8.9246 30.8587 7.85474 34.7624 7.85474Z"
                fill="#5CC159"
              />
            </g>
            <defs>
              <clipPath id="clip0_2_21590">
                <rect
                  width="39.1712"
                  height="39.1712"
                  fill="white"
                  transform="translate(0.484375 0.510254)"
                />
              </clipPath>
            </defs>
          </svg>
          <p>{t("Home.Testimonials.t1.text")}</p>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2_21595)">
              <path
                d="M22.8371 31.886V26.9743C24.3899 26.8886 25.5656 26.6052 26.3631 26.1235C27.1612 25.6419 27.7219 24.8609 28.0456 23.78C28.3688 22.6991 28.531 21.1531 28.531 19.1413H22.8371L22.8371 7.42357H34.5156V18.5635C34.5156 23.1649 33.529 26.5354 31.5558 28.6758C29.5819 30.8161 26.6759 31.886 22.8371 31.886ZM5.20638 31.886V26.9743C6.75915 26.8886 7.93429 26.6156 8.7324 26.1554C9.53051 25.6957 10.1077 24.9141 10.4633 23.8118C10.8189 22.7101 10.997 21.1531 10.997 19.1413H5.20638L5.20638 7.42357H16.9492L16.9492 18.5635C16.9492 23.1649 15.9681 26.5354 14.0058 28.6758C12.043 30.8161 9.11003 31.886 5.20638 31.886Z"
                fill="#5CC159"
              />
            </g>
            <defs>
              <clipPath id="clip0_2_21595">
                <rect
                  width="39.1712"
                  height="39.1712"
                  fill="white"
                  transform="matrix(-1 0 0 -1 39.4844 39.231)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div className="item">
        <div className="profile">
          <img src={profile1} alt="John Smith" />
          <div className="info">
            <h3>{t("Home.Testimonials.t2.name")}</h3>
            <div className="rating">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.526677 9.28187C0.186245 8.96704 0.371169 8.39791 0.831632 8.34331L7.35616 7.56974C7.54383 7.54749 7.70684 7.42925 7.78599 7.25765L10.5379 1.2914C10.7322 0.870349 11.3307 0.870295 11.525 1.29135L14.2769 7.25773C14.3561 7.42934 14.518 7.5475 14.7057 7.56976L21.2306 8.34331C21.691 8.39791 21.8759 8.96708 21.5354 9.2819L16.712 13.7428C16.5732 13.8711 16.5113 14.0624 16.5481 14.2477L17.8283 20.6921C17.9187 21.1469 17.4347 21.4986 17.0301 21.2721L11.297 18.063C11.1321 17.9707 10.9312 17.9706 10.7663 18.0629L5.03258 21.2722C4.62797 21.4986 4.14344 21.1469 4.23381 20.6922L5.51433 14.2477C5.55116 14.0623 5.4893 13.8712 5.35055 13.7429L0.526677 9.28187Z"
                  fill="#FFC83B"
                />
              </svg>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.526677 9.28187C0.186245 8.96704 0.371169 8.39791 0.831632 8.34331L7.35616 7.56974C7.54383 7.54749 7.70684 7.42925 7.78599 7.25765L10.5379 1.2914C10.7322 0.870349 11.3307 0.870295 11.525 1.29135L14.2769 7.25773C14.3561 7.42934 14.518 7.5475 14.7057 7.56976L21.2306 8.34331C21.691 8.39791 21.8759 8.96708 21.5354 9.2819L16.712 13.7428C16.5732 13.8711 16.5113 14.0624 16.5481 14.2477L17.8283 20.6921C17.9187 21.1469 17.4347 21.4986 17.0301 21.2721L11.297 18.063C11.1321 17.9707 10.9312 17.9706 10.7663 18.0629L5.03258 21.2722C4.62797 21.4986 4.14344 21.1469 4.23381 20.6922L5.51433 14.2477C5.55116 14.0623 5.4893 13.8712 5.35055 13.7429L0.526677 9.28187Z"
                  fill="#FFC83B"
                />
              </svg>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.526677 9.28187C0.186245 8.96704 0.371169 8.39791 0.831632 8.34331L7.35616 7.56974C7.54383 7.54749 7.70684 7.42925 7.78599 7.25765L10.5379 1.2914C10.7322 0.870349 11.3307 0.870295 11.525 1.29135L14.2769 7.25773C14.3561 7.42934 14.518 7.5475 14.7057 7.56976L21.2306 8.34331C21.691 8.39791 21.8759 8.96708 21.5354 9.2819L16.712 13.7428C16.5732 13.8711 16.5113 14.0624 16.5481 14.2477L17.8283 20.6921C17.9187 21.1469 17.4347 21.4986 17.0301 21.2721L11.297 18.063C11.1321 17.9707 10.9312 17.9706 10.7663 18.0629L5.03258 21.2722C4.62797 21.4986 4.14344 21.1469 4.23381 20.6922L5.51433 14.2477C5.55116 14.0623 5.4893 13.8712 5.35055 13.7429L0.526677 9.28187Z"
                  fill="#FFC83B"
                />
              </svg>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.526677 9.28187C0.186245 8.96704 0.371169 8.39791 0.831632 8.34331L7.35616 7.56974C7.54383 7.54749 7.70684 7.42925 7.78599 7.25765L10.5379 1.2914C10.7322 0.870349 11.3307 0.870295 11.525 1.29135L14.2769 7.25773C14.3561 7.42934 14.518 7.5475 14.7057 7.56976L21.2306 8.34331C21.691 8.39791 21.8759 8.96708 21.5354 9.2819L16.712 13.7428C16.5732 13.8711 16.5113 14.0624 16.5481 14.2477L17.8283 20.6921C17.9187 21.1469 17.4347 21.4986 17.0301 21.2721L11.297 18.063C11.1321 17.9707 10.9312 17.9706 10.7663 18.0629L5.03258 21.2722C4.62797 21.4986 4.14344 21.1469 4.23381 20.6922L5.51433 14.2477C5.55116 14.0623 5.4893 13.8712 5.35055 13.7429L0.526677 9.28187Z"
                  fill="#FFC83B"
                />
              </svg>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.526677 9.28187C0.186245 8.96704 0.371169 8.39791 0.831632 8.34331L7.35616 7.56974C7.54383 7.54749 7.70684 7.42925 7.78599 7.25765L10.5379 1.2914C10.7322 0.870349 11.3307 0.870295 11.525 1.29135L14.2769 7.25773C14.3561 7.42934 14.518 7.5475 14.7057 7.56976L21.2306 8.34331C21.691 8.39791 21.8759 8.96708 21.5354 9.2819L16.712 13.7428C16.5732 13.8711 16.5113 14.0624 16.5481 14.2477L17.8283 20.6921C17.9187 21.1469 17.4347 21.4986 17.0301 21.2721L11.297 18.063C11.1321 17.9707 10.9312 17.9706 10.7663 18.0629L5.03258 21.2722C4.62797 21.4986 4.14344 21.1469 4.23381 20.6922L5.51433 14.2477C5.55116 14.0623 5.4893 13.8712 5.35055 13.7429L0.526677 9.28187Z"
                  fill="#FFC83B"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="text">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2_21590)">
              <path
                d="M17.1317 7.85474V12.7664C15.5789 12.8521 14.4031 13.1355 13.6056 13.6172C12.8075 14.0989 12.2469 14.8798 11.9231 15.9607C11.5999 17.0416 11.4378 18.5876 11.4378 20.5995H17.1317V32.3172H5.45312V21.1772C5.45312 16.5758 6.43975 13.2053 8.413 11.0649C10.3869 8.9246 13.2929 7.85474 17.1317 7.85474ZM34.7624 7.85474V12.7664C33.2096 12.8521 32.0345 13.1251 31.2364 13.5854C30.4382 14.045 29.8611 14.8266 29.5055 15.9289C29.1499 17.0306 28.9718 18.5876 28.9718 20.5995H34.7624V32.3172H23.0196V21.1772C23.0196 16.5758 24.0007 13.2053 25.9629 11.0649C27.9258 8.9246 30.8587 7.85474 34.7624 7.85474Z"
                fill="#5CC159"
              />
            </g>
            <defs>
              <clipPath id="clip0_2_21590">
                <rect
                  width="39.1712"
                  height="39.1712"
                  fill="white"
                  transform="translate(0.484375 0.510254)"
                />
              </clipPath>
            </defs>
          </svg>
          <p>{t("Home.Testimonials.t2.text")}</p>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2_21595)">
              <path
                d="M22.8371 31.886V26.9743C24.3899 26.8886 25.5656 26.6052 26.3631 26.1235C27.1612 25.6419 27.7219 24.8609 28.0456 23.78C28.3688 22.6991 28.531 21.1531 28.531 19.1413H22.8371L22.8371 7.42357H34.5156V18.5635C34.5156 23.1649 33.529 26.5354 31.5558 28.6758C29.5819 30.8161 26.6759 31.886 22.8371 31.886ZM5.20638 31.886V26.9743C6.75915 26.8886 7.93429 26.6156 8.7324 26.1554C9.53051 25.6957 10.1077 24.9141 10.4633 23.8118C10.8189 22.7101 10.997 21.1531 10.997 19.1413H5.20638L5.20638 7.42357H16.9492L16.9492 18.5635C16.9492 23.1649 15.9681 26.5354 14.0058 28.6758C12.043 30.8161 9.11003 31.886 5.20638 31.886Z"
                fill="#5CC159"
              />
            </g>
            <defs>
              <clipPath id="clip0_2_21595">
                <rect
                  width="39.1712"
                  height="39.1712"
                  fill="white"
                  transform="matrix(-1 0 0 -1 39.4844 39.231)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div className="item">
        <div className="profile">
          <img src={profile3} alt="John Smith" />
          <div className="info">
            <h3>{t("Home.Testimonials.t3.name")}</h3>
            <div className="rating">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.526677 9.28187C0.186245 8.96704 0.371169 8.39791 0.831632 8.34331L7.35616 7.56974C7.54383 7.54749 7.70684 7.42925 7.78599 7.25765L10.5379 1.2914C10.7322 0.870349 11.3307 0.870295 11.525 1.29135L14.2769 7.25773C14.3561 7.42934 14.518 7.5475 14.7057 7.56976L21.2306 8.34331C21.691 8.39791 21.8759 8.96708 21.5354 9.2819L16.712 13.7428C16.5732 13.8711 16.5113 14.0624 16.5481 14.2477L17.8283 20.6921C17.9187 21.1469 17.4347 21.4986 17.0301 21.2721L11.297 18.063C11.1321 17.9707 10.9312 17.9706 10.7663 18.0629L5.03258 21.2722C4.62797 21.4986 4.14344 21.1469 4.23381 20.6922L5.51433 14.2477C5.55116 14.0623 5.4893 13.8712 5.35055 13.7429L0.526677 9.28187Z"
                  fill="#FFC83B"
                />
              </svg>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.526677 9.28187C0.186245 8.96704 0.371169 8.39791 0.831632 8.34331L7.35616 7.56974C7.54383 7.54749 7.70684 7.42925 7.78599 7.25765L10.5379 1.2914C10.7322 0.870349 11.3307 0.870295 11.525 1.29135L14.2769 7.25773C14.3561 7.42934 14.518 7.5475 14.7057 7.56976L21.2306 8.34331C21.691 8.39791 21.8759 8.96708 21.5354 9.2819L16.712 13.7428C16.5732 13.8711 16.5113 14.0624 16.5481 14.2477L17.8283 20.6921C17.9187 21.1469 17.4347 21.4986 17.0301 21.2721L11.297 18.063C11.1321 17.9707 10.9312 17.9706 10.7663 18.0629L5.03258 21.2722C4.62797 21.4986 4.14344 21.1469 4.23381 20.6922L5.51433 14.2477C5.55116 14.0623 5.4893 13.8712 5.35055 13.7429L0.526677 9.28187Z"
                  fill="#FFC83B"
                />
              </svg>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.526677 9.28187C0.186245 8.96704 0.371169 8.39791 0.831632 8.34331L7.35616 7.56974C7.54383 7.54749 7.70684 7.42925 7.78599 7.25765L10.5379 1.2914C10.7322 0.870349 11.3307 0.870295 11.525 1.29135L14.2769 7.25773C14.3561 7.42934 14.518 7.5475 14.7057 7.56976L21.2306 8.34331C21.691 8.39791 21.8759 8.96708 21.5354 9.2819L16.712 13.7428C16.5732 13.8711 16.5113 14.0624 16.5481 14.2477L17.8283 20.6921C17.9187 21.1469 17.4347 21.4986 17.0301 21.2721L11.297 18.063C11.1321 17.9707 10.9312 17.9706 10.7663 18.0629L5.03258 21.2722C4.62797 21.4986 4.14344 21.1469 4.23381 20.6922L5.51433 14.2477C5.55116 14.0623 5.4893 13.8712 5.35055 13.7429L0.526677 9.28187Z"
                  fill="#FFC83B"
                />
              </svg>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.526677 9.28187C0.186245 8.96704 0.371169 8.39791 0.831632 8.34331L7.35616 7.56974C7.54383 7.54749 7.70684 7.42925 7.78599 7.25765L10.5379 1.2914C10.7322 0.870349 11.3307 0.870295 11.525 1.29135L14.2769 7.25773C14.3561 7.42934 14.518 7.5475 14.7057 7.56976L21.2306 8.34331C21.691 8.39791 21.8759 8.96708 21.5354 9.2819L16.712 13.7428C16.5732 13.8711 16.5113 14.0624 16.5481 14.2477L17.8283 20.6921C17.9187 21.1469 17.4347 21.4986 17.0301 21.2721L11.297 18.063C11.1321 17.9707 10.9312 17.9706 10.7663 18.0629L5.03258 21.2722C4.62797 21.4986 4.14344 21.1469 4.23381 20.6922L5.51433 14.2477C5.55116 14.0623 5.4893 13.8712 5.35055 13.7429L0.526677 9.28187Z"
                  fill="#FFC83B"
                />
              </svg>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.526677 9.28187C0.186245 8.96704 0.371169 8.39791 0.831632 8.34331L7.35616 7.56974C7.54383 7.54749 7.70684 7.42925 7.78599 7.25765L10.5379 1.2914C10.7322 0.870349 11.3307 0.870295 11.525 1.29135L14.2769 7.25773C14.3561 7.42934 14.518 7.5475 14.7057 7.56976L21.2306 8.34331C21.691 8.39791 21.8759 8.96708 21.5354 9.2819L16.712 13.7428C16.5732 13.8711 16.5113 14.0624 16.5481 14.2477L17.8283 20.6921C17.9187 21.1469 17.4347 21.4986 17.0301 21.2721L11.297 18.063C11.1321 17.9707 10.9312 17.9706 10.7663 18.0629L5.03258 21.2722C4.62797 21.4986 4.14344 21.1469 4.23381 20.6922L5.51433 14.2477C5.55116 14.0623 5.4893 13.8712 5.35055 13.7429L0.526677 9.28187Z"
                  fill="#FFC83B"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="text">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2_21590)">
              <path
                d="M17.1317 7.85474V12.7664C15.5789 12.8521 14.4031 13.1355 13.6056 13.6172C12.8075 14.0989 12.2469 14.8798 11.9231 15.9607C11.5999 17.0416 11.4378 18.5876 11.4378 20.5995H17.1317V32.3172H5.45312V21.1772C5.45312 16.5758 6.43975 13.2053 8.413 11.0649C10.3869 8.9246 13.2929 7.85474 17.1317 7.85474ZM34.7624 7.85474V12.7664C33.2096 12.8521 32.0345 13.1251 31.2364 13.5854C30.4382 14.045 29.8611 14.8266 29.5055 15.9289C29.1499 17.0306 28.9718 18.5876 28.9718 20.5995H34.7624V32.3172H23.0196V21.1772C23.0196 16.5758 24.0007 13.2053 25.9629 11.0649C27.9258 8.9246 30.8587 7.85474 34.7624 7.85474Z"
                fill="#5CC159"
              />
            </g>
            <defs>
              <clipPath id="clip0_2_21590">
                <rect
                  width="39.1712"
                  height="39.1712"
                  fill="white"
                  transform="translate(0.484375 0.510254)"
                />
              </clipPath>
            </defs>
          </svg>
          <p>{t("Home.Testimonials.t3.text")}</p>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2_21595)">
              <path
                d="M22.8371 31.886V26.9743C24.3899 26.8886 25.5656 26.6052 26.3631 26.1235C27.1612 25.6419 27.7219 24.8609 28.0456 23.78C28.3688 22.6991 28.531 21.1531 28.531 19.1413H22.8371L22.8371 7.42357H34.5156V18.5635C34.5156 23.1649 33.529 26.5354 31.5558 28.6758C29.5819 30.8161 26.6759 31.886 22.8371 31.886ZM5.20638 31.886V26.9743C6.75915 26.8886 7.93429 26.6156 8.7324 26.1554C9.53051 25.6957 10.1077 24.9141 10.4633 23.8118C10.8189 22.7101 10.997 21.1531 10.997 19.1413H5.20638L5.20638 7.42357H16.9492L16.9492 18.5635C16.9492 23.1649 15.9681 26.5354 14.0058 28.6758C12.043 30.8161 9.11003 31.886 5.20638 31.886Z"
                fill="#5CC159"
              />
            </g>
            <defs>
              <clipPath id="clip0_2_21595">
                <rect
                  width="39.1712"
                  height="39.1712"
                  fill="white"
                  transform="matrix(-1 0 0 -1 39.4844 39.231)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
