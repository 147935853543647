import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";

function Analytics() {
  // Default Link Active
  const [DefaultActiveLink, SetDefaultActiveLink] = useState(true);
  const [t] = useTranslation();
  useEffect(() => {
    if (window.location.pathname !== "/analytics") {
      SetDefaultActiveLink(false);
    }
  }, [DefaultActiveLink]);
  return (
    <div className="dashboard-analytics">
      <div className="title">
        <h1>{t("Dashboard.Analytics.title")}</h1>
        <ul className="list-unstyled links">
          <li>
            <NavLink
              exact
              to="statics"
              className={`${DefaultActiveLink ? "active" : ""}`}
              onClick={(e) => SetDefaultActiveLink(false)}
            >
              {t("Dashboard.Analytics.subTitle1")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="history"
              exact
              onClick={(e) => SetDefaultActiveLink(false)}
            >
              {t("Dashboard.Analytics.subTitle2")}
            </NavLink>
          </li>
        </ul>
      </div>
      <Outlet />
    </div>
  );
}

export default Analytics;
