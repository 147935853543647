function CongratulationsPopup({ msg, title, btnText, closePopup }) {
  // const [open, setOpen] = useState(false);
  return (
    <div className="congratulations-pop-up">
      <div className="content">
        <svg
          width="139"
          height="138"
          viewBox="0 0 139 138"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0.5" width="138" height="138" rx="69" fill="#5CC159" />
          <path
            d="M69.4974 39.4167C53.197 39.4167 39.9141 52.6996 39.9141 69C39.9141 85.3004 53.197 98.5833 69.4974 98.5833C85.7978 98.5833 99.0807 85.3004 99.0807 69C99.0807 52.6996 85.7978 39.4167 69.4974 39.4167ZM83.6382 62.1958L66.8645 78.9696C66.4503 79.3838 65.8882 79.6204 65.2966 79.6204C64.7049 79.6204 64.1428 79.3838 63.7286 78.9696L55.3566 70.5975C54.4986 69.7396 54.4986 68.3196 55.3566 67.4617C56.2145 66.6038 57.6345 66.6038 58.4924 67.4617L65.2966 74.2658L80.5024 59.06C81.3603 58.2021 82.7803 58.2021 83.6382 59.06C84.4961 59.9179 84.4961 61.3083 83.6382 62.1958Z"
            fill="white"
          />
        </svg>
        <h1>{title}</h1>
        <p>{msg}</p>
        <button onClick={closePopup}>{btnText}</button>
        <div onClick={closePopup} className="close">
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27.0009 26.9999L9.00098 9M27.001 9L9.00098 27.0001"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default CongratulationsPopup;
